
<div class="wrapper">
  <header class="header-4 skew-separator">
    <div class="header-wrapper">
      <div class="page-header header-video">
        <div class="overlay"></div>
        <video
          autoplay="autoplay"
          loop="loop"
          muted="muted"
          playsinline="playsinline"
        >
          <source
            src="https://www.oberlo.com/wp-content/uploads/2018/10/1.-Pixabay.mp4"
            type="video/mp4"
          />
        </video>
        <div class="container text-center">
          <div class="row">
            <div class="col-lg-7 mx-auto">
              <h1 class="video-text">We Help You</h1>
              <h2 class="display-3 text-white">become the future of insurance</h2>
              <a
                class="btn btn-warning btn-icon mt-3 mb-sm-0"
                href="https://start.insureco.io"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-button-play"> </i>
                </span>
                <span class="btn-inner--text"> Get Started </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  
  <div class="section features-2">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-8 mr-auto text-left">
          <div class="pr-md-5">
            <div
              class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5"
            >
              <i class="ni ni-favourite-28"> </i>
            </div>
            <h3>We Are...</h3>
            <p>
              A group of insurTech innovators, brokers, and insurers linked with common objectives to
              promote sustainable change through technology, trust, and transparency.
            </p>

            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-primary mr-3">
                      <i class="ni ni-settings-gear-65"> </i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Open InsurTech Platform</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-primary mr-3">
                      <i class="ni ni-html5"> </i>
                    </div>
                  </div>
                  <div><h6 class="mb-0">Access Markets Directly</h6></div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-primary mr-3">
                      <i class="ni ni-satisfied"> </i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Automated Accounting</h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 pl-md-0">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="info text-left bg-info shadow">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                >
                  <i class="ni ni-satisfied text-info"> </i>
                </div>
                <h5 class="info-title text-white">Distribution</h5>
                <p class="description">
                  One assembly line for insurance providers to model, launch, service, and
                  maintain insurance products.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="info text-left bg-danger info-raised shadow">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                >
                  <i class="ni ni-palette text-danger"> </i>
                </div>
                <h5 class="info-title text-white">Document Management</h5>
                <p class="description">
                  Store everything in a secure cloud with 
                  visibility to every aspect of your service.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="info text-left bg-default shadow">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                >
                  <i class="ni ni-spaceship text-default"> </i>
                </div>
                <h5 class="info-title text-white">Financial Services</h5>
                <p class="description">
                  Collecting insurance premium is just the start of 
                  a symphony of commission and claims transactions.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="info text-left bg-primary shadow">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                >
                  <i class="ni ni-planet text-primary"> </i>
                </div>
                <h5 class="info-title text-white">Marketing Mentor</h5>
                <p class="description">
                  Tools to help you focus on what you do best and 
                  match up easy to sell and service products.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="info text-left bg-warning info-raised shadow">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                >
                  <i class="ni ni-glasses-2 text-warning"> </i>
                </div>
                <h5 class="info-title text-white">Process Outsourcing</h5>
                <p class="description">
                  Why do all the dirty work when you can lean on our 
                  platform to take care of the details and processes.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="info text-left bg-success shadow">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                >
                  <i class="ni ni-notification-70 text-success"> </i>
                </div>
                <h5 class="info-title text-white">Program Business</h5>
                <p class="description">
                  Manage multiple programs to a complex user base with the 
                  help of our AI and ML routines to pump UW profitablity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="section features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <span class="badge badge-primary badge-pill mb-3">
            Insight
          </span>
          <h3 class="display-3">Full-Funnel Social Analytics</h3>
          <p class="lead">
            The time is now for it to be okay to be great. For being a
            bright color. For standing out.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle"
            >
              <i class="ni ni-settings-gear-65"> </i>
            </div>
            <h6 class="info-title text-uppercase text-primary">
              Social Conversations
            </h6>
            <p class="description opacity-8">
              We get insulted by others, lose trust for those others. We get
              back stabbed by friends. It becomes harder for us to give
              others a hand.
            </p>
            <a class="text-primary" href="javascript:;">
              More about us <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle"
            >
              <i class="ni ni-atom"> </i>
            </div>
            <h6 class="info-title text-uppercase text-success">
              Analyze Performance
            </h6>
            <p class="description opacity-8">
              Don't get your heart broken by people we love, even that we
              give them all we have. Then we lose family over time. As we
              live, our hearts turn colder.
            </p>
            <a class="text-primary" href="javascript:;">
              Learn about our products
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle"
            >
              <i class="ni ni-world"> </i>
            </div>
            <h6 class="info-title text-uppercase text-warning">
              Measure Conversions
            </h6>
            <p class="description opacity-8">
              What else could rust the heart more over time? Blackgold. The
              time is now for it to be okay to be great. or being a bright
              color. For standing out.
            </p>
            <a class="text-primary" href="javascript:;">
              Check our documentation
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <section
    class="pricing-5"
    id="pricing-6"
    style="background-image: url('assets/img/ill/bg_pricing5.svg');"
  >
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-4 d-flex justify-content-center flex-column">
          <h3 class="display-3 mt-3">
            Platform Pricing
          </h3>
          <p class="lead mt-0">
            Your growth on our ecosystem is supported by our technology platform
            and we make it as easy as picking your favorite meal. We have no hidden
            ingredients or costs. Just straight forward pricing that grows with you.
          </p>
        </div>
        <div class="col-lg-7 col-md-8 ml-auto mr-auto">
          <tabset type="pills" class="nav-fill nav-pills-primary">
            <tab heading="Retail Agency">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">PolicySpot</h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$299</div>
                      <span> Setup </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> $99 </b>
                          <span> Monthly </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Policyholders </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> No </b>
                          <span> Contract </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="https://calendly.com/policyspot/15min"> Request a demo </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Digital Agent
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$1,250</div>
                      <span> Setup </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> $500 </b>
                          <span> Monthly </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Custom </b>
                          <span> Branding </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Mobile </b>
                          <span> Application </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Website </b>
                          <span> Raters </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="https://calendly.com/policyspot/15min"> Request a demo </a>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="MGA / Carrier">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Insurance Mall
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$500</div>
                      <span> Setup </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> $399 </b>
                          <span> per month </span>
                        </li><li class="align-items-center">
                          <b class="text-primary"> Appetite </b>
                          <span> Engine </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Submissions </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="https://calendly.com/insureco/raterspot"> Request a demo </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Carrier in a Box
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$25,000</div>
                      <span> Setup </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> Submission</b>
                          <span> Workflows </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> ISO / AAIS</b>
                          <span> Rating and Forms </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Automated</b>
                          <span> Policy Issuance </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> TPA & Claims</b>
                          <span> Integration </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="https://calendly.com/insureco/raterspot"> Request a demo </a>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </section>
  <section class="blogs-1">
    <div class="container-fluid">
      <div class="row mb-md-5">
        <div class="col-md-8 mx-auto">
          <br/><br/>
          <h3 class="display-3 text-center">Our Innovation Lab</h3>
          <p class="lead text-center">
            The best way for most to wrap their head around what we do is by 
            looking at some of the amazing projects we are working on. Do you 
            see yourself uzing technology to advance insurance? Us too!
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/damian.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    My Home Risk
                  </h6>
                  <h5 class="card-title">Comparative Peril Rater</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/ashim.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    LossRuns.com
                  </h6>
                  <h5 class="card-title">Automation Robot</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/odin.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    NEMT PRO
                  </h6>
                  <h5 class="card-title">Tech Enabled Product</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/dane.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    BigTruck Data
                  </h6>
                  <h5 class="card-title">FMCSA Enrichment</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section><!-- 
  <section>
    <div class="cd-section" id="accordion">
      <div class="accordion-1">
        <div class="container">
          <div class="row">
            <div class="col-md-6 mx-auto text-center">
              <h2 class="title mb-3 mt-5">Frequently asked question</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ml-auto">
              <div class="accordion" id="accordionExample">
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        aria-controls="collapseOne"
                        class="btn btn-link w-100 text-primary text-left"
                        [attr.aria-expanded]="!isCollapsed"
                        (click)="isCollapsed = !isCollapsed"
                        id="collapseOne"
                        type="button"
                      >
                        How do I order?
                        <i class="ni ni-bold-down float-right pt-1"> </i>
                      </button>
                    </h5>
                  </div>
                  <div
                    aria-labelledby="headingOne"
                    class="show"
                    data-parent="#accordionExample"
                    [isAnimated]="true"
                    [collapse]="isCollapsed"
                    id="collapseOne"
                  >
                    <div class="card-body opacity-8">
                      Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch.
                      Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                      wolf moon tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft
                      beer farm-to-table, raw denim aesthetic synth nesciunt
                      you probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                      <button
                        aria-controls="collapseTwo"
                        class="btn btn-link w-100 text-primary text-left collapsed"
                        [attr.aria-expanded]="!isCollapsed1"
                        (click)="isCollapsed1 = !isCollapsed1"
                        id="collapseTwo"
                        type="button"
                      >
                        How can i make the payment?
                        <i class="ni ni-bold-down float-right pt-1"> </i>
                      </button>
                    </h5>
                  </div>
                  <div
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                    [isAnimated]="true"
                    [collapse]="isCollapsed1"
                    id="collapseTwo"
                  >
                    <div class="card-body opacity-8">
                      Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch.
                      Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                      wolf moon tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft
                      beer farm-to-table, raw denim aesthetic synth nesciunt
                      you probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button
                        aria-controls="collapseThree"
                        class="btn btn-link w-100 text-primary text-left collapsed"
                        [attr.aria-expanded]="!isCollapsed2"
                        (click)="isCollapsed2 = !isCollapsed2"
                        id="collapseThree"
                        type="button"
                      >
                        How much time does it take to receive the order?
                        <i class="ni ni-bold-down float-right pt-1"> </i>
                      </button>
                    </h5>
                  </div>
                  <div
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                    [isAnimated]="true"
                    [collapse]="isCollapsed2"
                    id="collapseThree"
                  >
                    <div class="card-body opacity-8">
                      Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch.
                      Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                      wolf moon tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft
                      beer farm-to-table, raw denim aesthetic synth nesciunt
                      you probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFour">
                    <h5 class="mb-0">
                      <button
                        aria-controls="collapseFour"
                        class="btn btn-link w-100 text-primary text-left"
                        [attr.aria-expanded]="!isCollapsed3"
                        (click)="isCollapsed3 = !isCollapsed3"
                        id="collapseFour"
                        type="button"
                      >
                        Can I resell the products?
                        <i class="ni ni-bold-down float-right pt-1"> </i>
                      </button>
                    </h5>
                  </div>
                  <div
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                    [isAnimated]="true"
                    [collapse]="isCollapsed3"
                    id="collapseFour"
                  >
                    <div class="card-body opacity-8">
                      Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch.
                      Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                      wolf moon tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft
                      beer farm-to-table, raw denim aesthetic synth nesciunt
                      you probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFifth">
                    <h5 class="mb-0">
                      <button
                        aria-controls="collapseFifth"
                        class="btn btn-link w-100 text-primary text-left"
                        [attr.aria-expanded]="!isCollapsed4"
                        (click)="isCollapsed4 = !isCollapsed4"
                        id="collapseFifth"
                        type="button"
                      >
                        Where do I find the shipping details?
                        <i class="ni ni-bold-down float-right pt-1"> </i>
                      </button>
                    </h5>
                  </div>
                  <div
                    aria-labelledby="headingFifth"
                    data-parent="#accordionExample"
                    [isAnimated]="true"
                    [collapse]="isCollapsed4"
                    id="collapseFifth"
                  >
                    <div class="card-body opacity-8">
                      Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch.
                      Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                      wolf moon tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft
                      beer farm-to-table, raw denim aesthetic synth nesciunt
                      you probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</div>
