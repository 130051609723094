import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "app-privacy",
  templateUrl: "privacy.component.html"
})
export class PrivacyComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("legal-page");
  }
  ngOnDestroy(){
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("legal-page");
  }
  print(){
    window.print();
  }
}
