
<div class="wrapper">
  <div class="cd-section" id="headers">
    <header class="header-1">
      <div class="page-header">
        <div
          class="page-header-image"
          style="background-image: url('assets/img/ill/p2.svg')"
        ></div>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-md-7 mr-auto text-left d-flex justify-content-center flex-column"
            >
              <h3 class="display-3">insurTech</h3>
              <p class="lead mt-0">
                Our platform that plays nice with everone and brings value to every aspect of the industry.
                
              </p>
              <br />

              <div class="buttons">
                <a class="btn btn-danger" [routerLink]='"."' [fragment]="'agents'"> Agents </a>
                <a class="btn btn-danger" [routerLink]='"."' [fragment]="'markets'"> Markets </a>
                <a class="btn btn-danger" [routerLink]='"."' [fragment]="'innovator'"> Innovator </a>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </header>
<!-- This is where we need to start for agents -->
<div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto"  id="agents">
            <h3 class="display-3">Retail Agents</h3>
            <p class="lead">
              You made your product, and now you want to release it to the industry
               and allow users to start consuming your value add.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 mr-auto text-left mt-4">
            <div class="card bg-default shadow border-0">
              <img
                class="card-img-top"
                src="assets/img/theme/img-1-1200x1000.jpg"
              />

              <blockquote class="card-blockquote">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-bg"
                  preserveaspectratio="none"
                  viewbox="0 0 583 95"
                >
                  <polygon
                    class="fill-default"
                    points="0,52 583,95 0,95"
                  ></polygon>
                  <polygon
                    class="fill-default"
                    opacity=".2"
                    points="0,42 583,95 683,0 0,95"
                  ></polygon>
                </svg>
                <h4 class="display-4 text-white">policySpot</h4>
                <p class="lead text-italic text-white">
                  Manage all your business in one place regardless of carrier.<br/>
                </p>
                <div class="buttons">
              <a class="btn btn-danger" href="https://thepolicyspot.com"> Learn More  </a>
            </div>

              </blockquote>
            </div>
          </div>
          <div class="col-lg-7 p-sm-0">
            <div class="row">
              <div class="col-md-6">
                <div class="info info-hover-warning">
                  <div
                    class="icon icon-shape bg-warning shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-satisfied text-white"> </i>
                  </div>
                  <h5 class="info-title">Better Proposals</h5>
                  <p class="description opacity-8">
                    Most agencies are neglecting the time they are 
                    spending building their proposals.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info info-hover-info">
                  <div
                    class="icon icon-shape bg-info shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-palette text-white"> </i>
                  </div>
                  <h5 class="info-title">Servicing & COIs</h5>
                  <p class="description opacity-8">
                    COI's are no longer an issue, and we can make
                    sure that your team is  dialed in.
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-lg-4">
              <div class="col-md-6">
                <div class="info info-hover-danger">
                  <div
                    class="icon icon-shape bg-danger shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-user-run text-white"> </i>
                  </div>
                  <h5 class="info-title">Take Payments</h5>
                  <p class="description opacity-8">
                    Down payments and cancellation prevention
                    calls help engage your customer.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info info-hover-success">
                  <div
                    class="icon icon-shape bg-success shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-glasses-2 text-white"> </i>
                  </div>
                  <h5 class="info-title">Integrated Markets</h5>
                  <p class="description opacity-8">
                    Over 100 markets are already included with
                    your subscrition with more adding monthly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- SECTION - Markets - raterspot -->
  <div
      class="section features-3 my-5"
      style="background-image: url('assets/img/ill/p31.svg')"
    >
      <div class="container"  >
        <div class="row text-center justify-content-center">
          <div class="col-lg-8" id="markets" >
            <h3 class="display-3 text-white" >
              Underwriters and Carriers<span class="text-success">
                quote more with raterSpot</span>
            </h3>
            <br/>
            <div class="buttons">
              <a class="btn btn-danger" href="https://raterspot.com"> Learn More About raterSpot </a>
            </div>
           <!--  <p class="lead text-white">
              Submission platforms can be a costly endeavor, but raterSpot makes getting up and running quickly and without investing thousands of dollars.
            </p> -->
          </div>
        </div>
        <div class="row row-grid mt-5">
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-hat-3 text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-info">Tame Submissions</h5>
                <p>
                  Submissions from agents are the lifeline of your program, but
                  most of the time your flows are less than effecient. Our forms
                  will tame even the most unruly agent submission process.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-warning rounded-circle text-white"
              >
                <i class="ni ni-istanbul text-warning"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-warning">Help Underwriters</h5>
                <p>
                  Being able to automate underwriting isn't always the best
                  option for all markets, and that is why we built out both
                  flows to ensure we have your underwriting covered.
                </p>
                <a class="text-warning" href="#"> Learn more </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid">
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-trophy text-danger"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-danger">Plug Tech In</h5>
                <p>
                  You spent millions on that rater, BYOT - bring your own tech,
                   or maybe you want to add a feature but don't have the bandwith
                  to take on another project; just plug in!
                </p>
                <a class="text-danger" href="#"> Learn more </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-success rounded-circle text-white"
              >
                <i class="ni ni-image text-success"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-success">Join The Mall</h5>
                <p>
                  Connect your product to our ecosystem and become a store in our
                  insurance mall. Thousands of digital agents are already wandering
                  through our store why not market to them?
                </p>
                <a class="text-success" href="#"> Learn more </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center justify-content-center">
          <div class="col-lg-8">
              
          </div>
        </div>
        <div class="row text-center justify-content-center">
          <div class="col-lg-8">

            <p class="lead text-white">
              <span class="text-success">Need Full Quote - Bind - Issue?</span><br/>
              Don't worry we have that too :)
            </p>

          </div>
        </div>
      </div>
    </div>
    

    <!-- SECTION ------------ Innovator -->

    <section class="section-content">
    <div class="container"  id="innovator">
      <div class="row">
        <div class="col-lg-6 mt-md-5 order-md-2 order-lg-1">
          <div class="image-container">
            <img
              class="img shadow rounded img-comments w-100"
              src="assets/img/presentation-page/content-2.png"
            />
            <img src="assets/img/presentation-page/content-1.png"
             class="img shadow rounded img-blog mt-5 w-100"
            />
          </div>
        </div>
        <div class="col-lg-6 mx-auto order-md-1">
          <div class="section-description">
            <h1 class="display-3">
              insurTech Innovators<span class="text-danger">
                deploy on our platform
              </span>
            </h1>
            <p class="lead">
              You have made the new cool thing and you need to make sure that you are not going to miss out on all that stuff that you have been doing.
            </p>
            <br />
            <div class="buttons">
              <a class="btn btn-danger" href="https://start.insureco.io/#/wizard/plugins"> Launch Your Plug-Ins </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
