
<div class="wrapper">
  <header class="header-5">
    <div class="page-header">
      <div class="container">
        <div class="row align-items-center text-left">
          <div class="col-lg-5 col-12">
            <h1 class="display-3">
              TRANSPARENCY <span class="text-primary">
              builds TRUST
              </span>
            </h1>
            <p class="lead pb-4">
              We aim high at being focused on building relationships with
              our clients and partners which transcend current industry constraints.
            </p>
            <div class="row row-input">
              <div class="col-sm-8 col-12">
                <input
                  aria-label="Your email"
                  class="form-control"
                  id="signupSrEmail"
                  name="email"
                  placeholder="Your email"
                  type="email"
                />
              </div>
              <div class="col-sm-4 col-12 pl-lg-0">
                <button class="btn btn-primary btn-block" type="submit">
                  Join
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-12 pl-0">
            <img
              class="ml-lg-5"
              src="assets/img/ill/bg6-2.svg"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="section features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <!-- <span class="badge badge-primary badge-pill mb-3">
            Insight
          </span> -->
          <h3 class="display-3">Our Core Values</h3>
          <p class="lead">
            The time is now for it to be okay to be great. For being a
            bright color and standing out.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle"
            >
              <i class="ni ni-settings-gear-65"> </i>
            </div>
            <h6 class="info-title text-uppercase text-primary">
              Transparency
            </h6>
            <p class="description opacity-8">
              The main goal of any company should be transparency in process and goals
               to bring us closer together on our common goals.
            </p>
            <a class="text-primary" href="javascript:;">
              Learn about our Philosophy <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle"
            >
              <i class="ni ni-atom"> </i>
            </div>
            <h6 class="info-title text-uppercase text-success">
              Appetite Marketing
            </h6>
            <p class="description opacity-8">
              Don't hunt stuff that you know will not fit into your box 
              and will only create more static. We only focus on what 
              we want to really do. This makes getting the process much
              easier when we are going to transform.
            </p>
            <a class="text-primary" href="javascript:;">
              Learn about our Markets
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle"
            >
              <i class="ni ni-world"> </i>
            </div>
            <h6 class="info-title text-uppercase text-warning">
              insurTech Singularity
            </h6>
            <p class="description opacity-8">
              When the insurtech <i>'arms race'</i> is over we will have tech 
              for everyone by everyone and this will be a gross over
              compenstation from the years of being in the dark. We are 
              working to create a world where all tech plays well.
            </p>
            <a class="text-primary" href="javascript:;">
              Learn about our insurTech
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="bg-primary">
    <div class="section team-4 bg-primary">
      <div class="container">
        <div class="row">
          <div class="team-2">
            <div class="container">
              <div class="row">
                <div class="col-md-8 mx-auto text-center mb-5">
                  <h3 class="display-3">The Founding Team</h3>
                  <h4 class="lead">
                    This ecosystem has been a long time vision and struggle
                    to acheive where we are today. Out founding team makes
                    very sure we keep to the ideals and goals first.
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div
                    class="card card-profile"
                    data-image="profile-image"
                  >
                    <div class="card-header">
                      <div class="card-image">
                        <!-- <div class="dropdown" dropdown>
                          <button
                            aria-expanded="false"
                            class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="ni ni-settings-gear-65"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                            x-placement="bottom-end"
                          >
                            <a class="dropdown-item" href="javascript:;">
                              Edit Profile
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Settings
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Log out
                            </a>
                          </div>
                        </div> -->
                        <a href="javascript:;">
                          <img
                            class="img rounded"
                            src="assets/img/team/derek.jpg"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <h4 class="display-4 mb-0">Derek Lovrenich</h4>
                      <p class="lead">President / CEO</p>
                      <div class="table-responsive">
                        <ul class="list-unstyled">
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-info mr-3"
                                >
                                  <i class="ni ni-atom"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">Keen on great design</h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-success mr-3"
                                >
                                  <i class="ni ni-user-run"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">Outdors lover</h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-danger mr-3"
                                >
                                  <i class="ni ni-chart-bar-32"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Super friendly support team
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="card card-profile"
                    data-image="profile-image"
                  >
                    <div class="card-header">
                      <div class="card-image">
                        <!-- <div class="dropdown" dropdown>
                          <button
                            aria-expanded="false"
                            class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="ni ni-settings-gear-65"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                            x-placement="bottom-end"
                          >
                            <a class="dropdown-item" href="javascript:;">
                              Edit Profile
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Settings
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Log out
                            </a>
                          </div>
                        </div> -->
                        <a href="javascript:;">
                          <img
                            class="img rounded"
                            src="assets/img/team/dan.jpg"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <h4 class="display-4 mb-0">Dan Duncan</h4>
                      <p class="lead">Finance </p>
                      <div class="table-responsive">
                        <ul class="list-unstyled">
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-info mr-3"
                                >
                                  <i class="ni ni-atom"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Dedicated entrepreneur
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-success mr-3"
                                >
                                  <i class="ni ni-user-run"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">Urban exploration</h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-danger mr-3"
                                >
                                  <i class="ni ni-chart-bar-32"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Able to get good at everything
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="card card-profile"
                    data-image="profile-image"
                  >
                    <div class="card-header">
                      <div class="card-image">
                        <!-- <div class="dropdown" dropdown>
                          <button
                            aria-expanded="false"
                            class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="ni ni-settings-gear-65"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                            x-placement="bottom-end"
                          >
                            <a class="dropdown-item" href="javascript:;">
                              Edit Profile
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Settings
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Log out
                            </a>
                          </div>
                        </div> -->
                        <a href="javascript:;">
                          <img
                            class="img rounded"
                            src="assets/img/team/aaron.jpg"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <h4 class="display-4 mb-0">Phil Duncan</h4>
                      <p class="lead">Insurance Markets</p>
                      <div class="table-responsive">
                        <ul class="list-unstyled">
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-info mr-3"
                                >
                                  <i class="ni ni-atom"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  High quality publication
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-success mr-3"
                                >
                                  <i class="ni ni-user-run"> </i>
                                </div>
                              </div>
                              <div><h6 class="mb-1">Storytelling</h6></div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-danger mr-3"
                                >
                                  <i class="ni ni-chart-bar-32"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Master of words qualification
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mt-md-5 order-md-2 order-lg-1">
          <div class="image-container">
            <img
              class="img shadow rounded img-comments w-100"
              src="assets/img/presentation-page/content-2.png"
            />
          </div>
        </div>
        <div class="col-lg-6 mx-auto order-md-1">
          <div class="section-description">
            <h1 class="display-3">
              The Future<span class="text-danger">
                Beholds Many Great Things
              </span>
            </h1>
            <p class="lead">
              We took into consideration multiple use cases and came up with
              some specific areas for growth. If you need elements such as policies,
              indications, submissions, quotes and others, we've got you
              covered. They're beautiful and easy to use for the end user
              navigating your insurance checkout process.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
  