
<div class="wrapper">
  <div class="page-header header-filter">
    <div
      class="page-header-image"
      style="background-image: url('assets/img/sections/photo-1.jpg');"
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto text-center">
          <h2 class="display-2 text-white">Check our latest post!</h2>
        </div>
      </div>
      <div class="row">
        <div class="floating-box bg-default">
          <div class="box text-center">
            <div
              class="icon icon-shape bg-primary icon-xl rounded-circle text-white"
            >
              <i class="ni ni-spaceship"> </i>
            </div>
          </div>
          <blockquote class="blockquote text-center mt-4">
            <p class="mb-0 text-white">posted 13 Sept 2019</p>
            <footer class="blockquote-footer text-white">
              Someone famous in
              <cite title="Source Title"> Source Title </cite>
            </footer>
          </blockquote>
          <h2 class="lead text-white p-5">
            As a result of growing greenhouse gas emissions, climate models
            predict that our planet will get significantly warmer, that
            ecosystems will be changed or destroyed, and that enormous human
            and economic costs will be incurred. These scenarios aren't
            guaranteed, but avoiding them will be very hard. We're trying to
            take small steps to mitigate our impact.
          </h2>
        </div>
      </div>
    </div>
  </div>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto">
          <h6 class="category">March 1, 2019</h6>
          <h3 class="title">The Castle Looks Different at Night...</h3>
          <p>
            This is the paragraph where you can write more details about
            your product. Keep you user engaged by providing meaningful
            information. Remember that by this time, the user is curious,
            otherwise he wouldn't scroll to get here. Add a button if you
            want the user to see more. We are here to make life better.
            <br />

            <br />

            And now I look and look around and there's so many Kanyes I've
            been trying to figure out the bed design for the master bedroom
            at our Hidden Hills compound... and thank you for turning my
            personal jean jacket into a couture piece.
          </p>
          <br />

          <br />

          <p class="blockquote">
            âAnd thank you for turning my personal jean jacket into a
            couture piece.â <br />

            <br />

            <small> Kanye West, Producer. </small>
          </p>
          <h3 class="title">Using Video Games To Generate Clicks</h3>
          <br />

          <p>
            This is the paragraph where you can write more details about
            your product. Keep you user engaged by providing meaningful
            information. Remember that by this time, the user is curious,
            otherwise he wouldn't scroll to get here. Add a button if you
            want the user to see more. We are here to make life better.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="glide">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <li class="glide__slide">
            <img
              height="500"
              src="assets/img/theme/sofia-kuniakina.jpg"
              width="450"
            />
          </li>
          <li class="glide__slide">
            <img
              height="500"
              src="assets/img/theme/sacha-styles.jpg"
              width="450"
            />
          </li>
          <li class="glide__slide">
            <img
              height="500"
              src="assets/img/theme/victor-garcia.jpg"
              width="450"
            />
          </li>
          <li class="glide__slide">
            <img
              height="500"
              src="assets/img/theme/doyoun-seo.jpg"
              width="450"
            />
          </li>
          <li class="glide__slide">
            <img
              height="500"
              src="assets/img/theme/ayo-ogunseinde.jpg"
              width="450"
            />
          </li>
        </ul>
      </div>
      <div class="glide__arrows" data-glide-el="controls">
        <button class="glide__arrow glide__arrow--left" data-glide-dir="<" aria-label="Previous">
          <i class="ni ni-bold-left"> </i>
        </button>
        <button
          class="glide__arrow glide__arrow--right"
          data-glide-dir=">" aria-label="Next"
        >
          <i class="ni ni-bold-right"> </i>
        </button>
      </div>
    </div>
  </section>
  <section class="section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <h3 class="title">Rest of the Story:</h3>
          <p>
            We are here to make life better. And now I look and look around
            and there's so many Kanyes I've been trying to figure out the
            bed design for the master bedroom at our Hidden Hills
            compound... and thank you for turning my personal jean jacket
            into a couture piece. <br />

            I speak yell scream directly at the old guard on behalf of the
            future. daytime All respect prayers and love to Phife's family
            Thank you for so much inspiration.
          </p>
          <br />

          <p>
            Thank you Anna for the invite thank you to the whole Vogue team
            And I love you like Kanye loves Kanye Pand Pand Panda I've been
            trying to figure out the bed design for the master bedroom at
            our Hidden Hills compound...The Pablo pop up was almost a pop up
            of influence. All respect prayers and love to Phife's family
            Thank you for so much inspiration daytime I love this new Ferg
            album! The Life of Pablo is now available for purchase I have a
            dream. Thank you to everybody who made The Life of Pablo the
            number 1 album in the world! I'm so proud of the nr #1 song in
            the country. Panda! Good music 2016!
          </p>
          <br />

          <p>
            I love this new Ferg album! The Life of Pablo is now available
            for purchase I have a dream. Thank you to everybody who made The
            Life of Pablo the number 1 album in the world! I'm so proud of
            the nr #1 song in the country. Panda! Good music 2016!
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-blog-info">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card">
            <div class="card-header">
              <h5 class="h3 mb-0">Activity feed</h5>
            </div>
            <div class="card-header d-flex align-items-center">
              <div class="d-flex align-items-center">
                <a href="javascript:;">
                  <img
                    class="avatar"
                    src="assets/img/faces/team-1.jpg"
                  />
                </a>
                <div class="mx-3">
                  <a
                    class="text-dark font-weight-600 text-sm"
                    href="javascript:;"
                  >
                    John Snow
                  </a>
                  <small class="d-block text-muted"> 3 days ago </small>
                </div>
              </div>
              <div class="text-right ml-auto">
                <button
                  class="btn btn-sm btn-primary btn-icon"
                  type="button"
                >
                  <span class="btn-inner--icon icon-big">
                    <i class="ni ni-fat-add"> </i>
                  </span>
                  <span class="btn-inner--text"> Follow </span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <p class="mb-4">
                Personal profiles are the perfect way for you to grab their
                attention and persuade recruiters to continue reading your
                CV because you're telling them from the off exactly why they
                should hire you.
              </p>
              <img
                alt="Image placeholder"
                class="img-fluid rounded"
                src="assets/img/sections/mohamed.jpg"
              />

              <div class="row align-items-center my-3 pb-3 border-bottom">
                <div class="col-sm-6">
                  <div class="icon-actions">
                    <a class="like active" href="javascript:;">
                      <i class="ni ni-like-2"> </i>
                      <span class="text-muted"> 150 </span>
                    </a>
                    <a href="javascript:;">
                      <i class="ni ni-chat-round"> </i>
                      <span class="text-muted"> 36 </span>
                    </a>
                    <a href="javascript:;">
                      <i class="ni ni-curved-next"> </i>
                      <span class="text-muted"> 12 </span>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6 d-none d-sm-block">
                  <div
                    class="d-flex align-items-center justify-content-sm-end"
                  >
                    <div class="avatar-group">
                      <a
                        class="avatar avatar-xs rounded-circle"
                        href="javascript:;"
                      >
                        <img
                          alt="Image placeholder"
                          src="assets/img/faces/team-1.jpg"
                        />
                      </a>
                      <a
                        class="avatar avatar-xs rounded-circle"
                        href="javascript:;"
                      >
                        <img
                          alt="Image placeholder"
                          class="rounded-circle"
                          src="assets/img/faces/team-2.jpg"
                        />
                      </a>
                      <a
                        class="avatar avatar-xs rounded-circle"
                        href="javascript:;"
                      >
                        <img
                          alt="Image placeholder"
                          class="rounded-circle"
                          src="assets/img/faces/team-3.jpg"
                        />
                      </a>
                    </div>
                    <small class="pl-2 font-weight-bold">
                      and 30+ more
                    </small>
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <div class="media media-comment">
                  <img
                    alt="Image placeholder"
                    class="media-comment-avatar rounded-circle"
                    src="assets/img/faces/team-1.jpg"
                  />

                  <div class="media-body">
                    <div class="media-comment-text">
                      <h6 class="h5 mt-0">Michael Lewis</h6>
                      <p class="text-sm lh-160">
                        You have the opportunity to play this game of life
                        you need to appreciate every moment. A lot of people
                        don't appreciate the moment until it's passed.
                      </p>
                      <div class="icon-actions">
                        <a class="like active" href="javascript:;">
                          <i class="ni ni-like-2"> </i>
                          <span class="text-muted"> 3 likes </span>
                        </a>
                        <a href="javascript:;">
                          <i class="ni ni-curved-next"> </i>
                          <span class="text-muted"> 2 shares </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="media media-comment">
                  <img
                    alt="Image placeholder"
                    class="media-comment-avatar rounded-circle"
                    src="assets/img/faces/team-2.jpg"
                  />

                  <div class="media-body">
                    <div class="media-comment-text">
                      <h6 class="h5 mt-0">Jessica Stones</h6>
                      <p class="text-sm lh-160">
                        I always felt like I could do anything. That's the
                        main thing people are controlled by! Thoughts- their
                        perception of themselves! They're slowed down.
                      </p>
                      <div class="icon-actions">
                        <a class="like active" href="javascript:;">
                          <i class="ni ni-like-2"> </i>
                          <span class="text-muted"> 10 likes </span>
                        </a>
                        <a href="javascript:;">
                          <i class="ni ni-curved-next"> </i>
                          <span class="text-muted"> 1 share </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="media align-items-center mt-5">
                  <img
                    alt="Image placeholder"
                    class="avatar avatar-lg rounded-circle mb-4"
                    src="assets/img/faces/team-3.jpg"
                  />

                  <div class="media-body">
                    <form>
                      <textarea
                        class="form-control"
                        placeholder="Write your comment"
                        rows="1"
                      ></textarea>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <section class="blogs-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-md-8 mx-auto">
            <h2 class="title mb-5">Related Stories</h2>
            <div class="card card-blog card-plain blog-horizontal mb-5">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/faces/team-2.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h3 class="card-title">
                      <a href="javascript:;">
                        Rover raised $65 million for pet sitting
                      </a>
                    </h3>
                    <p class="card-description">
                      Finding temporary housing for your dog should be as
                      easy as renting an Airbnb. That's the idea behind
                      Rover, which raised $65 million to expand its pet
                      sitting and dog-walking businesses...
                      <a href="javascript:;"> Read More </a>
                    </p>
                    <div class="author">
                      <img
                        alt="..."
                        class="avatar img-raised"
                        src="assets/img/faces/team-1.jpg"
                      />

                      <div class="text">
                        <span class="name"> Tom Hanks </span>
                        <div class="meta">Drawn on 23 Jan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-blog card-plain blog-horizontal mb-5">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/faces/team-3.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h3 class="card-title">
                      <a href="javascript:;">
                        MateLabs mixes learning with IFTTT
                      </a>
                    </h3>
                    <p class="card-description">
                      If you've ever wanted to train a machine learning
                      model and integrate it with IFTTT, you now can with a
                      new offering from MateLabs. MateVerse, a platform
                      where novices can spin out machine...<a
                        href="javascript:;"
                      >
                        Read More
                      </a>
                    </p>
                    <div class="author">
                      <img
                        alt="..."
                        class="avatar img-raised"
                        src="assets/img/faces/team-5.jpg"
                      />

                      <div class="text">
                        <span class="name"> Paul Smith </span>
                        <div class="meta">Drawn on 23 Jan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-blog card-plain blog-horizontal">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/faces/team-4.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h3 class="card-title">
                      <a href="javascript:;">
                        US venture investment ticks up in Q2
                      </a>
                    </h3>
                    <p class="card-description">
                      Venture investment in U.S. startups rose sequentially
                      in the second quarter of 2017, boosted by large,
                      late-stage financings and a few outsized early-stage
                      rounds in tech and healthcare..
                      <a href="javascript:;"> Read More </a>
                    </p>
                    <div class="author">
                      <img
                        alt="..."
                        class="avatar img-raised"
                        src="assets/img/faces/team-2.jpg"
                      />

                      <div class="text">
                        <span class="name"> Jasmine Tailor </span>
                        <div class="meta">Drawn on 23 Jan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</div>
