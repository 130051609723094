<footer class="footer footer-big bg-gradient-primary">
  <div class="container">
    <div class="content">
      <!-- <div class="row mb-5">
        <div class="column mx-auto"><img alt="" src="assets/img/brand/argon-white.png" class="logo logo-sm"></div>
      </div> -->
      <div class="row">
        <div class="col-md-2 col-6">
          <div class="column">
            <h4 class="mb-4">Company</h4>
            <ul class="links-vertical">
              <li>
                <a [routerLink]="['/about-us/']">
                  About Us
                </a>
              </li>
              <li>
                <a href="https://news.insureco.io/">
                  News
                </a>
              </li>
              <li>
                <a href="https://stats.uptimerobot.com/0077GH2k8x">
                  API Status
                </a>
              </li>
              <li>
                <a [routerLink]="['/about-us/contact']">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 col-6">
          <div class="column">
            <h4 class="mb-4">Social</h4>
            <ul class="links-vertical">
              <li>
                <a href="https://www.youtube.com/channel/UChu0NZa_dFczKud_b3LsVsg">
                  YouTube
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/insureco/">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://twitter.com/insurecosystem">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/insurEco/">
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <div class="column">
            <h4 class="mb-4">Newsletter</h4>
            <ul class="links-vertical">
              <p>
                We handpicked the most interesting content for you. No spam. It takes one click to unsubscribe. Give it a try!
              </p>
            </ul>
            <form novalidate="" action="" method="" class="form form-newsletter ng-untouched ng-pristine ng-valid">
              <div class="form-group"><input placeholder="Your Email" type="email" class="form-control"></div>
              <button name="button" type="button" class="btn btn-primary">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div><hr class="bg-white opacity-3">
    <div class="column">
      <ul class="d-flex justify-content-center">

        <li>
          <a [routerLink]="['/legal/terms']" class="nav-link">
            © {{ date | date: "yyyy" }} insurEco System
          </a>
        </li>
        <li>
          <a [routerLink]="['/legal/terms']" class="nav-link">
            Terms
          </a>
        </li>
        <li>
          <a [routerLink]="['/legal/privacy']" class="nav-link">
            Privacy
          </a>
        </li>
        <li>
          <a [routerLink]="['/legal/disclaimer']" class="nav-link">
            Disclaimer
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
<!-- <footer class="footer">
  <div class="container">
    <div class="row row-grid align-items-center mb-5">
      <div class="col-lg-6">
        <h3 class="text-primary font-weight-light mb-2">
          Thanks for being you! :)
        </h3>
        <h4 class="mb-0 font-weight-light">
          Let's keep in touch on any of these platforms.
        </h4>
      </div>
      <div class="col-lg-6 text-lg-center btn-wrapper">
        <button
          class="btn btn-icon-only btn-twitter rounded-circle mr-3"
          href="https://twitter.com/insureco"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-twitter"> </i>
          </span>
        </button>
        <button
          class="btn-icon-only rounded-circle btn btn-facebook mr-3"
          href="https://www.facebook.com/insureco/"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <i class="fab fa-facebook"> </i>
          </span>
        </button>
        <button
          class="btn btn-icon-only btn-dribbble rounded-circle mr-3"
          href="https://dribbble.com/insureco"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-dribbble"> </i>
          </span>
        </button>
        <button
          class="btn btn-icon-only btn-github rounded-circle"
          href="https://github.com/insureco"
          target="_blank"
        >
          <span class="btn-inner--icon"> <i class="fa fa-github"> </i> </span>
        </button>
      </div>
    </div>
    <hr />

    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © {{ date | date: "yyyy" }}
          <a href="" target="_blank"> insurEco System</a>.
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a class="nav-link" href="https://news.insureco.io/" target="_parent"> insurEco News </a>
          </li>
          <li class="nav-item">
            <a
             class="nav-link"
             [routerLink]="['/about-us']"
             target="_parent"
             >
              About Us
             </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/about-us/contact']"
              target="_parent"
            >
              Contact
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/legal/privacy']" target="_parent"> Privacy Policy </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer> -->
