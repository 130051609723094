import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  insurtechSubline = "A key ingredient in any special sauce, but we serve it up with a sizzlin' side of bacon.";
  financeSubline = "Payments and collections are key to maintaining engagement and retaining commission.";
  marketsSubline = "Everyone needs access to product, but we don't need to be having it run our lives.";
  constructor(private router: Router) {
    router.events.subscribe(val => {
      this.isCollapsed = true;
    });
  }
  mobileView(){
    if (window.innerWidth < 992) {
        return true;
    }
    return false;
  }
  ngOnInit() {

  }
}
