
<div class="wrapper">
  <div class="cd-section" id="headers">
    <header class="header-1">
      <div class="page-header">
        <div
          class="page-header-image"
          style="background-image: url('assets/img/ill/p2.svg')"
        ></div>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-md-7 mr-auto text-left d-flex justify-content-center flex-column"
            >
              <h3 class="display-3">Finance</h3>
              <p class="lead mt-0">
                All your premium finance and premium collection needs in one system and under one roof.
              </p>
              <br />

              <div class="buttons">
                <a class="btn btn-success" href="javascript:;"> Premium Finance </a>
                <a class="btn btn-success" href="javascript:;"> Payment Solutions </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>


  <section
      class="pricing-3 "
      id="pricing-4"
      style="background-image: url('assets/img/ill/1.svg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3">Hell yeah, we can finance premium.</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pr-md-0">
            <div class="card card-pricing text-center bg-default">
              <div class="card-header bg-transparent">
                <h4 class="text-uppercase ls-1 text-primary py-3 mb-0">
                  Use Our Banks
                </h4>
              </div>
              <div class="card-body px-lg-6">
                <div class="display-2 text-white">2%</div>
                <span class="text-muted"> extra commission </span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-folder-17 text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Multiple Lenders
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-camera-compact text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Flexible Down Payments
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-chart-pie-35 text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Cancellation Protection
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn btn-primary mb-3" onclick="window.location.href = 'https://start.financepremium.com';" type="button">
                  Start financing
                </button>
              </div>
              <div class="card-footer bg-transparent">
                <a class="text-muted" href="https://start.financepremium.com">
                  Request a demo
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-7 pl-md-0">
            <div class="card card-pricing border-0 text-center my-5">
              <div class="card-header bg-transparent">
                <h4 class="text-uppercase ls-1 text-primary py-3 mb-0">
                  Start Your Own
                </h4>
              </div>
              <div class="card-body px-lg-6">
                <div class="card-description">
                  Sometimes you need a bank that understands your model like it's your own. So we provide that option too.
                </div>
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <td>24/7 Support</td>
                      <td>No Tech Setup Fees</td>
                    </tr>
                    <tr>
                      <td>Make Fee Income</td>
                      <td>Private Branding</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="https://start.financepremium.com">
                  Request a Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        <!-- SECTION ------------ Innovator -->

<section class="section section-shaped">
  <!-- SVG separator -->
  <div class="separator separator-bottom separator-skew">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</section>
<section class="section section-lg section-shaped">
  <div class="shape shape-style-1 shape-default">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="container py-md">
    <div class="row row-grid justify-content-between align-items-center">
      <div class="col-lg-6">
        <h3 class="display-3 text-white">Cancellations Suck
          <span class="text-white">that's why we offer protection!</span>
        </h3>
        <p class="lead text-white">When accounts are getting ready to cancel our automation will alert our team to reach out on your behalf to make the payment however possible.</p>
      </div>
      <div class="col-lg-5 mb-lg-auto">
        <div class="transform-perspective-right">
          <div class="card bg-secondary shadow border-0">
            <div class="card-header bg-white pb-5">
            <img src="assets/img/presentation-page/content-1.png"
             class="img shadow rounded img-blog mt-5 w-100"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SVG separator -->
  <div class="separator separator-bottom separator-skew">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</section>
    <section class="section-content section-lg">
    <div class="container"  id="innovator">
      <div class="row">
        <div class="col-lg-6 mt-md-5 order-md-2 order-lg-1">
          <div class="image-container">
            <img
              class="img shadow rounded img-comments w-100"
              src="assets/img/presentation-page/content-2.png"
            />
            <img src="assets/img/presentation-page/content-1.png"
             class="img shadow rounded img-blog mt-5 w-100"
            />
          </div>
        </div>
        <div class="col-lg-6 mx-auto order-md-1">
          <div class="section-description">
            <h1 class="display-3">
              Payments of all types<span class="text-danger">
                we got them too.
              </span>
            </h1>
            <p class="lead">
              Our payment platform that already is integrated with stripe and many other payment gateways. We can make sure that your payments are taken care of in a way that everything is great.
            </p>
            <br />
            <div class="buttons">
              <a class="btn btn-danger" href="https://policypay.io"> Visit PolicyPay </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
