
<div class="wrapper">
  <div
    class="page-header header-filter page-header-small skew-separator skew-mini"
  >
    <div
      class="page-header-image"
      style="background-image: url('assets/img/pages/boxed-water.jpg');"
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-7 mr-auto text-left">
          <h1 class="title text-white">Our products</h1>
          <br />

          <h4 class="category text-white opacity-8">
            This is the best way to find your favorite stuff
          </h4>
        </div>
      </div>
    </div>
    <div class="separator separator-bottom separator-skew">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveaspectratio="none"
        version="1.1"
        viewbox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  </div>
  <div class="section section-item">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <carousel [showIndicators]="true">
            <slide>
              <img
                alt="First slide"
                class="d-block"
                src="assets/img/pages/shirt.png"
              />
            </slide>
            <slide>
              <img
                alt="Second slide"
                class="d-block"
                src="assets/img/pages/shorts.png"
              />
            </slide>
            <slide>
              <img
                alt="Third slide"
                class="d-block"
                src="assets/img/pages/tshirt.png"
              />
            </slide>
          </carousel>
        </div>
        <div class="col-lg-6 col-md-12 mx-auto">
          <h2 class="title">Givenchy</h2>
          <div class="stats">
            <div class="stars text-warning">
              <i class="fas fa-star"> </i> <i class="fas fa-star"> </i>
              <i class="fas fa-star"> </i> <i class="fas fa-star"> </i>
              <i class="far fa-star"> </i>
              <p class="d-inline">(76 customer reviews)</p>
            </div>
          </div>
          <br />

          <h2 class="main-price">$3,390</h2>
          <h6 class="category">Description</h6>
          <p class="description">
            Eres' daring 'Grigri Fortune' swimsuit has the fit and coverage
            of a bikini in a one-piece silhouette. This fuchsia style is
            crafted from the label's sculpting peau douce fabric and has
            flattering cutouts through the torso and back. Wear yours with
            mirrored sunglasses on vacation.
          </p>
          <br />

          <div class="row pick-size">
            <div class="col-lg-4 col-md-4">
              <label> Quantity </label>
              <div class="input-group">
                <div class="input-group-btn">
                  <button
                    class="btn btn-outline-warning btn-round"
                    id="down"
                  >
                    <i class="ni ni-fat-delete"> </i>
                  </button>
                </div>
                <input
                  class="form-control input-number"
                  id="myNumber"
                  type="text"
                  value="1"
                />

                <div class="input-group-btn">
                  <button
                    class="btn btn-outline-warning btn-round"
                    id="up"
                  >
                    <i class="ni ni-fat-add"> </i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
              <label> Select color </label>
              <select
                class="form-control"
                data-trigger=""
                id="choices-single-default"
                name="choices-single-default"
              >
                <option disabled="disabled" selected="selected" value="1">
                  Choose Color
                </option>
                <option value="2"> Black </option>
                <option value="3"> Gray </option>
                <option value="4"> White </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
              <label> Select size </label>
              <select
                class="form-control form-control-sm"
                data-trigger=""
                id="choices-single-default"
                name="choices-single-default"
              >
                <option disabled="disabled" selected="selected">
                  Choose size
                </option>
                <option value="0"> XS </option>
                <option value="1"> S </option>
                <option value="2"> M </option>
                <option value="3"> L </option>
                <option value="4"> XL </option>
              </select>
            </div>
          </div>
          <br />

          <div class="row justify-content-start">
            <button class="btn btn-warning ml-3">
              Add to Cart <i class="ni ni-cart"> </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="testimonials-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h3 class="display-3">Join our world</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-8 col-10 positioned">
            <h3 class="display-3">See our customers opinion</h3>
            <p class="lead">
              Meet Wingman, a robust suite of styled pages and components,
              powered by Bootstrap 4. The ideal starting point for product
              landing pages, stylish web-apps and complete company websites.
            </p>
            <a class="btn btn-primary mt-5" href="javascript:;">
              Contact us
            </a>
          </div>
          <div class="col-md-12 col-md-6">
            <div class="testimonial-glide">
              <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "Take up one idea. Make that one idea your life -
                        think of it, dream of it, live on that idea. Let the
                        brain, muscles, nerves, every part of your body, be
                        full of that idea, and just leave every other idea
                        alone. This is the way to success. A single rose can
                        be my garden... a single friend, my world."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-1.jpg"
                        />

                        <span> Andrew Lino </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        Artist is a term applied to a person who engages in
                        an activity deemed to be an art. An artist also may
                        be defined unofficially as "a person who expresses
                        him- or herself through a medium". He is a
                        descriptive term applied to a person who engages in
                        an activity deemed to be an art."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-2.jpg"
                        />

                        <span> Michael Jenos </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "The simplest visual description uses ordinary words
                        to convey what the writer sees. First he or she must
                        look at the subject â slowly, carefully, and
                        repeatedly, if possible â to identify the parts that
                        make the whole"
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-3.jpg"
                        />

                        <span> Rose Arthur </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "Finding temporary housing for your dog should be as
                        easy as renting an Airbnb. That's the idea behind
                        Rover, which raised $65 million to expand its pet
                        sitting and dog-walking businesses..Finding
                        temporary housing for your dog should be as easy as
                        renting an Airbnb."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-4.jpg"
                        />

                        <span> Lora Jimi </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "Venture investment in U.S. startups rose
                        sequentially in the second quarter of 2017, boosted
                        by large, late-stage financings and a few outsized
                        early-stage rounds in tech and healthcare..enture
                        investment in U.S. startups rose sequentially in the
                        second quarter of 2017, boosted by large."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-5.jpg"
                        />

                        <span> Mike Ranson </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="glide__arrows" data-glide-el="controls">
                <button
                  class="glide__arrow glide__arrow--left text-default"
                  aria-label="Previous"  data-glide-dir="<"
                >
                  <i class="ni ni-bold-left"> </i>
                </button>
                <button
                  class="glide__arrow glide__arrow--right text-default"
                  data-glide-dir=">" aria-label="Next"
                >
                  <i class="ni ni-bold-right"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="section related-products bg-secondary skew-separator skew-top"
  >
    <div class="container">
      <div class="col-md-8"><h2 class="title">You may also like</h2></div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="card card-product">
            <div class="card-image">
              <a href="#pablo">
                <img
                  class="img rounded"
                  src="assets/img/pages/tshirt.png"
                />
              </a>
            </div>
            <div class="card-body">
              <h6 class="category text-warning">Trending</h6>
              <h4 class="card-title">
                <a class="card-link" href="javascript:;"> Gucci </a>
              </h4>
              <div class="card-description opacity-8 mt-2">
                Dolce &amp; Gabbana's 'Greta' tote has been crafted in Italy
                from hard-wearing red textured-leather.
              </div>
              <div class="card-footer">
                <div class="price-container">
                  <span class="price"> €1,459 </span>
                </div>
                <button
                  class="btn btn-simple btn-warning btn-icon-only btn-sm btn-round pull-right"
                  placement="left"
                >
                  <i class="ni ni-favourite-28"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="card card-product">
            <div class="card-image">
              <a href="#pablo">
                <img
                  class="img rounded"
                  src="assets/img/pages/shorts.png"
                />
              </a>
            </div>
            <div class="card-body">
              <h6 class="category text-warning">Popular</h6>
              <h4 class="card-title">
                <a class="card-link" href="javascript:;"> Balmain </a>
              </h4>
              <div class="card-description opacity-8 mt-2">
                Balmain's mid-rise skinny jeans are cut with stretch to
                ensure they retain their second-skin fit but move
                comfortably.
              </div>
              <div class="card-footer">
                <div class="price-container">
                  <span class="price"> €459 </span>
                </div>
                <button
                  class="btn btn-simple btn-warning btn-icon-only btn-sm btn-round pull-right"
                  placement="left"
                >
                  <i class="ni ni-favourite-28"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="card card-product">
            <div class="card-image">
              <a href="#pablo">
                <img
                  class="img rounded"
                  src="assets/img/pages/shirt.png"
                />
              </a>
            </div>
            <div class="card-body">
              <h6 class="category text-warning">Popular</h6>
              <h4 class="card-title">
                <a class="card-link" href="javascript:;"> Balenciaga </a>
              </h4>
              <div class="card-description opacity-8 mt-2">
                Balenciaga's black textured-leather wallet is finished with
                the label's iconic 'Giant' studs. This is where you can...
              </div>
              <div class="card-footer">
                <div class="price-container">
                  <span class="price"> €559 </span>
                </div>
                <button
                  class="btn btn-simple btn-warning btn-icon-only btn-sm btn-round pull-right"
                  placement="left"
                >
                  <i class="ni ni-favourite-28"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="card card-product">
            <div class="card-image">
              <a href="#pablo">
                <img
                  class="img rounded"
                  src="assets/img/pages/jeans.png"
                />
              </a>
            </div>
            <div class="card-body">
              <h6 class="category text-warning">Trending</h6>
              <h4 class="card-title">
                <a class="card-link" href="javascript:;"> D &amp; G </a>
              </h4>
              <div class="card-description opacity-8 mt-2">
                Dolce &amp; Gabbana's 'Greta' tote has been crafted in Italy
                from hard-wearing red textured-leather.
              </div>
              <div class="card-footer">
                <div class="price-container">
                  <span class="price"> € 1,359 </span>
                </div>
                <button
                  class="btn btn-simple btn-warning btn-icon-only btn-sm btn-round pull-right"
                  placement="left"
                >
                  <i class="ni ni-favourite-28"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
