<nav
  class="navbar navbar-main navbar-expand-lg navbar-dark headroom"
  id="navbar-main"
>
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/index']">
      <img src="assets/img/brand/argon-white.png" id="brand-logo"/>
    </a>
    <button
      aria-controls="navbar_global"
      aria-label="Toggle navigation"
      class="navbar-toggler"
      [attr.aria-expanded]="!isCollapsed"
      (click)="isCollapsed = !isCollapsed"
      id="navbar_global"
      type="button"
    >
      <span class="navbar-toggler-icon"> </span>
    </button>
    <div
      class="navbar-collapse"
      [isAnimated]="true"
      [collapse]="isCollapsed"
      id="navbar_global"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/index']">
              <img src="assets/img/brand/blue.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              aria-controls="navbar_global"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="navbar_global"
              type="button"
            >
              <span> </span> <span> </span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto" *ngIf="mobileView()">
        <li class="nav-item dropdown" dropdown>
          <a
            class="nav-link"
            data-toggle="dropdown"
            dropdownToggle
            href="javascript:;"
            role="button"
          >
            <i class="ni ni-compass-04 d-lg-none"> </i>
            <span class="nav-link-inner--text"> Insurance Ecosystem </span>
          </a>
          <div class="dropdown-menu dropdown-menu-xl" *dropdownMenu >
            <div class="dropdown-menu-inner">
              <a class="media d-flex align-items-center" [routerLink]="['/insurtech']">
                <div
                  class="icon icon-shape bg-gradient-warning rounded-circle text-white"
                >
                  <i class="ni ni-spaceship"> </i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">InsurTech</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{insurtechSubline}}
                  </p>
                </div>
              </a>
              <a
                class="media d-flex align-items-center"
                [routerLink]="['/markets']"
              >
                <div
                  class="icon icon-shape bg-gradient-info rounded-circle text-white"
                >
                  <i class="ni ni-basket"> </i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Markets</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{marketsSubline}}
                  </p>
                </div>
              </a>
              <a
                class="media d-flex align-items-center"
                [routerLink]="['/finance']"
              >
                <div
                  class="icon icon-shape bg-gradient-success rounded-circle text-white"
                >
                  <i class="ni ni-credit-card"> </i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Finance</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{financeSubline}}
                  </p>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a
                class="dropdown-item"
                [routerLink]="['/about-us/history']"
              >
                <i class="ni ni-atom"> </i> Our History
              </a>
              <a
                class="dropdown-item"
                [routerLink]="['/about-us/culture']"
              >
                <i class="ni ni-diamond"> </i> Our Culture
              </a>
              <a
                class="dropdown-item"
                [routerLink]="['/about-us/philosophy']"
              >
                <i class="ni ni-compass-04"> </i> Our Philosophy
              </a>
            </div>
          </div>
        </li>

        <li class="nav-item dropdown" dropdown>
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="javascript:;"
            dropdownToggle
            role="button"
          >
            <i class="ni ni-single-copy-04 d-lg-none"> </i>
            <span class="nav-link-inner--text"> Our Services </span>
          </a>
          <div
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu"
            *dropdownMenu
          >
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-badge text-info"> </i> Leads
          </a>
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-notification-70 text-danger"> </i> Marketing
          </a>
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-map-big text-primary"> </i> Process
          </a>
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-credit-card text-danger"> </i> Payment
          </a>
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-zoom-split-in text-purple"> </i> Enrichment
          </a>
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-planet text-pink"> </i> Innovation
          </a>
          <a class="dropdown-item" [routerLink]="['#']">
            <i class="ni ni-paper-diploma text-muted"> </i> Training
          </a>
          <!-- <a class="dropdown-item" [routerLink]="['/services/leads']">
            <i class="ni ni-badge text-info"> </i> Leads
          </a>
          <a class="dropdown-item" [routerLink]="['services/marketing']">
            <i class="ni ni-notification-70 text-danger"> </i> Marketing
          </a>
          <a class="dropdown-item" [routerLink]="['services/process']">
            <i class="ni ni-map-big text-primary"> </i> Process
          </a>
          <a class="dropdown-item" [routerLink]="['services/payment']">
            <i class="ni ni-credit-card text-danger"> </i> Payment
          </a>
          <a class="dropdown-item" [routerLink]="['services/enrichment']">
            <i class="ni ni-zoom-split-in text-purple"> </i> Enrichment
          </a>
          <a class="dropdown-item" [routerLink]="['services/innovation']">
            <i class="ni ni-planet text-pink"> </i> Innovation
          </a>
          <a class="dropdown-item" [routerLink]="['services/training']">
            <i class="ni ni-paper-diploma text-muted"> </i> Training
          </a> -->
          </div>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="javascript:;"
            dropdownToggle
            role="button"
          >
            <i class="ni ni-tablet-button d-lg-none"> </i>
            <span class="nav-link-inner--text"> Resources </span>
          </a>
          <div
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu"
            *dropdownMenu
          >
          <a class="dropdown-item"  href="https://news.insureco.io">
            <i class="ni ni-notification-70 text-muted"> </i> News
          </a>
          <a class="dropdown-item" href="https://insureco.atlassian.net/servicedesk/customer/portals">
            <i class="ni ni-support-16 text-pink"> </i> Support
          </a>
          <a class="dropdown-item" href="https://platform.insureco.io">
            <i class="ni ni-floors text-info"> </i> Platform
          </a>
          <a class="dropdown-item" href="https://stats.uptimerobot.com/0077GH2k8x">
            <i class="ni ni-world text-success"> </i> API Status
          </a>
          <a class="dropdown-item" [routerLink]="['/ecosearch']">
            <i class="ni ni-world text-success"> </i> EcoSearch
          </a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto" *ngIf="!mobileView()">
        
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="javascript:;"
            role="button"
          >
            <i class="ni ni-compass-04 d-lg-none"> </i>
            <span class="nav-link-inner--text"> Insurance Ecosystem </span>
          </a>
          <div class="dropdown-menu dropdown-menu-xl">
            <div class="dropdown-menu-inner">
              <a class="media d-flex align-items-center" [routerLink]="['/insurtech']">
                <div
                  class="icon icon-shape bg-gradient-warning rounded-circle text-white"
                >
                  <i class="ni ni-spaceship"> </i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">InsurTech</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{insurtechSubline}}
                  </p>
                </div>
              </a>
              <a
                class="media d-flex align-items-center"
                [routerLink]="['/markets']"
              >
                <div
                  class="icon icon-shape bg-gradient-info rounded-circle text-white"
                >
                  <i class="ni ni-basket"> </i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Markets</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{marketsSubline}}
                  </p>
                </div>
              </a>
              <a
                class="media d-flex align-items-center"
                [routerLink]="['/finance']"
              >
                <div
                  class="icon icon-shape bg-gradient-success rounded-circle text-white"
                >
                  <i class="ni ni-credit-card"> </i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Finance</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{financeSubline}}
                  </p>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a
                class="dropdown-item"
                [routerLink]="['/about-us/history']"
              >
                <i class="ni ni-atom"> </i> Our History
              </a>
              <a
                class="dropdown-item"
                [routerLink]="['/about-us/culture']"
              >
                <i class="ni ni-diamond"> </i> Our Culture
              </a>
              <a
                class="dropdown-item"
                [routerLink]="['/about-us/philosophy']"
              >
                <i class="ni ni-compass-04"> </i> Our Philosophy
              </a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="javascript:;"
            role="button"
          >
            <i class="ni ni-app d-lg-none"> </i>
            <span class="nav-link-inner--text"> Our Services </span>
          </a>
          <div
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu"
          >
          <a class="dropdown-item" [routerLink]="['#']">
            Links Coming Soon
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-badge text-info"> </i> Leads
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-notification-70 text-danger"> </i> Marketing
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-map-big text-primary"> </i> Process
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-credit-card text-danger"> </i> Payment
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-zoom-split-in text-purple"> </i> Enrichment
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-planet text-pink"> </i> Innovation
            </a>
            <a class="dropdown-item" [routerLink]="['#']">
              <i class="ni ni-paper-diploma text-muted"> </i> Training
            </a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="javascript:;"
            role="button"
          >
            <i class="ni ni-tablet-button d-lg-none"> </i>
            <span class="nav-link-inner--text"> Resources </span>
          </a>
          <div
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu"
          >
          <a class="dropdown-item" href="https://news.insureco.io">
            <i class="ni ni-notification-70 text-muted"> </i> News
          </a>
          <a class="dropdown-item" href="https://insureco.atlassian.net/servicedesk/customer/portals">
            <i class="ni ni-support-16 text-pink"> </i> Support
          </a>
          <a class="dropdown-item" href="https://platform.insureco.io">
            <i class="ni ni-world-2 text-info"> </i> Platform
          </a>
          <a class="dropdown-item" href="https://stats.uptimerobot.com/0077GH2k8x">
            <i class="ni ni-controller text-success"> </i> API Status
          </a>
          <a class="dropdown-item" [routerLink]="['/ecosearch']">
            <i class="ni ni-world text-success"> </i> EcoSearch
            <span class="badge badge-pill badge-default">soon</span>
          </a>
          </div>
        </li>

      </ul>
      <a
        class="btn btn-danger btn-icon mb-3 mb-sm-0"
        href="https://start.insureco.io"
      >
        <span class="btn-inner--icon">
          <i class="fas fa-rocket"> </i>
        </span>
        <span class="btn-inner--text"> Get Started </span>
      </a>
    </div>
  </div>
</nav>
