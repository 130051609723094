
<div class="wrapper">
  <div class="cd-section" id="headers">
    <header class="header-1">
      <nav class="navbar navbar-expand-lg bg-default navbar-absolute">
        <div class="container">
          <a class="navbar-brand text-white" href="javascript:;">
            Argon
          </a>
          <button
            aria-controls="navbar-header-1"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            [attr.aria-expanded]="!isCollapsed"
            (click)="isCollapsed = !isCollapsed"
            id="navbar-header-1"
            type="button"
          >
            <span class="navbar-toggler-icon"> </span>
          </button>
          <div
            class="navbar-collapse"
            [isAnimated]="true"
            [collapse]="isCollapsed"
            id="navbar-header-1"
          >
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <a  [routerLink]="['/index']">
                    <img src="assets/img/brand/blue.png" />
                  </a>
                </div>
                <div class="col-6 collapse-close">
                  <button
                    aria-controls="navbar-header-1"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    [attr.aria-expanded]="!isCollapsed"
                    (click)="isCollapsed = !isCollapsed"
                    id="navbar-header-1"
                    type="button"
                  >
                    <span> </span> <span> </span>
                  </button>
                </div>
              </div>
            </div>
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a class="nav-link text-white" href="javascript:;">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-white" href="javascript:;">
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-white" href="javascript:;">
                  Contact Us
                </a>
              </li>
            </ul>
            <ul class="nav navbar-nav">
              <li class="nav-item">
                <a
                  class="nav-link text-white"
                  href="https://twitter.com/CreativeTim"
                >
                  <i class="fab fa-twitter"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-white"
                  href="https://www.facebook.com/CreativeTim"
                >
                  <i class="fab fa-facebook-square"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-white"
                  href="https://www.instagram.com/CreativeTimOfficial"
                >
                  <i class="fab fa-instagram"> </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header">
        <div
          class="page-header-image"
          style="background-image: url('assets/img/ill/p2.svg')"
        ></div>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-md-7 mr-auto text-left d-flex justify-content-center flex-column"
            >
              <h3 class="display-3">Argon Design System</h3>
              <p class="lead mt-0">
                The time is now for it to be okay to be great. People in
                this world shun people for being great. For being a bright
                color.
              </p>
              <br />

              <div class="buttons">
                <a class="btn btn-danger" href="javascript:;"> Got it </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="header-2 skew-separator">
      <nav class="navbar navbar-expand-lg bg-white navbar-absolute">
        <div class="container">
          <div class="navbar-translate">
            <a class="navbar-brand" href="javascript:;"> Creative Tim </a>
            <button
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="example-header-2"
              type="button"
            >
              <span class="navbar-toggler-icon"> </span>
            </button>
          </div>
          <div
            class="navbar-collapse"
            [isAnimated]="true"
            [collapse]="isCollapsed"
            id="example-header-2"
          >
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <a> Argon <span> PRO </span> </a>
                </div>
                <div class="col-6 collapse-close text-right">
                  <button
                    aria-controls="navigation-index"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    [attr.aria-expanded]="!isCollapsed"
                    (click)="isCollapsed = !isCollapsed"
                    id="example-header-2"
                    type="button"
                  >
                    <span> </span> <span> </span>
                  </button>
                </div>
              </div>
            </div>
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> About Us </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Products </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Contact Us </a>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li class="nav-item">
                <a class="nav-link" href="https://twitter.com/CreativeTim">
                  <i class="fab fa-twitter"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.facebook.com/CreativeTim"
                >
                  <i class="fab fa-facebook-square"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.instagram.com/CreativeTimOfficial"
                >
                  <i class="fab fa-instagram"> </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header">
        <div
          class="page-header-image"
          style="background-image: url('assets/img/ill/p8.svg')"
        ></div>
        <div class="container pt-300">
          <div class="row">
            <div class="col-md-8 mx-auto text-center">
              <h2 class="display-2">Trello lets you work</h2>
            </div>
          </div>
          <div class="row">
            <div class="floating-box bg-default">
              <div class="box text-center">
                <div
                  class="icon icon-shape bg-success icon-xl rounded-circle text-white"
                >
                  <i class="ni ni-spaceship"> </i>
                </div>
              </div>
              <h2 class="lead text-white p-5">
                As a result of growing greenhouse gas emissions, climate
                models predict that our planet will get significantly
                warmer, that ecosystems will be changed or destroyed, and
                that enormous human and economic costs will be incurred.
                These scenarios aren't guaranteed, but avoiding them will be
                very hard. We're trying to take small steps to mitigate our
                impact.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="header-3 bg-dark">
      <nav
        class="navbar navbar-expand-lg navbar-transparent navbar-absolute"
      >
        <div class="container">
          <div class="navbar-translate">
            <a class="navbar-brand" href="javascript:;"> Creative Tim </a>
            <button
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="example-header-3"
              type="button"
            >
              <span class="navbar-toggler-icon"> </span>
            </button>
          </div>
          <div
            class="navbar-collapse"
            [isAnimated]="true"
            [collapse]="isCollapsed"
            id="example-header-3"
          >
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <a> Argon <span> Design System PRO </span> </a>
                </div>
                <div class="col-6 collapse-close text-right">
                  <button
                    aria-controls="navigation-index"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    [attr.aria-expanded]="!isCollapsed"
                    (click)="isCollapsed = !isCollapsed"
                    id="example-header-3"
                    type="button"
                  >
                    <span> </span> <span> </span>
                  </button>
                </div>
              </div>
            </div>
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> About Us </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Contact Us </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header header-filter">
        <div class="content-center">
          <div class="row">
            <div class="col-lg-5 col-md-8 col-12 mx-auto positioned">
              <h4 class="title text-white text-uppercase ml-0">
                Build stunning websites and apps
              </h4>
              <div class="info info-horizontal ml-0">
                <div
                  class="icon icon-shape bg-white shadow rounded-circle text-default"
                >
                  <i class="ni ni-active-40"> </i>
                </div>
                <div class="description">
                  <h6 class="info-title text-uppercase text-white pl-0">
                    Connect with founders
                  </h6>
                  <p class="text-white opacity-8">
                    As we live, our hearts turn colder. Cause pain is what
                    we go through as we become older and we get insulted
                  </p>
                </div>
              </div>
              <div class="info info-horizontal ml-0">
                <div
                  class="icon icon-shape bg-white shadow rounded-circle text-default"
                >
                  <i class="ni ni-trophy"> </i>
                </div>
                <div class="description">
                  <h6 class="info-title text-uppercase text-white pl-0">
                    Learn from experts
                  </h6>
                  <p class="text-white opacity-8">
                    We get back stabbed by friends. It becomes harder for us
                    to give others a hand. We get our heart broken by people
                    we love.
                  </p>
                </div>
              </div>
              <div class="info info-horizontal ml-0">
                <div
                  class="icon icon-shape bg-white shadow rounded-circle text-default"
                >
                  <i class="ni ni-paper-diploma"> </i>
                </div>
                <div class="description">
                  <h6 class="info-title text-uppercase text-white pl-0">
                    Get practical advice
                  </h6>
                  <p class="text-white opacity-8">
                    When we lose family over time. What else could rust the
                    heart more over time? Blackgold. It becomes harder...
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-md-6">
              <div class="glide">
                <div class="glide__track" data-glide-el="track">
                  <ul class="glide__slides">
                    <li class="glide__slide">
                      <img
                        height="500"
                        src="assets/img/theme/victor-garcia.jpg"
                        width="450"
                      />
                    </li>
                    <li class="glide__slide">
                      <img
                        height="500"
                        src="assets/img/theme/sacha-styles.jpg"
                        width="450"
                      />
                    </li>
                    <li class="glide__slide">
                      <img
                        height="500"
                        src="assets/img/theme/doyoun-seo.jpg"
                        width="450"
                      />
                    </li>
                    <li class="glide__slide">
                      <img
                        height="500"
                        src="assets/img/theme/sofia-kuniakina.jpg"
                        width="450"
                      />
                    </li>
                    <li class="glide__slide">
                      <img
                        height="500"
                        src="assets/img/theme/ayo-ogunseinde.jpg"
                        width="450"
                      />
                    </li>
                  </ul>
                </div>
                <div class="glide__arrows" data-glide-el="controls">
                  <button
                    class="glide__arrow glide__arrow--left"
                    data-glide-dir="<"
                    aria-label="Previous"
                  >
                    <i class="ni ni-bold-left"> </i>
                  </button>
                  <button
                    class="glide__arrow glide__arrow--right"
                    data-glide-dir=">"
                    aria-label="Next"
                  >
                    <i class="ni ni-bold-right"> </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="header-4 skew-separator">
      <div class="header-wrapper">
        <nav class="navbar navbar-expand-lg navbar-transparent">
          <div class="container">
            <div class="navbar-translate">
              <a class="navbar-brand text-white" href="javascript:;">
                Creative Tim
              </a>
              <button
                aria-controls="navbarSupportedContent"
                aria-label="Toggle navigation"
                class="navbar-toggler"
                [attr.aria-expanded]="!isCollapsed"
                (click)="isCollapsed = !isCollapsed"
                id="example-header-4"
                type="button"
              >
                <span class="navbar-toggler-icon"> </span>
              </button>
            </div>
            <div
              class="navbar-collapse"
              [isAnimated]="true"
              [collapse]="isCollapsed"
              id="example-header-4"
            >
              <div class="navbar-collapse-header">
                <div class="row">
                  <div class="col-6 collapse-brand">
                    <a> Argon <span> PRO </span> </a>
                  </div>
                  <div class="col-6 collapse-close text-right">
                    <button
                      aria-controls="navigation-index"
                      aria-label="Toggle navigation"
                      class="navbar-toggler"
                      [attr.aria-expanded]="!isCollapsed"
                      (click)="isCollapsed = !isCollapsed"
                      id="example-header-4"
                      type="button"
                    >
                      <span> </span> <span> </span>
                    </button>
                  </div>
                </div>
              </div>
              <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                  <a class="nav-link" href="javascript:;"> Home </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:;"> About Us </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:;"> Products </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:;"> Contact Us </a>
                </li>
              </ul>
              <ul class="nav navbar-nav navbar-right">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://twitter.com/CreativeTim"
                  >
                    <i class="fab fa-twitter"> </i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://www.facebook.com/CreativeTim"
                  >
                    <i class="fab fa-facebook-square"> </i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://www.instagram.com/CreativeTimOfficial"
                  >
                    <i class="fab fa-instagram"> </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div class="page-header header-video">
          <div class="overlay"></div>
          <video
            autoplay="autoplay"
            loop="loop"
            muted="muted"
            playsinline="playsinline"
          >
            <source
              src="https://www.oberlo.com/wp-content/uploads/2018/10/1.-Pixabay.mp4"
              type="video/mp4"
            />
          </video>
          <div class="container text-center">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <h1 class="video-text">People stories</h1>
                <h1 class="display-3 text-white">
                  The time is right now!
                </h1>
                <a
                  class="btn btn-warning btn-icon mt-3 mb-sm-0"
                  href="https://www.creative-tim.com/product/argon-design-system-pro"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-button-play"> </i>
                  </span>
                  <span class="btn-inner--text"> Play more </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="header-5">
      <nav class="navbar navbar-expand-lg navbar-absolute">
        <div class="container">
          <div class="navbar-translate">
            <a class="navbar-brand" href="javascript:;"> Creative Tim </a>
            <button
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="example-header-6"
              type="button"
            >
              <span class="navbar-toggler-icon"> </span>
            </button>
          </div>
          <div
            class="navbar-collapse"
            [isAnimated]="true"
            [collapse]="isCollapsed"
            id="example-header-6"
          >
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <a> Argon <span> PRO </span> </a>
                </div>
                <div class="col-6 collapse-close text-right">
                  <button
                    aria-controls="navigation-index"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    [attr.aria-expanded]="!isCollapsed"
                    (click)="isCollapsed = !isCollapsed"
                    id="example-header-6"
                    type="button"
                  >
                    <span> </span> <span> </span>
                  </button>
                </div>
              </div>
            </div>
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> About Us </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Products </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Contact Us </a>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li class="nav-item">
                <a class="nav-link" href="https://twitter.com/CreativeTim">
                  <i class="fab fa-twitter"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.facebook.com/CreativeTim"
                >
                  <i class="fab fa-facebook-square"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.instagram.com/CreativeTimOfficial"
                >
                  <i class="fab fa-instagram"> </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header">
        <div class="container">
          <div class="row align-items-center text-left">
            <div class="col-lg-5 col-12">
              <h1 class="display-3">
                Design with us,<span class="text-primary">
                  Develop Anything.
                </span>
              </h1>
              <p class="lead pb-4">
                We aim high at being focused on building relationships with
                our clients and community. Using our creative gifts drives
                this foundation.
              </p>
              <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <input
                    aria-label="Your email"
                    class="form-control"
                    id="signupSrEmail"
                    name="email"
                    placeholder="Your email"
                    type="email"
                  />
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <button class="btn btn-primary btn-block" type="submit">
                    More
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-12 pl-0">
              <img
                class="ml-lg-5"
                src="assets/img/ill/bg6-2.svg"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
  <div class="cd-section" id="features">
    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <span class="badge badge-primary badge-pill mb-3">
              Insight
            </span>
            <h3 class="display-3">Full-Funnel Social Analytics</h3>
            <p class="lead">
              The time is now for it to be okay to be great. For being a
              bright color. For standing out.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle"
              >
                <i class="ni ni-settings-gear-65"> </i>
              </div>
              <h6 class="info-title text-uppercase text-primary">
                Social Conversations
              </h6>
              <p class="description opacity-8">
                We get insulted by others, lose trust for those others. We
                get back stabbed by friends. It becomes harder for us to
                give others a hand.
              </p>
              <a class="text-primary" href="javascript:;">
                More about us
                <i class="ni ni-bold-right text-primary"> </i>
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle"
              >
                <i class="ni ni-atom"> </i>
              </div>
              <h6 class="info-title text-uppercase text-success">
                Analyze Performance
              </h6>
              <p class="description opacity-8">
                Don't get your heart broken by people we love, even that we
                give them all we have. Then we lose family over time. As we
                live, our hearts turn colder.
              </p>
              <a class="text-primary" href="javascript:;">
                Learn about our products
                <i class="ni ni-bold-right text-primary"> </i>
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle"
              >
                <i class="ni ni-world"> </i>
              </div>
              <h6 class="info-title text-uppercase text-warning">
                Measure Conversions
              </h6>
              <p class="description opacity-8">
                What else could rust the heart more over time? Blackgold.
                The time is now for it to be okay to be great. or being a
                bright color. For standing out.
              </p>
              <a class="text-primary" href="javascript:;">
                Check our documentation
                <i class="ni ni-bold-right text-primary"> </i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-2">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-8 mr-auto text-left">
            <div class="pr-md-5">
              <div
                class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5"
              >
                <i class="ni ni-favourite-28"> </i>
              </div>
              <h3>Awesome features</h3>
              <p>
                The kit comes with three pre-built pages to help you get
                started faster. You can change the text and images and
                you're good to go.
              </p>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-settings-gear-65"> </i>
                      </div>
                    </div>
                    <div>
                      <h6 class="mb-0">Carefully crafted components</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-html5"> </i>
                      </div>
                    </div>
                    <div><h6 class="mb-0">Amazing page examples</h6></div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-satisfied"> </i>
                      </div>
                    </div>
                    <div>
                      <h6 class="mb-0">Super friendly support team</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 pl-md-0">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="info text-left bg-info shadow">
                  <div
                    class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-satisfied text-info"> </i>
                  </div>
                  <h5 class="info-title text-white">Best Quality</h5>
                  <p class="description">
                    What matters is the people who are sparked by it. And
                    the people who are like offended by it.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="info text-left bg-danger info-raised shadow">
                  <div
                    class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-palette text-danger"> </i>
                  </div>
                  <h5 class="info-title text-white">Awesome Design</h5>
                  <p class="description">
                    Because it's about motivating the doers. Because I'm
                    here to follow my dreams and inspire the world.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="info text-left bg-default shadow">
                  <div
                    class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-spaceship text-default"> </i>
                  </div>
                  <h5 class="info-title text-white">Great Performance</h5>
                  <p class="description">
                    There's nothing I really wanted to do in life that I
                    wasn't able to get good at. We're constantly growing.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="info text-left bg-primary shadow">
                  <div
                    class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-planet text-primary"> </i>
                  </div>
                  <h5 class="info-title text-white">Fast Development</h5>
                  <p class="description">
                    I always felt like I could do anything. That's the main
                    thing people are controlled by the force!
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="info text-left bg-warning info-raised shadow">
                  <div
                    class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-glasses-2 text-warning"> </i>
                  </div>
                  <h5 class="info-title text-white">Super Fresh</h5>
                  <p class="description">
                    Thoughts- their perception of themselves! They're slowed
                    down by their perception of themselves.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="info text-left bg-success shadow">
                  <div
                    class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-notification-70 text-success"> </i>
                  </div>
                  <h5 class="info-title text-white">Organized Content</h5>
                  <p class="description">
                    If you're taught you can't do anything, you won't do
                    anything. I was taught I could do everything.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="section features-3 my-5"
      style="background-image: url('assets/img/ill/p31.svg')"
    >
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-8">
            <h3 class="display-3 text-white">
              Build something great<span class="text-success">
                with our products
              </span>
            </h3>
            <p class="lead text-white">
              We're constantly trying to express ourselves and actualize our
              dreams. If you have the opportunity to play this game of life
              you need to appreciate every moment.
            </p>
          </div>
        </div>
        <div class="row row-grid mt-5">
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-hat-3 text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-info">Modular Components</h5>
                <p>
                  The Arctic Ocean freezes every winter and much of the
                  sea-ice then thaws every summer, and that process will
                  continue whatever.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-warning rounded-circle text-white"
              >
                <i class="ni ni-istanbul text-warning"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-warning">Modern Interface</h5>
                <p>
                  If everything I did failed - which it doesn't, it actually
                  succeeds - just the fact that I'm willing to fail is an
                  inspiration.
                </p>
                <a class="text-warning" href="#"> Learn more </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid">
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-trophy text-danger"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-danger">Great Features</h5>
                <p>
                  People are so scared to lose that they don't even try.
                  Like, one thing people can't say is that I'm not trying,
                  and I'm not trying my hardest.
                </p>
                <a class="text-danger" href="#"> Learn more </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-success rounded-circle text-white"
              >
                <i class="ni ni-image text-success"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title text-success">Awesome Support</h5>
                <p>
                  The time is now for it to be okay to be great. People in
                  this world shun people for being great. For being a bright
                  color.
                </p>
                <a class="text-success" href="#"> Learn more </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3">The time is now</h3>
            <p class="lead">
              The time is now to be okay to be the greatest you. Would you
              believe in what you believe in, if you were the only one who
              believed it?
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 mr-auto text-left mt-4">
            <div class="card bg-default shadow border-0">
              <img
                class="card-img-top"
                src="assets/img/theme/img-1-1200x1000.jpg"
              />

              <blockquote class="card-blockquote">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-bg"
                  preserveaspectratio="none"
                  viewbox="0 0 583 95"
                >
                  <polygon
                    class="fill-default"
                    points="0,52 583,95 0,95"
                  ></polygon>
                  <polygon
                    class="fill-default"
                    opacity=".2"
                    points="0,42 583,95 683,0 0,95"
                  ></polygon>
                </svg>
                <h4 class="display-4 text-white">Design System</h4>
                <p class="lead text-italic text-white">
                  That's my skill. I'm not really specifically talented at
                  anything except for the ability to learn.
                </p>
              </blockquote>
            </div>
          </div>
          <div class="col-lg-7 p-sm-0">
            <div class="row">
              <div class="col-md-6">
                <div class="info info-hover-warning">
                  <div
                    class="icon icon-shape bg-warning shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-satisfied text-white"> </i>
                  </div>
                  <h5 class="info-title">Best Quality</h5>
                  <p class="description opacity-8">
                    It becomes harder for us to give others a hand. We get
                    our heart broken by people we love.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info info-hover-info">
                  <div
                    class="icon icon-shape bg-info shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-palette text-white"> </i>
                  </div>
                  <h5 class="info-title">Awesome Design</h5>
                  <p class="description opacity-8">
                    As we live, our hearts turn colder. Cause pain is what
                    we go through as we become older.
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-lg-4">
              <div class="col-md-6">
                <div class="info info-hover-danger">
                  <div
                    class="icon icon-shape bg-danger shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-user-run text-white"> </i>
                  </div>
                  <h5 class="info-title">Fast Development</h5>
                  <p class="description opacity-8">
                    We're not always in the position that we want to be at.
                    We're constantly growing.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info info-hover-success">
                  <div
                    class="icon icon-shape bg-success shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-glasses-2 text-white"> </i>
                  </div>
                  <h5 class="info-title">Super Fresh</h5>
                  <p class="description opacity-8">
                    When we lose family over time. What else could rust the
                    heart more over time? Blackgold.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features features-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3">Company values</h3>
            <p class="lead">
              There's nothing I wanted to do in life that I wasn't able to
              get good at. I'm not really specifically talented at anything
              except for the ability to learn.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 mt-md-5">
            <div class="row">
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div
                      class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                    >
                      <i class="ni ni-atom"> </i>
                    </div>
                    <h5 class="display-5">Awesome features</h5>
                    <p>
                      The kit comes with three pre-built pages to help you
                      get started faster.
                    </p>
                    <ul class="list-unstyled">
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div><p class="mb-0">Beautiful elements</p></div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Amazing page examples</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Super friendly support team</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div
                      class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                    >
                      <i class="ni ni-money-coins"> </i>
                    </div>
                    <h5 class="display-5">Best prices</h5>
                    <p>
                      If you're taught you can't do anything, you won't do
                      anything.
                    </p>
                    <ul class="list-unstyled">
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div><p class="mb-0">Beautiful elements</p></div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Amazing page examples</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Super friendly support team</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div
                      class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                    >
                      <i class="ni ni-support-16"> </i>
                    </div>
                    <h5 class="display-5">Community support</h5>
                    <p>
                      That's the main thing people are always controlled by!
                    </p>
                    <ul class="list-unstyled">
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div><p class="mb-0">Beautiful elements</p></div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Amazing page examples</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Super friendly support team</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info">
                  <div class="pr-md-5">
                    <div
                      class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                    >
                      <i class="ni ni-laptop"> </i>
                    </div>
                    <h5 class="display-5">Responsive componenets</h5>
                    <p>
                      They're slowed down by their perception of themselves.
                    </p>
                    <ul class="list-unstyled">
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div><p class="mb-0">Beautiful elements</p></div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Amazing page examples</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="mr-3">
                              <i class="ni ni-check-bold"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-0">Super friendly support team</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-6">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="info info-horizontal info-hover-primary">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-html5 text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title">For Developers</h5>
                <p>
                  The time is now for it to be okay to be great. People in
                  this world shun people for being great. For being a bright
                  color. For standing out. But the time is now.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
            <div class="info info-horizontal info-hover-primary">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-app text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title">For Designers</h5>
                <p>
                  There's nothing I really wanted to do in life that I
                  wasn't able to get good at. That's my skill. I'm not
                  really specifically talented at anything except for the
                  ability to learn.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
            <div class="info info-horizontal info-hover-primary">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-bell-55 text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title">For Beginners</h5>
                <p>
                  That's what I do. That's what I'm here for. Don't be
                  afraid to be wrong because you can't learn anything from a
                  compliment. If everything I did failed - which it doesn't.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-10 mx-md-auto">
            <img
              class="ml-lg-5"
              src="assets/img/ill/ill.png"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section features-7 bg-secondary">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3">Follow your dreams</h3>
            <p class="lead">
              We're constantly trying to express ourselves and actualize our
              dreams. If you have the opportunity to play this game of life
              you need to appreciate every moment.
            </p>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <div class="card card-lift--hover shadow border-0">
                  <div class="card-body py-5">
                    <div
                      class="icon icon-shape icon-shape-primary rounded-circle mb-4"
                    >
                      <i class="ni ni-check-bold"> </i>
                    </div>
                    <h6 class="text-primary text-uppercase">
                      Download Argon
                    </h6>
                    <p class="description mt-3">
                      Because I'm here to follow my dreams and inspire other
                      people to follow their dreams, too. That's what I'm
                      here for.
                    </p>
                    <a class="btn btn-primary mt-4" href="#">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-lift--hover shadow border-0">
                  <div class="card-body py-5">
                    <div
                      class="icon icon-shape icon-shape-success rounded-circle mb-4"
                    >
                      <i class="ni ni-istanbul"> </i>
                    </div>
                    <h6 class="text-success text-uppercase">
                      Build Something
                    </h6>
                    <p class="description mt-3">
                      Society has put up so many boundaries, so many
                      limitations on what's right and wrong that it's almost
                      impossible.
                    </p>
                    <a class="btn btn-success mt-4" href="#">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-lift--hover shadow border-0">
                  <div class="card-body py-5">
                    <div
                      class="icon icon-shape icon-shape-warning rounded-circle mb-4"
                    >
                      <i class="ni ni-planet"> </i>
                    </div>
                    <h6 class="text-warning text-uppercase">
                      Prepare Launch
                    </h6>
                    <p class="description mt-3">
                      Why would anyone pick blue over pink? Pink is
                      obviously a better color.Everyone's born confident,
                      and everything's...
                    </p>
                    <a class="btn btn-warning mt-4" href="#">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cd-section" id="blogs">
    <section class="blogs-1">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-8">
            <h3 class="display-3">Our recent writings</h3>
            <p class="lead mt-1">
              The time is now for it to be okay to be great. People in this
              world shun people for being great.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/theme/josh-appel.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      New Challenges
                    </h6>
                    <h5 class="card-title">Touch on a trend</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/theme/john-hoang.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      New Opportunities
                    </h6>
                    <h5 class="card-title">Constantly growing</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/theme/kit-suman.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Sales Leads
                    </h6>
                    <h5 class="card-title">
                      Configure Blockchain Technology
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/damian.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      AI at the Edge
                    </h6>
                    <h5 class="card-title">Research Byte</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/ashim.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Spectrum
                    </h6>
                    <h5 class="card-title">Data Virtualization</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/odin.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Touch on a trend
                    </h6>
                    <h5 class="card-title">New Challenges</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <button class="btn btn-icon btn-primary mt-4" type="button">
          <span class="btn-inner--text"> Show more </span>
          <span class="btn-inner--icon">
            <i class="ni ni-bold-right"> </i>
          </span>
        </button>
      </div>
    </section>
    <section class="blogs-2">
      <div class="container-fluid">
        <div class="row mb-md-5">
          <div class="col-md-8 mx-auto">
            <h3 class="display-3 text-center">Our recent writings</h3>
            <p class="lead text-center">
              I always felt like I could do anything. That's the main thing
              people are controlled by! Thoughts- their perception of
              themselves! They're slowed down by their perception of
              themselves. If you're taught you can't do anything, you won't
              do anything. I was taught I could do everything.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/damian.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      AI at the Edge
                    </h6>
                    <h5 class="card-title">Research Byte</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/ashim.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Spectrum
                    </h6>
                    <h5 class="card-title">Data Virtualization</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/odin.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Touch on a trend
                    </h6>
                    <h5 class="card-title">New Challenges</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/dane.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Self-Driving Cars
                    </h6>
                    <h5 class="card-title">Driverless Future</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blogs-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-md-8 mx-auto">
            <h2 class="title mb-5">Related Stories</h2>
            <div class="card card-blog card-plain blog-horizontal mb-5">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/faces/team-2.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h3 class="card-title">
                      <a href="javascript:;">
                        Rover raised $65 million for pet sitting
                      </a>
                    </h3>
                    <p class="card-description">
                      Finding temporary housing for your dog should be as
                      easy as renting an Airbnb. That's the idea behind
                      Rover, which raised $65 million to expand its pet
                      sitting and dog-walking businesses...
                      <a href="javascript:;"> Read More </a>
                    </p>
                    <div class="author">
                      <img
                        alt="..."
                        class="avatar img-raised"
                        src="assets/img/faces/team-1.jpg"
                      />

                      <div class="text">
                        <span class="name"> Tom Hanks </span>
                        <div class="meta">Drawn on 23 Jan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-blog card-plain blog-horizontal mb-5">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/faces/team-3.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h3 class="card-title">
                      <a href="javascript:;">
                        MateLabs mixes learning with IFTTT
                      </a>
                    </h3>
                    <p class="card-description">
                      If you've ever wanted to train a machine learning
                      model and integrate it with IFTTT, you now can with a
                      new offering from MateLabs. MateVerse, a platform
                      where novices can spin out machine...<a
                        href="javascript:;"
                      >
                        Read More
                      </a>
                    </p>
                    <div class="author">
                      <img
                        alt="..."
                        class="avatar img-raised"
                        src="assets/img/faces/team-5.jpg"
                      />

                      <div class="text">
                        <span class="name"> Paul Smith </span>
                        <div class="meta">Drawn on 23 Jan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-blog card-plain blog-horizontal">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/faces/team-4.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h3 class="card-title">
                      <a href="javascript:;">
                        US venture investment ticks up in Q2
                      </a>
                    </h3>
                    <p class="card-description">
                      Venture investment in U.S. startups rose sequentially
                      in the second quarter of 2017, boosted by large,
                      late-stage financings and a few outsized early-stage
                      rounds in tech and healthcare..
                      <a href="javascript:;"> Read More </a>
                    </p>
                    <div class="author">
                      <img
                        alt="..."
                        class="avatar img-raised"
                        src="assets/img/faces/team-2.jpg"
                      />

                      <div class="text">
                        <span class="name"> Jasmine Tailor </span>
                        <div class="meta">Drawn on 23 Jan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blogs-4">
      <div class="container-fluid">
        <h2 class="title mb-4">Latest Blogposts</h2>
        <br />

        <div class="row">
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/athena.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Stellar Partnership
                    </h6>
                    <h5 class="card-title">Climate Change</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/thomas.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Digital Currency
                    </h6>
                    <h5 class="card-title">Save the World</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/odin.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Blockchain Explained
                    </h6>
                    <h5 class="card-title">Applications Companies</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/twk-tt.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      A Robot is Your Co-Worker
                    </h6>
                    <h5 class="card-title">ARFID microchips</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="row row-below">
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/theme/kit-suman.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Sales Leads
                    </h6>
                    <h5 class="card-title">
                      Configure Blockchain Technology
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/damian.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      AI at the Edge
                    </h6>
                    <h5 class="card-title">Research Byte</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/ill/p2.svg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      Features
                    </h6>
                    <h5 class="card-title">FiftyThree Files For Paper</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="card card-blog card-background"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/ill/p8.svg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body">
                  <div class="content-bottom">
                    <h6 class="card-category text-white opacity-8">
                      News
                    </h6>
                    <h5 class="card-title">Focus on Your Employees</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blogs-5">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <h2 class="title mb-5">You may also like:</h2>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="card card-blog card-plain">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/sections/mark-harrison.jpg"
                      />
                    </a>
                  </div>
                  <div class="card-body">
                    <h4 class="card-title">
                      <a href="javascript:;">
                        That's One Way To Ditch Your Passenger
                      </a>
                    </h4>
                    <p class="card-description">
                      As near as we can tell, this guy must have thought he
                      was going over backwards and tapped the rear...
                    </p>
                    <div class="card-footer">
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-3.jpg"
                        />

                        <span> Mike John </span>
                      </div>
                      <div class="stats stats-right opacity-8">
                        <i class="ni ni-watch-time"> </i> 5 min read
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card card-blog card-plain">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/sections/twk-tt.jpg"
                      />
                    </a>
                  </div>
                  <div class="card-body">
                    <h4 class="card-title">
                      <a href="javascript:;">
                        Shark Week: How to Watch It Scientist
                      </a>
                    </h4>
                    <p class="card-description">
                      Just when you thought it was safe to turn on your
                      television, the Discovery Channel's "Shark Week"...
                    </p>
                    <div class="card-footer">
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-2.jpg"
                        />

                        <span> Jona Zmud </span>
                      </div>
                      <div class="stats stats-right opacity-8">
                        <i class="ni ni-watch-time"> </i> 5 min read
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-12">
                <div class="card card-blog card-plain">
                  <div class="card-image shadow">
                    <a href="javascript:;">
                      <img
                        class="img rounded"
                        src="assets/img/sections/pawel-nolbert.jpg"
                      />
                    </a>
                  </div>
                  <div class="card-body">
                    <h4 class="card-title">
                      <a href="javascript:;">
                        Who's Afraid of the Self-Driving Car?
                      </a>
                    </h4>
                    <p class="card-description">
                      It's been 60 years since the cover of Popular
                      Mechanics magazine gave us the promise of flying
                      cars...
                    </p>
                    <div class="card-footer">
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-5.jpg"
                        />

                        <span> Marc Oliver </span>
                      </div>
                      <div class="stats stats-right opacity-8">
                        <i class="ni ni-favourite-28"> </i> 2.4K
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blogs-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <h3 class="display-3 mb-5">Latest Blogposts</h3>
            <div
              class="card card-blog card-background card-grande"
              data-animation="zooming"
            >
              <div
                class="full-background"
                style="background-image: url('assets/img/sections/unsplashs.jpg')"
              ></div>
              <a href="javascript:;">
                <div class="card-body mb-4">
                  <h2 class="card-title">Miami Vice</h2>
                  <p class="card-description text-white">
                    The simplest visual description uses ordinary words to
                    convey what the writer sees. First he or she must look
                    at the subject â slowly, carefully, and repeatedly, if
                    possible â to identify the parts that make the whole
                  </p>
                  <div class="author">
                    <img
                      alt="..."
                      class="avatar"
                      src="assets/img/faces/team-4.jpg"
                    />

                    <span class="text-white"> by Johanna Zmud </span>
                  </div>
                  <br />

                  <a class="btn btn-primary" href="javascript:;">
                    Read more...
                  </a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blogs-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <h3 class="display-3 mb-5">Latest Blogposts</h3>
            <div class="media-area">
              <div class="media-header">
                <div class="row">
                  <div class="col-md-6 d-flex justify-content-start">
                    <div class="avatar">
                      <img
                        alt="..."
                        class="media-object shadow"
                        src="assets/img/faces/team-2.jpg"
                      />
                    </div>
                    <div class="text">
                      <span class="name"> Tom Hanks </span>
                      <div class="meta">Drawn on 23 Jan</div>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex justify-content-end">
                    <div class="btn-group">
                      <button
                        class="btn btn-icon-only btn-outline-secondary btn-sm"
                      >
                        <i class="ni ni-notification-70"> </i>
                      </button>
                      <button
                        class="btn btn-icon-only btn-outline-secondary btn-sm"
                      >
                        <i class="ni ni-chat-round"> </i>
                      </button>
                      <button
                        class="btn btn-icon-only btn-outline-secondary btn-sm"
                      >
                        <i class="ni ni-single-copy-04"> </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="image">
                  <img alt="" src="assets/img/theme/wade.jpg" />
                </div>
                <p class="description">
                  If you've ever wanted to train a machine learning model
                  and integrate it with IFTTT, you now can with a new
                  offering from MateLabs. MateVerse, a platform where
                  novices can spin out machine...If you've ever wanted to
                  train a machine learning model and integrate it with
                  IFTTT, you now can with a new offering from MateLabs.
                  MateVerse, a platform where novices can spin out
                  machine... <a href="javascript:;"> Read More </a>
                </p>
                <p class="hashtag">
                  <a href="javascript:;"> #tothetop </a>
                  <a href="javascript:;"> #allthewayup </a>
                  <a href="javascript:;"> #goodvibesonly </a>
                </p>
                <div class="actions mb-5">
                  <button class="btn btn-outline-info btn-sm">
                    <i class="ni ni-like-2"> </i> Like
                  </button>
                  <button class="btn btn-outline-info btn-sm">
                    <i class="ni ni-curved-next"> </i> Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="cd-section" id="derek">
    <section class="team-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h3 class="display-3">Our Awesome Team 1</h3>
            <p class="lead">
              People in this world shun people for being great. For being a
              bright color. For standing out. But the time is now to be okay
              to be the greatest you. Would you believe in what you believe
              in?
            </p>
          </div>
        </div>
        <div class="row">
          <carousel [showIndicators]="false" class=" carousel-team">
            <slide>
              <div class="container">
                <div class="row">
                  <div class="col-md-5 p-5">
                    <div class="p-4">
                      <img
                        alt="First slide"
                        class="rounded shadow transform-perspective-left"
                        src="assets/img/theme/kareya-saleh.jpg"
                      />
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="wrapper p-md-0">
                      <h3 class="card-title display-3">Charlie Watson</h3>
                      <div class="lead">
                        Artist is a term applied to a person who engages in
                        an activity deemed to be an art. An artist also may
                        be defined unofficially as "a person should is one
                        who expresses him- or herself through a medium". He
                        is should a descriptive term applied to a person who
                        engages in an activity deemed to be an art.
                      </div>
                      <ul class="list-unstyled mb-0">
                        <li class="py-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <div
                                class="badge badge-circle badge-info mr-3"
                              >
                                <i class="ni ni-atom"> </i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-1">Dedicated entrepreneur</p>
                            </div>
                          </div>
                        </li>
                        <li class="py-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <div
                                class="badge badge-circle badge-success mr-3"
                              >
                                <i class="ni ni-user-run"> </i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-1">Urban exploration</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="footer text-left">
                        <a
                          class="btn btn-twitter btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-twitter"> </i>
                        </a>
                        <a
                          class="btn btn-facebook btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-facebook-square"> </i>
                        </a>
                        <a
                          class="btn btn-dribbble btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-dribbble"> </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="container">
                <div class="row">
                  <div class="col-md-5 p-5">
                    <div class="p-4">
                      <img
                        alt="First slide"
                        class="rounded shadow transform-perspective-left"
                        src="assets/img/theme/lucy.jpg"
                      />
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="wrapper p-md-0">
                      <h1 class="card-title">Quavo Austen</h1>
                      <div class="lead">
                        Artist is a term applied to a person who engages in
                        an activity deemed to be an art. An artist also may
                        be defined unofficially as "a person should is one
                        who expresses him- or herself through a medium". He
                        is should a descriptive term applied to a person who
                        engages in an activity deemed to be an art."
                      </div>
                      <ul class="list-unstyled mb-0">
                        <li class="py-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <div
                                class="badge badge-circle badge-info mr-3"
                              >
                                <i class="ni ni-atom"> </i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-1">Dedicated entrepreneur</p>
                            </div>
                          </div>
                        </li>
                        <li class="py-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <div
                                class="badge badge-circle badge-success mr-3"
                              >
                                <i class="ni ni-user-run"> </i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-1">Urban exploration</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="footer text-left">
                        <a
                          class="btn btn-twitter btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-twitter"> </i>
                        </a>
                        <a
                          class="btn btn-facebook btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-facebook-square"> </i>
                        </a>
                        <a
                          class="btn btn-dribbble btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-dribbble"> </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="container">
                <div class="row">
                  <div class="col-md-5 p-5">
                    <div class="p-4">
                      <img
                        alt="First slide"
                        class="rounded shadow transform-perspective-left"
                        src="assets/img/theme/willy-dade.jpg"
                      />
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="wrapper p-md-0">
                      <h1 class="card-title">Lucy Thomson</h1>
                      <div class="lead">
                        Artist is a term applied to a person who engages in
                        an activity deemed to be an art. An artist also may
                        be defined unofficially as "a person should is one
                        who expresses him- or herself through a medium". He
                        is should a descriptive term applied to a person who
                        engages in an activity deemed to be an art."
                      </div>
                      <ul class="list-unstyled mb-0">
                        <li class="py-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <div
                                class="badge badge-circle badge-info mr-3"
                              >
                                <i class="ni ni-atom"> </i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-1">Dedicated entrepreneur</p>
                            </div>
                          </div>
                        </li>
                        <li class="py-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <div
                                class="badge badge-circle badge-success mr-3"
                              >
                                <i class="ni ni-user-run"> </i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-1">Urban exploration</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="footer text-left">
                        <a
                          class="btn btn-twitter btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-twitter"> </i>
                        </a>
                        <a
                          class="btn btn-facebook btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-facebook-square"> </i>
                        </a>
                        <a
                          class="btn btn-dribbble btn-sm rounded-circle"
                          href="javascript:;"
                        >
                          <i class="fab fa-dribbble"> </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </section>
    <div class="team-2">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-5">
            <h3 class="display-3">The Executive Team</h3>
            <h4 class="lead">
              This is the paragraph where you can write more details about
              your team. Keep you user engaged by providing meaningful
              information.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card card-profile" data-image="profile-image">
              <div class="card-header">
                <div class="card-image">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Edit Profile
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Settings
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Log out
                      </a>
                    </div>
                  </div>
                  <a href="javascript:;">
                    <img
                      class="img rounded"
                      src="assets/img/faces/ali-pazani.jpg"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body pt-0">
                <h4 class="display-4 mb-0">Sofia Scarlett</h4>
                <p class="lead">UX Designer</p>
                <div class="table-responsive">
                  <ul class="list-unstyled">
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="badge badge-circle badge-info mr-3">
                            <i class="ni ni-atom"> </i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-1">Keen on great design</h6>
                        </div>
                      </div>
                    </li>
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="badge badge-circle badge-success mr-3"
                          >
                            <i class="ni ni-user-run"> </i>
                          </div>
                        </div>
                        <div><h6 class="mb-1">Outdors lover</h6></div>
                      </div>
                    </li>
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="badge badge-circle badge-danger mr-3"
                          >
                            <i class="ni ni-chart-bar-32"> </i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-1">Super friendly support team</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-profile" data-image="profile-image">
              <div class="card-header">
                <div class="card-image">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Edit Profile
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Settings
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Log out
                      </a>
                    </div>
                  </div>
                  <a href="javascript:;">
                    <img
                      class="img rounded"
                      src="assets/img/faces/team-5.jpg"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body pt-0">
                <h4 class="display-4 mb-0">Dylan Wyatt</h4>
                <p class="lead">Team Lead</p>
                <div class="table-responsive">
                  <ul class="list-unstyled">
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="badge badge-circle badge-info mr-3">
                            <i class="ni ni-atom"> </i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-1">Dedicated entrepreneur</h6>
                        </div>
                      </div>
                    </li>
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="badge badge-circle badge-success mr-3"
                          >
                            <i class="ni ni-user-run"> </i>
                          </div>
                        </div>
                        <div><h6 class="mb-1">Urban exploration</h6></div>
                      </div>
                    </li>
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="badge badge-circle badge-danger mr-3"
                          >
                            <i class="ni ni-chart-bar-32"> </i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-1">
                            Able to get good at everything
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-profile" data-image="profile-image">
              <div class="card-header">
                <div class="card-image">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Edit Profile
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Settings
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Log out
                      </a>
                    </div>
                  </div>
                  <a href="javascript:;">
                    <img
                      class="img rounded"
                      src="assets/img/faces/atikh.jpg"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body pt-0">
                <h4 class="display-4 mb-0">Mila Skylar</h4>
                <p class="lead">Content Creator</p>
                <div class="table-responsive">
                  <ul class="list-unstyled">
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="badge badge-circle badge-info mr-3">
                            <i class="ni ni-atom"> </i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-1">High quality publication</h6>
                        </div>
                      </div>
                    </li>
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="badge badge-circle badge-success mr-3"
                          >
                            <i class="ni ni-user-run"> </i>
                          </div>
                        </div>
                        <div><h6 class="mb-1">Storytelling</h6></div>
                      </div>
                    </li>
                    <li class="py-1">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="badge badge-circle badge-danger mr-3"
                          >
                            <i class="ni ni-chart-bar-32"> </i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-1">
                            Master of words qualification
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-3">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-5">
            <h3 class="display-3">You are into a great company</h3>
            <p class="lead">
              This is the paragraph where you can write more details about
              your team. Keep you user engaged by providing meaningful
              information.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-5 mx-auto">
            <div class="card card-profile" data-background="full">
              <a href="javascript:;">
                <img
                  class="img img-raised rounded"
                  src="assets/img/theme/lucy.jpg"
                />
              </a>
              <div class="card-image">
                <div class="dropdown" dropdown>
                  <button
                    aria-expanded="false"
                    class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                    data-toggle="dropdown"
                    dropdownToggle
                    type="button"
                  >
                    <i class="ni ni-settings-gear-65"> </i>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    *dropdownMenu
                    x-placement="bottom-end"
                  >
                    <a class="dropdown-item" href="javascript:;">
                      Edit Profile
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Settings
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Log out
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <h4 class="display-4">Jane Doe</h4>
                <p class="lead mt-0 mb-1">Scrum Master</p>
                <div class="table-responsive">
                  <table class="table tablesorter" id="plain-table">
                    <tbody>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-hat-3"> </i>
                          </div>
                          Skills
                        </td>
                        <td class="text-right">Leadership</td>
                      </tr>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-satisfied"> </i>
                          </div>
                          Hobbies
                        </td>
                        <td class="text-right">Skiing, Chess</td>
                      </tr>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-bullet-list-67"> </i>
                          </div>
                          Level
                        </td>
                        <td class="text-right">• • • • •</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-5 mx-auto">
            <div class="card card-profile" data-background="full">
              <a href="javascript:;">
                <img
                  class="img img-raised rounded"
                  src="assets/img/theme/charlie.jpg"
                />
              </a>
              <div class="card-image">
                <div class="dropdown" dropdown>
                  <button
                    aria-expanded="false"
                    class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                    data-toggle="dropdown"
                    dropdownToggle
                    type="button"
                  >
                    <i class="ni ni-settings-gear-65"> </i>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    *dropdownMenu
                    x-placement="bottom-end"
                  >
                    <a class="dropdown-item" href="javascript:;">
                      Edit Profile
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Settings
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Log out
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <h4 class="display-4">Hannah Klein</h4>
                <p class="lead mt-0 mb-1">CTO</p>
                <div class="table-responsive">
                  <table class="table tablesorter" id="plain-table">
                    <tbody>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-hat-3"> </i>
                          </div>
                          Skills
                        </td>
                        <td class="text-right">Leadership</td>
                      </tr>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-satisfied"> </i>
                          </div>
                          Hobbies
                        </td>
                        <td class="text-right">Skiing, Chess</td>
                      </tr>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-bullet-list-67"> </i>
                          </div>
                          Level
                        </td>
                        <td class="text-right">• • • • •</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-5 mx-auto">
            <div class="card card-profile" data-background="full">
              <a href="javascript:;">
                <img
                  class="img img-raised rounded"
                  src="assets/img/theme/willy-dade.jpg"
                />
              </a>
              <div class="card-image">
                <div class="dropdown" dropdown>
                  <button
                    aria-expanded="false"
                    class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                    data-toggle="dropdown"
                    dropdownToggle
                    type="button"
                  >
                    <i class="ni ni-settings-gear-65"> </i>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    *dropdownMenu
                    x-placement="bottom-end"
                  >
                    <a class="dropdown-item" href="javascript:;">
                      Edit Profile
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Settings
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Log out
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <h4 class="display-4">Lucy Khan</h4>
                <p class="lead mt-0 mb-1">Brand Strategist</p>
                <div class="table-responsive">
                  <table class="table tablesorter" id="plain-table">
                    <tbody>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-hat-3"> </i>
                          </div>
                          Skills
                        </td>
                        <td class="text-right">Leadership</td>
                      </tr>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-satisfied"> </i>
                          </div>
                          Hobbies
                        </td>
                        <td class="text-right">Skiing, Chess</td>
                      </tr>
                      <tr>
                        <td class="text-left pl-0">
                          <div class="badge badge-circle badge-info mr-2">
                            <i class="ni ni-bullet-list-67"> </i>
                          </div>
                          Level
                        </td>
                        <td class="text-right">• • • • •</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-4">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-5">
            <h3 class="display-3">The Amazing Team</h3>
            <p class="lead">
              Society has put up so many boundaries, so many limitations on
              what's right and wrong that it's almost impossible to get a
              pure thought out. It's like a little kid, a little boy,
              looking at colors.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 ml-auto mr-auto">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-7">
                  <div class="card-image no-mask">
                    <a href="javascript:;">
                      <img
                        class="img"
                        src="assets/img/theme/tom-klein.jpg"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-xl-5">
                  <div class="card-body mt-3">
                    <div class="card-profile-stats">
                      <div class="text-left p-0">
                        <span class="heading"> Willian Pearce </span>
                        <span class="description"> Name </span>
                      </div>
                    </div>
                    <div class="card-profile-stats">
                      <div class="text-left p-0">
                        <span class="heading"> Project Manager </span>
                        <span class="description"> Position </span>
                      </div>
                    </div>
                    <div class="card-profile-stats d-flex">
                      <div class="pl-0">
                        <span class="heading"> 367 </span>
                        <span class="description"> Followers </span>
                      </div>
                      <div>
                        <span class="heading"> 31 </span>
                        <span class="description"> Projects </span>
                      </div>
                      <div>
                        <span class="heading"> 189 </span>
                        <span class="description"> Commits </span>
                      </div>
                    </div>
                    <div class="card-profile-stats">
                      <div class="text-left p-0">
                        <div class="avatar-group mb-2">
                          <a
                            class="avatar avatar-lg rounded-circle shadow"
                            href="javascript:;"
                            tooltip="Ryan Thomson"
                          >
                            <img
                              alt="Image placeholder"
                              src="assets/img/faces/team-1.jpg"
                            />
                          </a>
                          <a
                            class="avatar avatar-lg rounded-circle shadow"
                            href="javascript:;"

                            tooltip="Romina Hadid"
                          >
                            <img
                              alt="Image placeholder"
                              src="assets/img/faces/team-2.jpg"
                            />
                          </a>
                          <a
                            class="avatar avatar-lg rounded-circle shadow"
                            href="javascript:;"
                            tooltip="Alexander Smith"
                          >
                            <img
                              alt="Image placeholder"
                              src="assets/img/faces/team-3.jpg"
                            />
                          </a>
                          <a
                            class="avatar avatar-lg rounded-circle shadow"
                            href="javascript:;"
                            tooltip="Jessica Doe"
                          >
                            <img
                              alt="Image placeholder"
                              src="assets/img/faces/team-4.jpg"
                            />
                          </a>
                        </div>
                        <span class="description"> Team </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <a class="btn btn-link px-0 mt-4" href="javascript:;">
                      View more<i class="ni ni-bold-right"> </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-5">
            <h3 class="display-3">Meet our awesome team</h3>
            <p class="lead">
              People are so scared to lose that they don't even try. Like,
              one thing people can't say is that I'm not trying, and I'm not
              trying my hardest, and I'm not trying to do the best way I
              know how.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card card-profile">
              <div
                class="card-header bg-info"
                style="background-image: url('assets/img/ill/inn.svg')"
              >
                <div class="card-avatar">
                  <a href="javascript:;">
                    <img
                      class="img img-raised rounded-circle"
                      src="assets/img/faces/team-2.jpg"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="d-flex justify-content-between">
                  <a
                    class="btn btn-sm btn-info mr-4 mt-3"
                    href="javascript:;"
                  >
                    Connect
                  </a>
                  <a
                    class="btn btn-sm btn-default float-right mt-3"
                    href="javascript:;"
                  >
                    Message
                  </a>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="card-profile-stats d-flex justify-content-center"
                    >
                      <div>
                        <span class="heading"> 22 </span>
                        <span class="description"> Friends </span>
                      </div>
                      <div>
                        <span class="heading"> 10 </span>
                        <span class="description"> Photos </span>
                      </div>
                      <div>
                        <span class="heading"> 89 </span>
                        <span class="description"> Comments </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <h5 class="h4">
                    Melanie Paisley<span class="font-weight-light">
                      , 31
                    </span>
                  </h5>
                  <div class="font-weight-300">
                    <i class="ni location_pin mr-2"> </i> Beijing, China
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-profile" data-background="cover">
              <img
                alt="cover"
                class="img pattern pattern-top"
                src="assets/img/ill/linth3.svg"
              />

              <div class="card-header">
                <div class="card-avatar">
                  <a href="javascript:;">
                    <img
                      class="img img-raised rounded-circle"
                      src="assets/img/faces/michael.jpg"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="text-center">
                  <h5 class="h5 mt-3">
                    Byron Reese<span class="font-weight-light">
                      , 29
                    </span>
                  </h5>
                  <div class="font-weight-300">
                    <i class="ni location_pin mr-2"> </i> California
                  </div>
                </div>
                <div
                  class="card-profile-stats d-flex justify-content-center"
                >
                  <div>
                    <span class="heading"> 23K </span>
                    <span class="description"> Followers </span>
                  </div>
                  <div>
                    <span class="heading"> 47 </span>
                    <span class="description"> Posts </span>
                  </div>
                </div>
                <button
                  class="btn btn-lg btn-success btn-block"
                  type="button"
                >
                  Like!
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-profile">
              <div
                class="card-header bg-info"
                style="background-image: url('assets/img/ill/inn.svg')"
              >
                <div class="card-avatar">
                  <a href="javascript:;">
                    <img
                      class="img img-raised rounded-circle"
                      src="assets/img/faces/team-3.jpg"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="d-flex justify-content-between">
                  <a
                    class="btn btn-sm btn-info mr-4 mt-3"
                    href="javascript:;"
                  >
                    Connect
                  </a>
                  <a
                    class="btn btn-sm btn-default float-right mt-3"
                    href="javascript:;"
                  >
                    Message
                  </a>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="card-profile-stats d-flex justify-content-center"
                    >
                      <div>
                        <span class="heading"> 22 </span>
                        <span class="description"> Friends </span>
                      </div>
                      <div>
                        <span class="heading"> 10 </span>
                        <span class="description"> Photos </span>
                      </div>
                      <div>
                        <span class="heading"> 89 </span>
                        <span class="description"> Comments </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <h5 class="h4">
                    Johana Doe<span class="font-weight-light"> , 29 </span>
                  </h5>
                  <div class="font-weight-300">
                    <i class="ni location_pin mr-2"> </i> California
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cd-section" id="projects">
    <div class="project-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mr-auto ml-auto text-center mb-5">
            <h3 class="diaplay-3">Some of Our Awesome Projects</h3>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg bg-transparent mb-5">
        <div class="container">
          <div class="navbar-translate"><p>52 projects found</p></div>
        </div>
      </nav>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header mt-2">
                <div class="float-left">
                  <i class="ni ni-basket d-inline text-info mr-1"> </i>
                  <p class="d-inline">H-23</p>
                </div>
                <div class="float-right">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link btn-sm"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Another action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Something else
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-4">
                <a href="javascript:;">
                  <img
                    alt="Circle image"
                    class="img-fluid rounded-circle shadow"
                    src="assets/img/slack.png"
                    style="width: 100px;"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">Slack</h4>
                <p class="card-description">
                  We are happy to work at such a great project.
                </p>
                <h5 class="card-title mt-4">Members</h5>
                <div class="avatar-group">
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Ryan Thomson"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-1.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"

                    tooltip="Romina Hadid"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-2.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Alexander Smith"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-3.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Jessica Doe"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img//faces/team-4.jpg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header mt-2">
                <div class="float-left">
                  <i class="ni ni-button-power d-inline text-success mr-1"> </i>
                  <p class="d-inline">F-43</p>
                </div>
                <div class="float-right">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link btn-sm"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Another action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Something else
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-4">
                <a href="javascript:;">
                  <img
                    alt="Circle image"
                    class="img-fluid rounded-circle shadow"
                    src="assets/img/spotify.jpeg"
                    style="width: 100px;"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">Spotify</h4>
                <p class="card-description">
                  We strive to embrace and drive change in our industry.
                </p>
                <h5 class="card-title mt-4">Members</h5>
                <div class="avatar-group">
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Ryan Thomson"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-1.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"

                    tooltip="Romina Hadid"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-2.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Alexander Smith"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-3.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Jessica Doe"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img//faces/team-4.jpg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header mt-2">
                <div class="float-left">
                  <i class="ni ni-building d-inline text-danger mr-1"> </i>
                  <p class="d-inline">J-11</p>
                </div>
                <div class="float-right">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link btn-sm"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Another action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Something else
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-4">
                <a href="javascript:;">
                  <img
                    alt="Circle image"
                    class="img-fluid rounded-circle shadow"
                    src="assets/img/dribbble.png"
                    style="width: 100px;"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">Dribbble</h4>
                <p class="card-description">
                  The time has come to bring our plans and ideas to life.
                </p>
                <h5 class="card-title mt-4">Members</h5>
                <div class="avatar-group">
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Ryan Thomson"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-1.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"

                    tooltip="Romina Hadid"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-2.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Alexander Smith"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-3.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Jessica Doe"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img//faces/team-4.jpg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header mt-2">
                <div class="float-left">
                  <i class="ni ni-spaceship d-inline text-warning mr-1"> </i>
                  <p class="d-inline">A-11</p>
                </div>
                <div class="float-right">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link btn-sm"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Another action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Something else
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-4">
                <a href="javascript:;">
                  <img
                    alt="Circle image"
                    class="img-fluid rounded-circle shadow"
                    src="assets/img/tim.png"
                    style="width: 100px;"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">CreativeTim</h4>
                <p class="card-description">
                  We are developing the best design projects.
                </p>
                <h5 class="card-title mt-4">Members</h5>
                <div class="avatar-group">
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Ryan Thomson"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-1.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"

                    tooltip="Romina Hadid"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-2.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Alexander Smith"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-3.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Jessica Doe"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img//faces/team-4.jpg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header mt-2">
                <div class="float-left">
                  <i class="ni ni-umbrella-13 d-inline text-primary mr-1"> </i>
                  <p class="d-inline">A-11</p>
                </div>
                <div class="float-right">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link btn-sm"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Another action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Something else
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-4">
                <a href="javascript:;">
                  <img
                    alt="Circle image"
                    class="img-fluid rounded-circle shadow"
                    src="assets/img/dropbox.png"
                    style="width: 100px;"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">DropBox</h4>
                <p class="card-description">
                  It is important to save every project safely with our app.
                </p>
                <h5 class="card-title mt-4">Members</h5>
                <div class="avatar-group">
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Ryan Thomson"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-1.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"

                    tooltip="Romina Hadid"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-2.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Alexander Smith"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/faces/team-3.jpg"
                    />
                  </a>
                  <a
                    class="avatar avatar-lg rounded-circle shadow"
                    href="javascript:;"
                    tooltip="Jessica Doe"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img//faces/team-4.jpg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header mt-2">
                <div class="float-left">
                  <i class="ni ni-bus-front-12 d-inline mr-1"> </i>
                  <p class="d-inline">E-28</p>
                </div>
                <div class="float-right">
                  <div class="dropdown" dropdown>
                    <button
                      aria-expanded="false"
                      class="btn btn-link btn-sm"
                      data-toggle="dropdown"
                      dropdownToggle
                      type="button"
                    >
                      <i class="ni ni-settings-gear-65"> </i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      *dropdownMenu
                      x-placement="bottom-end"
                    >
                      <a class="dropdown-item" href="javascript:;">
                        Action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Another action
                      </a>
                      <a class="dropdown-item" href="javascript:;">
                        Something else
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-4">
                <a href="javascript:;">
                  <img
                    alt="Circle image"
                    class="img-fluid rounded-circle shadow"
                    src="assets/img/unass.jpg"
                    style="width: 100px;"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">Unassigned</h4>
                <p class="card-description">
                  Here you can add your description and bellow add your
                  members.
                </p>
                <h5 class="card-title mt-4">Members</h5>
                <div class="avatar-group">
                  <a
                    class="avatar avatar-sm rounded-circle"
                    href="javascript:;"
                    tooltip="Add members"
                  >
                    <i class="ni ni-key-25"> </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="mt-5">
          <pagination
            class="float-left"
            [(ngModel)]="page"
            previousText="&lsaquo;" nextText="&rsaquo;"
            [totalItems]="50"
          >
          </pagination>
          <div class="text-right">
            <p>Showing 6 out of 36</p>
            <div></div>
          </div>
        </footer>
      </div>
    </div>
    <div class="project-2 my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3">Some of Our Awesome Products</h3>
            <p class="lead">
              The time is now for it to be okay to be great. People in this
              world shun people for being great.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-favourite-28"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Slack bot</h4>
                <p class="card-description">
                  If everything I did failed - which it doesn't, it actually
                  succeeds - just the fact that I'm willing to fail is an
                  inspiration. People are so scared to lose that they don't
                  even try.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-primary" type="button">
                    <i class="ni ni-glasses-2"> </i> Check more
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Looking great</h4>
                <p class="card-description">
                  You have the opportunity to play this game of life you
                  need to appreciate every moment. A lot of people don't
                  appreciate the moment until it's motivating the doers.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-success" type="button">
                    <i class="ni ni-key-25"> </i> Find a opportunity
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-trophy"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Developer First</h4>
                <p class="card-description">
                  For standing out. But the time is now to be okay to be the
                  greatest you. Would you believe in what you believe in, if
                  you were the only one who believed it?
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-warning" type="button">
                    <i class="ni ni-notification-70"> </i> Check more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-favourite-28"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Prepare launch</h4>
                <p class="card-description">
                  Society has put up so many boundaries, so many limitations
                  on what's right and wrong that it's almost impossible to
                  get a pure thought out. It's like a little kid, a little
                  boy.
                </p>
                <div class="card-footer">
                  <button
                    class="btn btn-link text-secondary"
                    type="button"
                  >
                    <i class="ni ni-glasses-2"> </i> Check out now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Premium support</h4>
                <p class="card-description">
                  Pink is obviously a better color. Everyone's born
                  confident, and everything's taken away from you matters is
                  the people who are sparked by it follow their dreams, too.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-danger" type="button">
                    <i class="ni ni-key-25"> </i> Find a opportunity
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-trophy"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Design tools</h4>
                <p class="card-description">
                  Constantly growing. We're constantly making mistakes.
                  We're constantly trying to express ourselves and actualize
                  our dreams the position that we want to be.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-info" type="button">
                    <i class="ni ni-notification-70"> </i> Check more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="projects-3">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mr-auto ml-auto text-center">
            <h6 class="category text-muted">Our work</h6>
            <h2 class="title mb-5">Some of Our Awesome Projects - 3</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div
              class="card card-background"
              style="background-image: url('assets/img/theme/masha-rostovskaya.jpg')"
            >
              <div class="card-body text-left">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i class="ni ni-atom text-warning"> </i>
                </div>
                <a href="javascript:;">
                  <h2 class="card-title">Project Alpha</h2>
                </a>
                <h6 class="desc text-white opacity-8">Java App</h6>
                <a class="btn btn-sm btn-warning" href="javascript:;">
                  Watch Later
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              class="card card-background"
              style="background-image: url('assets/img/theme/ali-pazani.jpg')"
            >
              <div class="card-body text-center">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i class="ni ni-controller text-danger"> </i>
                </div>
                <a href="javascript:;">
                  <h2 class="card-title">Project Beta</h2>
                </a>
                <h6 class="desc text-white opacity-8">College project</h6>
                <a class="btn btn-sm btn-danger" href="javascript:;">
                  Watch Later
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              class="card card-background"
              style="background-image: url('assets/img/theme/willy-dade.jpg')"
            >
              <div class="card-body text-right">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i class="ni ni-html5 text-success"> </i>
                </div>
                <a href="javascript:;">
                  <h2 class="card-title">Project Gama</h2>
                </a>
                <h6 class="desc text-white opacity-8">HTML code</h6>
                <a class="btn btn-sm btn-success" href="javascript:;">
                  Watch Later
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cd-section" id="pricing">
    <div class="pricing-1" id="pricing-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center mb-5">
            <h2 class="title">Pick the best plan for you</h2>
            <h4 class="description">
              You have Free Unlimited Updates and Premium Support on each
              package.
            </h4>
            <div class="section-space"></div>
          </div>
        </div>
        <div class="row">
          <div class="nav-wrapper ml-auto mr-3 mb-3">
            <tabset type="pills" class="nav-fill nav-pills-success no-tab-content">
              <tab>
                <ng-template tabHeading>
                      <i class="ni ni-satisfied mr-2"> </i> Monthly
                </ng-template>
                </tab>
              <tab>
                <ng-template tabHeading>
                  <i class="ni ni-collection mr-2"> </i> Yearly
                </ng-template>
              </tab>

            </tabset>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div
              class="card card-pricing bg-gradient-info border-0 text-center mb-4"
            >
              <div class="card-header bg-transparent">
                <h6 class="text-uppercase ls-1 text-white py-3 mb-0">
                  Starter
                </h6>
              </div>
              <div class="card-body">
                <div class="display-2 text-white">$22</div>
                <span class="text-white"> per month </span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-info"
                        >
                          <i class="ni ni-book-bookmark"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Complete documentation
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-info"
                        >
                          <i class="ni ni-diamond"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Working materials in Sketch
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-info"
                        >
                          <i class="ni ni-chart-pie-35"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          2GB cloud storage
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn btn-link text-white mb-3" type="button">
                  Get Started
                </button>
              </div>
              <div class="card-footer bg-transparent">
                <a class="text-white" href="#!"> Request a demo </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="card card-pricing bg-gradient-warning border-0 text-center mb-4"
            >
              <div class="card-header bg-transparent">
                <h6 class="text-uppercase ls-1 text-white py-3 mb-0">
                  PRO
                </h6>
              </div>
              <div class="card-body">
                <div class="display-2 text-white">$45</div>
                <span class="text-white"> per month </span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-warning"
                        >
                          <i class="ni ni-book-bookmark"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Complete documentation
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-warning"
                        >
                          <i class="ni ni-diamond"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Working materials in Sketch
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-warning"
                        >
                          <i class="ni ni-chart-pie-35"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          200GB cloud storage
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn btn-link text-white mb-3" type="button">
                  Get Started
                </button>
              </div>
              <div class="card-footer bg-transparent">
                <a class="text-white" href="#!"> Request a demo </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="card card-pricing bg-gradient-success border-0 text-center mb-4"
            >
              <div class="card-header bg-transparent">
                <h6 class="text-uppercase ls-1 text-white py-3 mb-0">
                  Enterprise
                </h6>
              </div>
              <div class="card-body">
                <div class="display-2 text-white">$68</div>
                <span class="text-white"> per month </span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-success"
                        >
                          <i class="ni ni-book-bookmark"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Complete documentation
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-success"
                        >
                          <i class="ni ni-diamond"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Working materials in Sketch
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape bg-white shadow rounded-circle text-success"
                        >
                          <i class="ni ni-chart-pie-35"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm text-white">
                          Unlimited cloud storage
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn btn-link text-white mb-3" type="button">
                  Get Started
                </button>
              </div>
              <div class="card-footer bg-transparent">
                <a class="text-white" href="#!"> Request a demo </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-2" id="pricing-2">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center mb-3">
            <h3 class="display-3">Pick the best plan for you</h3>
            <p class="lead">
              You have Free Unlimited Updates and Premium Support on each
              package.
            </p>
            <div class="section-space"></div>
          </div>
        </div>
        <div class="space-100"></div>
        <div class="row">
          <div class="nav-wrapper mx-auto mr-3 mb-3">
            <tabset type="pills" class="nav-fill nav-pills-info no-tab-content">
              <tab>
                <ng-template tabHeading>
                      <i class="ni ni-satisfied mr-2"> </i> Monthly
                </ng-template>
                </tab>
              <tab>
                <ng-template tabHeading>
                  <i class="ni ni-collection mr-2"> </i> Yearly
                </ng-template>
              </tab>

            </tabset>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div
              class="card card-pricing bg-info card-background"
              data-background="image"
              style="background-image: url('assets/img/ill/pricing_bg.svg')"
            >
              <div class="card-body">
                <h6 class="card-category text-white text-uppercase">
                  Starter
                </h6>
                <h1 class="card-title">
                  <small class="text-white"> $ </small> 199
                </h1>
                <ul>
                  <li><b> 10 </b> Projects</li>
                  <li><b> 1 </b> Team Members</li>
                  <li><b> 5 </b> Personal Contacts</li>
                  <li><b> 500 </b> Messages</li>
                </ul>
                <a class="btn btn-white btn-sm mt-3" href="javascript:;">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="card card-pricing bg-warning card-background"
              data-background="image"
              style="background-image: url('assets/img/ill/pricing_bg.svg')"
            >
              <div class="card-body">
                <h6 class="card-category text-white text-uppercase">
                  PRO
                </h6>
                <h1 class="card-title">
                  <small class="text-white"> $ </small> 399
                </h1>
                <ul>
                  <li><b> 100 </b> Projects</li>
                  <li><b> 10 </b> Team Members</li>
                  <li><b> 50 </b> Personal Contacts</li>
                  <li><b> 500 </b> Messages</li>
                </ul>
                <a class="btn btn-white btn-sm mt-3" href="javascript:;">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="card card-pricing bg-success card-background"
              data-background="image"
              style="background-image: url('assets/img/ill/pricing_bg.svg')"
            >
              <div class="card-body">
                <h6 class="card-category text-white text-uppercase">
                  Enterprise
                </h6>
                <h1 class="card-title">
                  <small class="text-white"> $ </small> 599
                </h1>
                <ul>
                  <li><b> 1K </b> Projects</li>
                  <li><b> 100 </b> Team Members</li>
                  <li><b> 500 </b> Personal Contacts</li>
                  <li><b> Unlimited </b> Messages</li>
                </ul>
                <a class="btn btn-white btn-sm mt-3" href="javascript:;">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section
      class="pricing-3"
      id="pricing-4"
      style="background-image: url('assets/img/ill/1.svg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3">Some of Our Pricing Plans</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pr-md-0">
            <div class="card card-pricing text-center bg-default">
              <div class="card-header bg-transparent">
                <h4 class="text-uppercase ls-1 text-primary py-3 mb-0">
                  Bravo pack
                </h4>
              </div>
              <div class="card-body px-lg-6">
                <div class="display-2 text-white">$49</div>
                <span class="text-muted"> per application </span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-folder-17 text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Complete documentation
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-camera-compact text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Working materials in Sketch
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-chart-pie-35 text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          2GB cloud storage
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn btn-primary mb-3" type="button">
                  Start free trial
                </button>
              </div>
              <div class="card-footer bg-transparent">
                <a class="text-muted" href="javascript:;">
                  Request a demo
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-7 pl-md-0">
            <div class="card card-pricing border-0 text-center my-5">
              <div class="card-header bg-transparent">
                <h4 class="text-uppercase ls-1 text-primary py-3 mb-0">
                  Enterprise
                </h4>
              </div>
              <div class="card-body px-lg-6">
                <div class="card-description">
                  What colors are good, before somebody tells you you
                  shouldn't like pink because that's for girls, or you'd
                  instantly become a gay two-year-old.
                </div>
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <td>24/7 Support</td>
                      <td>Design Tools</td>
                    </tr>
                    <tr>
                      <td>10K emails</td>
                      <td>Private Brand</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="javascript:;">
                  Create new account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="pricing-4" id="pricing-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">Pick the best plan for you</h2>
            <h4 class="description">
              You have Free Unlimited Updates and Premium Support on each
              package.
            </h4>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4">
            <div
              class="card card-pricing card-coin"
            >
              <div class="card-header">
                <img
                  class="img-center shadow"
                  src="assets/img/theme/vue.jpg"
                />
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h5 class="text-uppercase">Standard Pack</h5>
                    <span> £ 39 </span>
                    <hr class="bg-info" />
                  </div>
                </div>
                <div class="row">
                  <ul class="list-group">
                    <li class="list-group-item">50 messages</li>
                    <li class="list-group-item">100 emails</li>
                    <li class="list-group-item">No Support</li>
                  </ul>
                </div>
              </div>
              <div class="card-footer text-center bg-transparent">
                <button class="btn btn-primary mb-3">Get plan</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="card card-pricing card-coin"
            >
              <div class="card-header">
                <img
                  class="img-center shadow"
                  src="assets/img/theme/react.jpg"
                />
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h5 class="text-uppercase">Premium Pack</h5>
                    <span> £ 49 </span>
                    <hr class="bg-info" />
                  </div>
                </div>
                <div class="row">
                  <ul class="list-group">
                    <li class="list-group-item">100 messages</li>
                    <li class="list-group-item">1K emails</li>
                    <li class="list-group-item">Premium Support</li>
                  </ul>
                </div>
              </div>
              <div class="card-footer text-center bg-transparent">
                <button class="btn btn-primary mb-3">Get plan</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="card card-pricing card-coin"
            >
              <div class="card-header">
                <img
                  class="img-center shadow"
                  src="assets/img/theme/sketch.jpg"
                />
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h5 class="text-uppercase">Platinum Pack</h5>
                    <span> £ 69 </span>
                    <hr class="bg-info" />
                  </div>
                </div>
                <div class="row">
                  <ul class="list-group">
                    <li class="list-group-item">350 messages</li>
                    <li class="list-group-item">10K emails</li>
                    <li class="list-group-item">24/7 Support</li>
                  </ul>
                </div>
              </div>
              <div class="card-footer text-center bg-transparent">
                <button class="btn btn-primary mb-3">Get plan</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section
      class="pricing-5"
      id="pricing-6"
      style="background-image: url('assets/img/ill/bg_pricing5.svg');"
    >
      <div class="container pt-5">
        <div class="row">
          <div class="col-md-4 d-flex justify-content-center flex-column">
            <h3 class="display-3 mt-3">
              Choose a plan for your next project
            </h3>
            <p class="lead mt-0">
              You have Free Unlimited Updates and Premium Support on each
              package. You also have 20 days to request a refund.
            </p>
          </div>
          <div class="col-lg-7 col-md-8 ml-auto mr-auto">
            <tabset type="pills" class="nav-fill nav-pills-primary">
              <tab heading="Cheaper">
                <div class="row">
                  <div class="col-md-6">
                    <div
                      class="card card-pricing bg-white border-0 text-center mb-4"
                    >
                      <div class="card-header bg-transparent">
                        <h6 class="text-uppercase ls-1 py-3 mb-0">Gold</h6>
                      </div>
                      <div class="card-body">
                        <div class="display-2">$100</div>
                        <span> per month </span>
                        <ul class="list-unstyled my-4">
                          <li class="align-items-center">
                            <b class="text-primary"> 200GB </b>
                            <span> File Storage </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> Unlimited </b>
                            <span> Users </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> Premium </b>
                            <span> Support </span>
                          </li>
                        </ul>
                      </div>
                      <div class="card-footer bg-transparent">
                        <a href="#!"> Request a demo </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="card card-pricing bg-white border-0 text-center mb-4"
                    >
                      <div class="card-header bg-transparent">
                        <h6 class="text-uppercase ls-1 py-3 mb-0">
                          Platinum
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="display-2">$135</div>
                        <span> per month </span>
                        <ul class="list-unstyled my-4">
                          <li class="align-items-center">
                            <b class="text-primary"> 500GB </b>
                            <span> File Storage </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> Unlimited </b>
                            <span> Users </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> No time </b>
                            <span> Tracking </span>
                          </li>
                        </ul>
                      </div>
                      <div class="card-footer bg-transparent">
                        <a href="#!"> Request a demo </a>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
              <tab heading="Expensive">
                <div class="row">
                  <div class="col-md-6">
                    <div
                      class="card card-pricing bg-white border-0 text-center mb-4"
                    >
                      <div class="card-header bg-transparent">
                        <h6 class="text-uppercase ls-1 py-3 mb-0">
                          Standard
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="display-2">$25</div>
                        <span> per month </span>
                        <ul class="list-unstyled my-4">
                          <li class="align-items-center">
                            <b class="text-primary"> 20GB </b>
                            <span> File Storage </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> 15 </b>
                            <span> Users </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> false </b>
                            <span> Support </span>
                          </li>
                        </ul>
                      </div>
                      <div class="card-footer bg-transparent">
                        <a href="#!"> Request a demo </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="card card-pricing bg-white border-0 text-center mb-4"
                    >
                      <div class="card-header bg-transparent">
                        <h6 class="text-uppercase ls-1 py-3 mb-0">
                          Premium
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="display-2">$59</div>
                        <span> per month </span>
                        <ul class="list-unstyled my-4">
                          <li class="align-items-center">
                            <b class="text-primary"> 50GB </b>
                            <span> File Storage </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> 100 </b>
                            <span> Users </span>
                          </li>
                          <li class="align-items-center">
                            <b class="text-primary"> Premium </b>
                            <span> Support </span>
                          </li>
                        </ul>
                      </div>
                      <div class="card-footer bg-transparent">
                        <a href="#!"> Request a demo </a>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="cd-section" id="testimonials">
    <div
      class="testimonials-1"
      style="background-image: url('assets/img/ill/testimonial_bg.svg');"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">What Clients Say</h2>
          </div>
        </div>
        <carousel [showIndicators]="false" class="carousel-team">
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 ml-auto">
                  <h3 class="card-title">Sarah Smith</h3>
                  <h3 class="text-danger">• • •</h3>
                  <h4 class="lead">
                    Take up one idea. Make that one idea your life - think
                    of it, dream of it, live on that idea. Let the brain,
                    muscles, nerves, every part of your body, be full of
                    that idea, and just leave every other idea alone. This
                    is the way to success. A single rose can be my garden...
                    a single friend, my world.
                  </h4>
                  <a class="btn btn-danger mt-3" href="javascript:void(0)">
                    Read more
                  </a>
                </div>
                <div class="col-md-5 p-5 ml-auto">
                  <div class="p-3">
                    <img
                      alt="First slide"
                      class="img-fluid rounded shadow transform-perspective-right"
                      src="assets/img/faces/fezbot.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 ml-auto">
                  <h3 class="card-title">Isaac Hunter</h3>
                  <h3 class="text-info">• • •</h3>
                  <h4 class="lead">
                    Take up one idea. Make that one idea your life - think
                    of it, dream of it, live on that idea. Let the brain,
                    muscles, nerves, every part of your body, be full of
                    that idea, and just leave every other idea alone. This
                    is the way to success. A single rose can be my garden...
                    a single friend, my world.
                  </h4>
                  <a class="btn btn-info mt-3" href="javascript:void(0)">
                    Read more
                  </a>
                </div>
                <div class="col-md-5 p-5 ml-auto">
                  <div class="p-3">
                    <img
                      alt="First slide"
                      class="img-fluid rounded shadow transform-perspective-right"
                      src="assets/img/faces/brooke-cagle.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <div class="testimonials-2">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">What Clients Say</h2>
          </div>
        </div>
        <div class="row">
          <carousel [showIndicators]="false" class="carousel carousel-fade carousel-testimonials" id="carouselFade">
            <slide>
              <div class="info text-left bg-danger shadow-lg">
                <div
                  class="icon icon-shape bg-white text-danger shadow rounded-circle mb-4"
                >
                  <i class="fa fa-quote-right"> </i>
                </div>
                <p class="description text-white">
                  It really matters and then like it really doesn't matter.
                  What matters is the people who are sparked by it. And the
                  people who are like offended by it, it doesn't matter.
                  Because it's about motivating the doers. Because I'm here
                  to follow my dreams and inspire other people to follow
                  their dreams.
                </p>
                <div class="author">
                  <img
                    alt="..."
                    class="avatar img-raised"
                    src="assets/img/faces/team-5.jpg"
                  />

                  <span> Mike John </span>
                </div>
              </div>
              <div
                class="card card-blog card-background"
                data-animation="true"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/austin.jpg')"
                ></div>
                <div class="card-body">
                  <div class="content-bottom ml-auto">
                    <a
                      class="btn btn-round btn-simple btn-neutral"
                      href="javascript:void(0)"
                    >
                      <i class="ni ni-book-bookmark"> </i> Read more
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="info text-left bg-info shadow-lg">
                <div
                  class="icon icon-shape bg-white text-info shadow rounded-circle mb-4"
                >
                  <i class="fa fa-quote-right"> </i>
                </div>
                <p class="description text-white">
                  Take up one idea. Make that one idea your life - think of
                  it, dream of it, live on that idea. Let the brain,
                  muscles, nerves, every part of your body, be full of that
                  idea, and just leave every other idea alone. This is the
                  way to success. A single rose can be my garden... a single
                  friend, my world.
                </p>
                <div class="author">
                  <img
                    alt="..."
                    class="avatar img-raised"
                    src="assets/img/faces/team-3.jpg"
                  />

                  <span> Andrew Lino </span>
                </div>
              </div>
              <div
                class="card card-blog card-background"
                data-animation="true"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/unsplashs.jpg')"
                ></div>
                <div class="card-body">
                  <div class="content-bottom ml-auto">
                    <a
                      class="btn btn-round btn-simple btn-neutral"
                      href="javascript:void(0)"
                    >
                      <i class="ni ni-book-bookmark"> </i> Read more
                    </a>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
    <div class="testimonials-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h3 class="display-3">Join our world</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-8 col-10 positioned">
            <h3 class="display-3">See our customers opinion</h3>
            <p class="lead">
              Meet Wingman, a robust suite of styled pages and components,
              powered by Bootstrap 4. The ideal starting point for product
              landing pages, stylish web-apps and complete company websites.
            </p>
            <a class="btn btn-primary mt-5" href="javascript:;">
              Contact us
            </a>
          </div>
          <div class="col-md-12 col-md-6">
            <div class="testimonial-glide">
              <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "Take up one idea. Make that one idea your life -
                        think of it, dream of it, live on that idea. Let the
                        brain, muscles, nerves, every part of your body, be
                        full of that idea, and just leave every other idea
                        alone. This is the way to success. A single rose can
                        be my garden... a single friend, my world."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-1.jpg"
                        />

                        <span> Andrew Lino </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        Artist is a term applied to a person who engages in
                        an activity deemed to be an art. An artist also may
                        be defined unofficially as "a person who expresses
                        him- or herself through a medium". He is a
                        descriptive term applied to a person who engages in
                        an activity deemed to be an art."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-2.jpg"
                        />

                        <span> Michael Jenos </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "The simplest visual description uses ordinary words
                        to convey what the writer sees. First he or she must
                        look at the subject â slowly, carefully, and
                        repeatedly, if possible â to identify the parts that
                        make the whole"
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-3.jpg"
                        />

                        <span> Rose Arthur </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "Finding temporary housing for your dog should be as
                        easy as renting an Airbnb. That's the idea behind
                        Rover, which raised $65 million to expand its pet
                        sitting and dog-walking businesses..Finding
                        temporary housing for your dog should be as easy as
                        renting an Airbnb."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-4.jpg"
                        />

                        <span> Lora Jimi </span>
                      </div>
                    </div>
                  </li>
                  <li class="glide__slide">
                    <div class="info text-left">
                      <p class="description">
                        "Venture investment in U.S. startups rose
                        sequentially in the second quarter of 2017, boosted
                        by large, late-stage financings and a few outsized
                        early-stage rounds in tech and healthcare..enture
                        investment in U.S. startups rose sequentially in the
                        second quarter of 2017, boosted by large."
                      </p>
                      <div class="author">
                        <img
                          alt="..."
                          class="avatar img-raised"
                          src="assets/img/faces/team-5.jpg"
                        />

                        <span> Mike Ranson </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="glide__arrows" data-glide-el="controls">
                <button
                  class="glide__arrow glide__arrow--left text-default"
                  data-glide-dir="<"
                  aria-label="Previous"
                >
                  <i class="ni ni-bold-left"> </i>
                </button>
                <button
                  class="glide__arrow glide__arrow--right text-default"
                  data-glide-dir=">"
                  aria-label="Next"
                >
                  <i class="ni ni-bold-right"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cd-section" id="contact-us">
    <div
      class="contactus-1 bg-default"
      style="background-image: url('assets/img/ill/1.svg')"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-lg-5 col-md-5 d-flex justify-content-center flex-column"
          >
            <h2 class="title text-white">Get in Touch</h2>
            <h4 class="description text-white">
              You need more information? Check what other persons are saying
              about our product. They are very happy with their purchase.
            </h4>
            <div class="info info-horizontal">
              <div
                class="icon icon-shape icon-shape-primary shadow rounded-circle text-white"
              >
                <i class="ni ni-square-pin"> </i>
              </div>
              <div class="description">
                <h4 class="info-title text-white">
                  Find us at the office
                </h4>
                <p class="description ml-3 text-white">
                  Bld Mihail Kogalniceanu, nr. 8,<br />

                  7652 Bucharest,<br />

                  Romania
                </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div
                class="icon icon-shape icon-shape-primary shadow rounded-circle text-white"
              >
                <i class="ni ni-mobile-button"> </i>
              </div>
              <div class="description">
                <h4 class="info-title text-white">Give us a ring</h4>
                <p class="description ml-3 text-white">
                  Michael Jordan<br />

                  +40 762 321 762<br />

                  Mon - Fri, 8:00-22:00
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-7 ml-auto mr-auto">
            <div class="card card-contact card-raised">
              <form id="contact-form" method="post" role="form">
                <div class="card-header text-center">
                  <h4 class="card-title">Contact Us</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group" [ngClass]="{ 'focused': focus === true }">
                        <label> First name </label>
                        <div class="input-group mb-4">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="ni ni-circle-08"> </i>
                            </span>
                          </div>
                          <input
                            aria-label="First Name..."
                            class="form-control"
                            placeholder="First Name..."
                            type="text" (blur)="focus = false" (focus)="focus = true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-2">
                      <div class="form-group" [ngClass]="{ 'focused': focus1 === true }">
                        <label> Last name </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="ni ni-collection"> </i>
                            </span>
                          </div>
                          <input
                            aria-label="Last Name..."
                            class="form-control"
                            placeholder="Last Name..."
                            type="text" (blur)="focus1 = false" (focus)="focus1 = true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'focused': focus2 === true }">
                    <label> Email address </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-email-83"> </i>
                        </span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="Email Here..."
                        type="text" (blur)="focus2 = false" (focus)="focus2 = true"
                      />
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'focused': focus3 === true }">
                    <label> Your message </label>
                    <textarea
                      class="form-control"
                      id="message"
                      name="message" (blur)="focus3 = false" (focus)="focus3 = true"
                      rows="6"
                    ></textarea>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="custom-control custom-checkbox mt-2">
                        <input
                          class="custom-control-input"
                          id="customCheck"
                          type="checkbox"
                        />

                        <label
                          class="custom-control-label"
                          for="customCheck"
                        >
                          <span> I'm not a robot </span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <button
                        class="btn btn-primary pull-right"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactus-2">
      <div
        class="map"
        id="map-contactus-1"
        style="width: 100%; height: 704px; display: block; margin-top: -80px;"
      ></div>
      <div class="col-lg-6 col-md-10">
        <div class="card card-contact card-raised">
          <form id="contact-form" method="post" role="form">
            <div class="card-header text-center">
              <h4 class="card-title">Contact Us</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="info info-horizontal">
                    <div
                      class="icon icon-shape icon-shape-primary shadow rounded-circle text-primary"
                    >
                      <i class="ni ni-mobile-button"> </i>
                    </div>
                    <div class="description">
                      <h5 class="info-title mt-2">Give us a ring</h5>
                      <p class="ml-3">
                        Michael Jordan<br />

                        +40 762 321 762<br />

                        Mon - Fri, 8:00-22:00
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info info-horizontal">
                    <div
                      class="icon icon-shape icon-shape-primary shadow rounded-circle text-primary"
                    >
                      <i class="ni ni-square-pin"> </i>
                    </div>
                    <div class="description">
                      <h5 class="info-title mt-2">Find us at:</h5>
                      <p class="ml-3">
                        Mihail Kogalnic 8,<br />

                        7652 Bucharest,<br />

                        Romania
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': focus4 === true }">
                    <label> First name </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-circle-08"> </i>
                        </span>
                      </div>
                      <input
                        aria-label="First Name..."
                        class="form-control"
                        placeholder="First Name..." (blur)="focus4 = false" (focus)="focus4 = true"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': focus5 === true }">
                    <label> Email address </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-email-83"> </i>
                        </span>
                      </div>
                      <input
                        autocomplete="email"
                        class="form-control"
                        placeholder="Email Here..."
                        type="email" (blur)="focus5 = false" (focus)="focus5 = true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="custom-control custom-checkbox mt-2">
                    <input
                      class="custom-control-input"
                      id="customCheck1"
                      type="checkbox"
                    />

                    <label class="custom-control-label" for="customCheck1">
                      <span> I'm not a robot </span>
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <button class="btn btn-primary pull-right" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="contactus-3">
      <div class="page-header">
        <img
          alt=""
          class="bg-image"
          src="assets/img/ill/bg_contactus3.svg"
        />
      </div>
      <div class="container pt-5">
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <h1 class="display-1">Got a question?</h1>
            <h3 class="lead">
              We'd like to talk more about what you need
            </h3>
            <button class="btn btn-icon btn-primary mt-3" type="button">
              <span class="btn-inner--icon">
                <i class="ni ni-chat-round"> </i>
              </span>
              <span class="btn-inner--text"> Chat with us </span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-square-pin"> </i>
              </div>
              <h4 class="info-title">Address</h4>
              <p class="description px-0">12124 First Street, nr 54</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-email-83"> </i>
              </div>
              <h4 class="info-title">Email</h4>
              <p class="description px-0">hello@email.com</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-mobile-button"> </i>
              </div>
              <h4 class="info-title">Phone</h4>
              <p class="description px-0">+1(424) 535 3523</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-circle-08"> </i>
              </div>
              <h4 class="info-title">Contact</h4>
              <p class="description px-0">Andrew Samian</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactus-4">
      <div class="map" id="map-contactus-2"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <h1 class="title text-white">Get in Touch</h1>
            <h4 class="description text-white">
              Do you need more information? Please contact us to find more
              about our products and services.
            </h4>
          </div>
          <div class="col-md-12 m-auto">
            <div class="card card-contact card-raised">
              <div class="row">
                <div class="col-lg-8 col-md-7 pr-md-0">
                  <form
                    class="p-3"
                    id="contact-form"
                    method="post"
                    role="form"
                  >
                    <div class="card-header">
                      <h4 class="card-title">Send us a message</h4>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group" [ngClass]="{ 'focused': focus6 === true }">
                            <label> First name </label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="ni ni-circle-08"> </i>
                                </span>
                              </div>
                              <input
                                aria-label="First Name..."
                                class="form-control"
                                placeholder="First Name..."
                                type="text" (blur)="focus6 = false" (focus)="focus6 = true"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" [ngClass]="{ 'focused': focus7 === true }">
                            <label> Last name </label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="ni ni-collection"> </i>
                                </span>
                              </div>
                              <input
                                aria-label="Last Name..."
                                class="form-control" (blur)="focus7 = false" (focus)="focus7 = true"
                                placeholder="Last Name..."
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" [ngClass]="{ 'focused': focus8 === true }">
                        <label> Email address </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="ni ni-email-83"> </i>
                            </span>
                          </div>
                          <input
                            class="form-control"
                            placeholder="Email Here..." (blur)="focus8 = false" (focus)="focus8 = true"
                            type="text"
                          />
                        </div>
                      </div>
                      <div class="form-group" [ngClass]="{ 'focused': focus9 === true }">
                        <label> Your message </label>
                        <textarea
                          class="form-control"
                          id="message"
                          name="message" (blur)="focus9 = false" (focus)="focus9 = true"
                          rows="6"
                        ></textarea>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mb-3">
                            <input
                              class="custom-control-input"
                              id="customCheck2"
                              type="checkbox"
                            />

                            <label
                              class="custom-control-label"
                              for="customCheck2"
                            >
                              <span> I'm not a robot </span>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <button
                            class="btn btn-info pull-right"
                            type="submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-4 col-md-5 pl-md-0">
                  <div class="info text-left bg-info">
                    <h4 class="card-title text-white">
                      Contact information
                    </h4>
                    <div class="info info-horizontal mt-lg-5">
                      <div
                        class="icon icon-shape bg-white rounded-circle text-info"
                      >
                        <i class="ni ni-square-pin"> </i>
                      </div>
                      <div class="description">
                        <p class="info-title text-white mt-2">
                          345 Street 2, Bucharest
                        </p>
                      </div>
                    </div>
                    <div class="info info-horizontal">
                      <div
                        class="icon icon-shape bg-white rounded-circle text-info"
                      >
                        <i class="ni ni-mobile-button"> </i>
                      </div>
                      <div class="description">
                        <p class="info-title text-white mt-2">
                          +16(3412) 421 241
                        </p>
                      </div>
                    </div>
                    <div class="info info-horizontal">
                      <div
                        class="icon icon-shape bg-white rounded-circle text-info"
                      >
                        <i class="ni ni-email-83"> </i>
                      </div>
                      <div class="description">
                        <p class="info-title text-white mt-2">
                          contact@yoursite.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cd-section" id="tables">
    <div class="tables-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title mb-4">Find out more details</h2>
            <div class="section-space"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div>
              <div class="card card-plain">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-pricing">
                      <thead class="text-primary">
                        <tr>
                          <th><h5 class="mb-0">Space individual</h5></th>
                          <th class="text-center">
                            <p>Team</p>
                            <h4 class="desc">$39</h4>
                            <a
                              class="btn btn-outline-success btn-sm"
                              href="javascript:void(0)"
                            >
                              Choose Plan
                            </a>
                          </th>
                          <th class="text-center">
                            <p>Growth</p>
                            <h4 class="desc">$79</h4>
                            <a
                              class="btn btn-outline-success btn-sm"
                              href="javascript:void(0)"
                            >
                              Choose Plan
                            </a>
                          </th>
                          <th class="text-center">
                            <p>Enterprise</p>
                            <h4 class="desc">$99</h4>
                            <a
                              class="btn btn-success btn-sm"
                              href="javascript:void(0)"
                            >
                              Choose Plan
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Community support</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>100+ Example Pages</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>50+ Section Examples</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Multiple use</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-danger">
                              <i class="fas fa-times text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">upon request</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Custom messages</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-success">
                              <i class="ni ni-check-bold text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-danger">
                              <i class="fas fa-times text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-danger">
                              <i class="fas fa-times text-white"> </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Multiple requests</td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-danger">
                              <i class="fas fa-times text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-danger">
                              <i class="fas fa-times text-white"> </i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="badge badge-circle badge-danger">
                              <i class="fas fa-times text-white"> </i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tables-2 mb-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h2 class="title mb-5">The best solution you can choose</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div>
              <div class="table-responsive mb-0">
                <table class="table table-pricing">
                  <thead class="text-primary">
                    <tr>
                      <th>
                        <img
                          class="background"
                          src="assets/img/ill/bg4-1.svg"
                        />
                      </th>
                      <th class="text-center">
                        <h5 class="card-title">Essential</h5>
                        <a
                          class="btn btn-info btn-sm"
                          href="javascript:void(0)"
                        >
                          Get started
                        </a>
                        <a href="javascript:void(0)"> Learn more </a>
                      </th>
                      <th class="text-center">
                        <h5 class="card-title">Premium</h5>
                        <a
                          class="btn btn-info btn-sm"
                          href="javascript:void(0)"
                        >
                          Get started
                        </a>
                        <a href="javascript:void(0)"> Learn more </a>
                      </th>
                      <th class="text-center">
                        <h5 class="card-title">Enterprise</h5>
                        <a
                          class="btn btn-info btn-sm"
                          href="javascript:void(0)"
                        >
                          Get started
                        </a>
                        <a href="javascript:void(0)"> Learn more </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-primary">
                      <td class="text-white"><b> Pricing </b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        Plan cost<i class="ni ni-compass-04 ml-1"> </i>
                      </td>
                      <td class="text-center">Free</td>
                      <td class="text-center">
                        <a href="javascript:void(0)"> Contact us </a>
                      </td>
                      <td class="text-center">
                        <a href="javascript:void(0)"> Contact us </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Platform fee<i class="ni ni-compass-04 ml-1"> </i>
                      </td>
                      <td class="text-center">3%</td>
                      <td class="text-center">5%</td>
                      <td class="text-center">
                        <a href="javascript:void(0)"> Contact us </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Payment processing</td>
                      <td class="text-center">5.5% + 5c</td>
                      <td class="text-center">6.2% + 5c</td>
                      <td class="text-center">
                        <a href="javascript:void(0)"> Contact us </a>
                      </td>
                    </tr>
                    <tr class="bg-primary">
                      <td class="text-white"><b> Features </b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Custom messages</td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Multiple requests</td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Team fundraising</td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-success">
                          <i class="ni ni-check-bold text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-success">
                          <i class="ni ni-check-bold text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-success">
                          <i class="ni ni-check-bold text-white"> </i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Basic donor data</td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-success">
                          <i class="ni ni-check-bold text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="badge badge-circle badge-danger">
                          <i class="fas fa-times text-white"> </i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cd-section" id="accordion">
    <div class="accordion-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h2 class="title mb-3 mt-5">Frequently asked question</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseOne"
                      class="btn btn-link w-100 text-primary text-left"
                      [attr.aria-expanded]="!isCollapsed"
                      (click)="isCollapsed = !isCollapsed"
                      id="collapseOne"
                      type="button"
                    >
                      How do I order?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingOne"
                  class="show"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed"
                  id="collapseOne"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseTwo"
                      class="btn btn-link w-100 text-primary text-left collapsed"
                      [attr.aria-expanded]="!isCollapsed1"
                      (click)="isCollapsed1 = !isCollapsed1"
                      id="collapseTwo"
                      type="button"
                    >
                      How can i make the payment?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed1"
                  id="collapseTwo"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseThree"
                      class="btn btn-link w-100 text-primary text-left collapsed"
                      [attr.aria-expanded]="!isCollapsed2"
                      (click)="isCollapsed2 = !isCollapsed2"
                      id="collapseThree"
                      type="button"
                    >
                      How much time does it take to receive the order?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed2"
                  id="collapseThree"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseFour"
                      class="btn btn-link w-100 text-primary text-left"
                      [attr.aria-expanded]="!isCollapsed3"
                      (click)="isCollapsed3 = !isCollapsed3"
                      id="collapseFour"
                      type="button"
                    >
                      Can I resell the products?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed3"
                  id="collapseFour"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFifth">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseFifth"
                      class="btn btn-link w-100 text-primary text-left"
                      [attr.aria-expanded]="!isCollapsed4"
                      (click)="isCollapsed4 = !isCollapsed4"
                      id="collapseFifth"
                      type="button"
                    >
                      Where do I find the shipping details?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingFifth"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed4"
                  id="collapseFifth"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
