
<div class="wrapper">
  <div class="section-shaped my-0 skew-separator skew-mini">
    <div class="page-header page-header-mini header-filter">
      <div
        class="page-header-image"
        style="background-image: url('assets/img/pages/legal-header.jpg');"
      ></div>
      <div class="container">
        <div class="header-body text-center mb-7">
          <!-- <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Legal</h1>
              <p class="text-lead text-white">
                This is all the fine print.
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <section class="upper">
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <div class="card card-invoice">
            <div class="card-header text-center">
              <div class="row justify-content-between">
                <div class="col-md-4 text-left">
                  <img
                    alt="Logo"
                    class="mb-2 w-100"
                    src="assets/img/brand/blue.png"
                  />
                </div>
                <div class="col-lg-4 col-md-6 text-left mt-4">
                  <!-- <h4 class="mb-1">Billed to:</h4>
                  <span class="d-block"> Awesome User </span>
                  <p>
                    Insurance Rd,<br />

                    Anytown,<br />

                    Texas
                  </p> -->
                  <h6>
                    101 W Broadway, Suite 300<br />San Diego CA, 92101
                  </h6>
                  <small class="d-block text-muted">
                    tel: (833) 411-7768
                  </small>
                </div>
              </div>
              <br />

              <!-- <div class="row justify-content-md-between">
                <div class="col-md-4">
                  <h3 class="mt-3 text-left">
                    Invoice no <br />

                    <small class="mr-2"> #0453119 </small>
                  </h3>
                </div>
                <div class="col-lg-4 col-md-5">
                  <div class="row mt-5">
                    <div class="col-md-6">Invoice date:</div>
                    <div class="col-md-6">06/03/2019</div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">Due date:</div>
                    <div class="col-md-6">11/03/2019</div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div>
                    <!-- <h3> Derek Policy</h3>
                    <p> This is the fine print</p> -->
                    <!-- Termly Tracking Code -->
                  <iframe src="https://app.termly.io/document/terms-of-use-for-saas/9c6022a1-7dfe-4982-a7f9-fb70690a7c0b" width="100%" height="500px">
                      <p>Your browser does not support iframes.</p>
                    </iframe>
                  </div>
                  <!-- Termly Tracking Code -->
                  <!-- <div name="termly-embed" data-id="9c6022a1-7dfe-4982-a7f9-fb70690a7c0b" data-type="iframe"></div>
                  <script type="text/javascript">(function(d, s, id) {
                    var js, tjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = "https://app.termly.io/embed-policy.min.js";
                    tjs.parentNode.insertBefore(js, tjs);
                  }(document, 'script', 'termly-jssdk'));</script> -->
                  <!-- <div class="table-responsive">
                    <table class="table text-right">
                      <thead class="bg-default">
                        <tr>
                          <th class="text-right text-white" scope="col">
                            Item
                          </th>
                          <th class="text-right text-white" scope="col">
                            Qty
                          </th>
                          <th class="text-right text-white" scope="col">
                            Rate
                          </th>
                          <th class="text-right text-white" scope="col">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Premium Support</td>
                          <td>1</td>
                          <td>$ 9.00</td>
                          <td>$ 9.00</td>
                        </tr>
                        <tr>
                          <td>BLK Design System PRO</td>
                          <td>3</td>
                          <td>$ 100.00</td>
                          <td>$ 300.00</td>
                        </tr>
                        <tr>
                          <td>Parts for service</td>
                          <td>1</td>
                          <td>$ 89.00</td>
                          <td>$ 89.00</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th class="h4">Total</th>
                          <th class="text-right h4" colspan="3">$ 750</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <div class="col-md-5 ml-auto">
                <!-- <h5>Thank you!</h5> -->
                <p class="description">
                  If you encounter any issues related to this document you can
                  contact us at:
                </p>
                <h6 class="d-block">
                  email:
                  <small class="text-muted">
                    hello@insureco.io
                  </small>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 ml-auto">
          <button
            class="btn btn-primary btn-print mt-3 mb-6"
            (click)="print()"
            type="button"
          >
            <i class="tim-icons icon-laptop"> </i> Print
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
