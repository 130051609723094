
<div class="wrapper">
  <header class="header-2 skew-separator">
    <nav class="navbar navbar-expand-lg bg-white navbar-absolute">
      <div class="container">
        <div class="navbar-translate">
          <a class="navbar-brand" href="javascript:;"> Creative Tim </a>
          <button
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            [attr.aria-expanded]="!isCollapsed"
            (click)="isCollapsed = !isCollapsed"
            id="example-header-2"
            type="button"
          >
            <span class="navbar-toggler-icon"> </span>
          </button>
        </div>
        <div
          class="navbar-collapse"
          [isAnimated]="true"
          [collapse]="isCollapsed"
          id="example-header-2"
        >
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <a> Argon <span> PRO </span> </a>
              </div>
              <div class="col-6 collapse-close text-right">
                <button
                  aria-controls="navigation-index"
                  aria-label="Toggle navigation"
                  class="navbar-toggler"
                  [attr.aria-expanded]="!isCollapsed"
                  (click)="isCollapsed = !isCollapsed"
                  id="example-header-2"
                  type="button"
                >
                  <span> </span> <span> </span>
                </button>
              </div>
            </div>
          </div>
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"> Home </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"> About Us </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"> Products </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"> Contact Us </a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li class="nav-item">
              <a class="nav-link" href="https://twitter.com/CreativeTim">
                <i class="fab fa-twitter"> </i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.facebook.com/CreativeTim"
              >
                <i class="fab fa-facebook-square"> </i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.instagram.com/CreativeTimOfficial"
              >
                <i class="fab fa-instagram"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="page-header">
      <div
        class="page-header-image"
        style="background-image: url('assets/img/ill/p8.svg')"
      ></div>
      <div class="container pt-300">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h2 class="display-2">Trello lets you work</h2>
          </div>
        </div>
        <div class="row">
          <div class="floating-box bg-default">
            <div class="box text-center">
              <div
                class="icon icon-shape bg-success icon-xl rounded-circle text-white"
              >
                <i class="ni ni-spaceship"> </i>
              </div>
            </div>
            <h2 class="lead text-white p-5">
              As a result of growing greenhouse gas emissions, climate
              models predict that our planet will get significantly warmer,
              that ecosystems will be changed or destroyed, and that
              enormous human and economic costs will be incurred. These
              scenarios aren't guaranteed, but avoiding them will be very
              hard. We're trying to take small steps to mitigate our impact.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="main mt-5">
    <section
      class="pricing-3"
      id="pricing-4"
      style="background-image: url('assets/img/ill/1.svg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3">Some of Our Pricing Plans</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pr-md-0">
            <div class="card card-pricing text-center bg-default">
              <div class="card-header bg-transparent">
                <h4 class="text-uppercase ls-1 text-primary py-3 mb-0">
                  Bravo pack
                </h4>
              </div>
              <div class="card-body px-lg-6">
                <div class="display-2 text-white">$49</div>
                <span class="text-muted"> per application </span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-folder-17 text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Complete documentation
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-camera-compact text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          Working materials in Sketch
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="icon icon-xs icon-shape icon-shape-primary shadow rounded-circle"
                        >
                          <i class="ni ni-chart-pie-35 text-white"> </i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">
                          2GB cloud storage
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <button class="btn btn-primary mb-3" type="button">
                  Start free trial
                </button>
              </div>
              <div class="card-footer bg-transparent">
                <a class="text-muted" href="javascript:;">
                  Request a demo
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-7 pl-md-0">
            <div class="card card-pricing border-0 text-center my-5">
              <div class="card-header bg-transparent">
                <h4 class="text-uppercase ls-1 text-primary py-3 mb-0">
                  Enterprise
                </h4>
              </div>
              <div class="card-body px-lg-6">
                <div class="card-description">
                  What colors are good, before somebody tells you you
                  shouldn't like pink because that's for girls, or you'd
                  instantly become a gay two-year-old.
                </div>
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <td>24/7 Support</td>
                      <td>Design Tools</td>
                    </tr>
                    <tr>
                      <td>10K emails</td>
                      <td>Private Brand</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <a class="text-primary" href="javascript:;">
                  Create new account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />

    <br />

    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <span class="badge badge-primary badge-pill mb-3">
              Insight
            </span>
            <h3 class="display-3">Full-Funnel Social Analytics</h3>
            <p class="lead">
              The time is now for it to be okay to be great. For being a
              bright color. For standing out.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle"
              >
                <i class="ni ni-settings-gear-65"> </i>
              </div>
              <h6 class="info-title text-uppercase text-primary">
                Social Conversations
              </h6>
              <p class="description opacity-8">
                We get insulted by others, lose trust for those others. We
                get back stabbed by friends. It becomes harder for us to
                give others a hand.
              </p>
              <a class="text-primary" href="javascript:;">
                More about us
                <i class="ni ni-bold-right text-primary"> </i>
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle"
              >
                <i class="ni ni-atom"> </i>
              </div>
              <h6 class="info-title text-uppercase text-success">
                Analyze Performance
              </h6>
              <p class="description opacity-8">
                Don't get your heart broken by people we love, even that we
                give them all we have. Then we lose family over time. As we
                live, our hearts turn colder.
              </p>
              <a class="text-primary" href="javascript:;">
                Learn about our products
                <i class="ni ni-bold-right text-primary"> </i>
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle"
              >
                <i class="ni ni-world"> </i>
              </div>
              <h6 class="info-title text-uppercase text-warning">
                Measure Conversions
              </h6>
              <p class="description opacity-8">
                What else could rust the heart more over time? Blackgold.
                The time is now for it to be okay to be great. or being a
                bright color. For standing out.
              </p>
              <a class="text-primary" href="javascript:;">
                Check our documentation
                <i class="ni ni-bold-right text-primary"> </i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <br />

    <div class="contactus-3">
      <div class="page-header">
        <img
          alt=""
          class="bg-image"
          src="assets/img/ill/bg_contactus3.svg"
        />
      </div>
      <div class="container pt-5">
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <h1 class="display-1">Got a question?</h1>
            <h3 class="lead">
              We'd like to talk more about what you need
            </h3>
            <button class="btn btn-icon btn-primary mt-3" type="button">
              <span class="btn-inner--icon">
                <i class="ni ni-chat-round"> </i>
              </span>
              <span class="btn-inner--text"> Chat with us </span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-square-pin"> </i>
              </div>
              <h4 class="info-title">Address</h4>
              <p class="description px-0">12124 First Street, nr 54</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-email-83"> </i>
              </div>
              <h4 class="info-title">Email</h4>
              <p class="description px-0">hello@email.com</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-mobile-button"> </i>
              </div>
              <h4 class="info-title">Phone</h4>
              <p class="description px-0">+1(424) 535 3523</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <div class="info info-hover">
              <div
                class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
              >
                <i class="ni ni-circle-08"> </i>
              </div>
              <h4 class="info-title">Contact</h4>
              <p class="description px-0">Andrew Samian</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <br />

    <div class="project-2 my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3">Some of Our Awesome Products</h3>
            <p class="lead">
              The time is now for it to be okay to be great. People in this
              world shun people for being great.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-favourite-28"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Slack bot</h4>
                <p class="card-description">
                  If everything I did failed - which it doesn't, it actually
                  succeeds - just the fact that I'm willing to fail is an
                  inspiration. People are so scared to lose that they don't
                  even try.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-primary" type="button">
                    <i class="ni ni-glasses-2"> </i> Check more
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Looking great</h4>
                <p class="card-description">
                  You have the opportunity to play this game of life you
                  need to appreciate every moment. A lot of people don't
                  appreciate the moment until it's motivating the doers.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-success" type="button">
                    <i class="ni ni-key-25"> </i> Find a opportunity
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-trophy"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Developer First</h4>
                <p class="card-description">
                  For standing out. But the time is now to be okay to be the
                  greatest you. Would you believe in what you believe in, if
                  you were the only one who believed it?
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-warning" type="button">
                    <i class="ni ni-notification-70"> </i> Check more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-favourite-28"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Prepare launch</h4>
                <p class="card-description">
                  Society has put up so many boundaries, so many limitations
                  on what's right and wrong that it's almost impossible to
                  get a pure thought out. It's like a little kid, a little
                  boy.
                </p>
                <div class="card-footer">
                  <button
                    class="btn btn-link text-secondary"
                    type="button"
                  >
                    <i class="ni ni-glasses-2"> </i> Check out now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Premium support</h4>
                <p class="card-description">
                  Pink is obviously a better color. Everyone's born
                  confident, and everything's taken away from you matters is
                  the people who are sparked by it follow their dreams, too.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-danger" type="button">
                    <i class="ni ni-key-25"> </i> Find a opportunity
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-trophy"> </i>
                </div>
              </a>
              <div class="card-body">
                <h4 class="card-title mt-3">Design tools</h4>
                <p class="card-description">
                  Constantly growing. We're constantly making mistakes.
                  We're constantly trying to express ourselves and actualize
                  our dreams the position that we want to be.
                </p>
                <div class="card-footer">
                  <button class="btn btn-link text-info" type="button">
                    <i class="ni ni-notification-70"> </i> Check more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <br />

    <div class="subscribe-line">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-10 mx-auto">
            <div class="text-center">
              <h2 class="title">Subscribe to our Newsletter</h2>
              <p class="description">
                You'll be informed about updates, special offers and planned
                changes..
              </p>
            </div>
            <div class="card card-raised card-form-horizontal">
              <div class="card-body">
                <form action="" method="">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="input-group"  [ngClass]="{ 'focused': focus === true }">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="ni ni-email-83"> </i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          placeholder="Your Email..."
                          type="email" (blur)="focus = false" (focus)="focus = true"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <button class="btn btn-primary" type="button">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
