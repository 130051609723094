import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Ecast } from './ecast';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EcastService {
	
	ecastUrl = "https://api.ecast.io/sendEmail/";

    constructor(private http: HttpClient) { }


  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
    // sendEcast(ecast){
    // 	console.log("executing sendEcast");
    // 	return this.http.post<Ecast>(this.ecastUrl, ecast, httpOptions)
    // }

     // POST
  	sendEcast(ecast: Ecast): Observable<Ecast> {
    	return this.http.post<Ecast>(this.ecastUrl, JSON.stringify(ecast), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }  

 //    sendEcast (ecast: Ecast): Observable<Ecast> {
 //    	console.log("executing sendEcast");
 //    	var httpOptions = {};
 //  		return this.http.post<Ecast>(this.ecastUrl, ecast, httpOptions)
 //    		.pipe(
 //      			catchError(this.handleError('sendEcast', ecast))
 //    		);
	// }
  // Error handling
  errorHandl(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }

}
