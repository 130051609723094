
<div class="wrapper">
  <div class="page-header bg-default">
    <div
      class="page-header-image"
      style="background-image: url('assets/img/ill/register_bg.png');"
    ></div>
    <div class="container" id="container">
      <div class="form-container sign-up-container">
        <form action="javascript:;">
          <h2>Create Account</h2>
          <div class="social-container">
            <button class="btn btn-facebook btn-sm" type="button">
              <span class="btn-inner--icon">
                <i class="fab fa-facebook"> </i>
              </span>
            </button>
            <button class="btn-instagram btn btn-sm" type="button">
              <span class="btn-inner--icon">
                <i class="fab fa-instagram"> </i>
              </span>
            </button>
            <button class="btn btn-twitter btn-sm" type="button">
              <span class="btn-inner--icon">
                <i class="fab fa-twitter"> </i>
              </span>
            </button>
          </div>
          <span class="text-default mb-4">
            or use your email for registration
          </span>
          <div class="form-group mb-3"  [ngClass]="{ 'focused': focus === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-circle-08"> </i>
                </span>
              </div>
              <input class="form-control" placeholder="Name" type="text" (blur)="focus = false" (focus)="focus = true" />
            </div>
          </div>
          <div class="form-group mb-3"  [ngClass]="{ 'focused': focus1 === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"> </i>
                </span>
              </div>
              <input
                class="form-control"
                placeholder="Email" (blur)="focus1 = false" (focus)="focus1 = true"
                type="email"
              />
            </div>
          </div>
          <div class="form-group" [ngClass]="{ 'focused': focus2 === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"> </i>
                </span>
              </div>
              <input
                class="form-control"
                placeholder="Password" (blur)="focus2 = false" (focus)="focus2 = true"
                type="password"
              />
            </div>
          </div>
          <button class="btn btn-primary">Sign Up</button>
        </form>
      </div>
      <div class="form-container sign-in-container">
        <form action="#" role="form">
          <h2>Sign in</h2>
          <div class="social-container">
            <button class="btn btn-facebook btn-sm" type="button">
              <span class="btn-inner--icon">
                <i class="fab fa-facebook"> </i>
              </span>
            </button>
            <button class="btn-instagram btn btn-sm" type="button">
              <span class="btn-inner--icon">
                <i class="fab fa-instagram"> </i>
              </span>
            </button>
            <button class="btn btn-twitter btn-sm" type="button">
              <span class="btn-inner--icon">
                <i class="fab fa-twitter"> </i>
              </span>
            </button>
          </div>
          <span class="text-default mb-4"> or use your account </span>
          <div class="form-group mb-3" [ngClass]="{ 'focused': focus3 === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"> </i>
                </span>
              </div>
              <input
                class="form-control"
                placeholder="Email" (blur)="focus3 = false" (focus)="focus3 = true"
                type="email"
              />
            </div>
          </div>
          <div class="form-group" [ngClass]="{ 'focused': focus4 === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"> </i>
                </span>
              </div>
              <input
                class="form-control"
                placeholder="Password" (blur)="focus4 = false" (focus)="focus4 = true"
                type="password"
              />
            </div>
          </div>
          <a href="javascript:;"> Forgot your password? </a>
          <button class="btn btn-primary mt-3">Sign In</button>
        </form>
      </div>
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1 class="text-white">Welcome Back!</h1>
            <p>
              To keep connected with us please login with your personal info
            </p>
            <button class="btn btn-neutral btn-sm" id="signIn">
              Sign In
            </button>
          </div>
          <div class="overlay-panel overlay-right">
            <h1 class="text-white">Hello, Friend!</h1>
            <p>Enter your personal details and start journey with us</p>
            <button class="btn btn-neutral btn-sm" id="signUp">
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
