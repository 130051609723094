
<div class="wrapper">
  <header class="header-4 skew-separator">
    <div class="header-wrapper">
      <nav class="navbar navbar-expand-lg navbar-transparent">
        <div class="container">
          <div class="navbar-translate">
            <a class="navbar-brand text-white" href="javascript:;">
              Creative Tim
            </a>
            <button
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="example-header-4"
              type="button"
            >
              <span class="navbar-toggler-icon"> </span>
            </button>
          </div>
          <div
            class="navbar-collapse"
            [isAnimated]="true"
            [collapse]="isCollapsed"
            id="example-header-4"
          >
            <div class="navbar-collapse-header">
              <div class="row">
                <div class="col-6 collapse-brand">
                  <a> Argon <span> PRO </span> </a>
                </div>
                <div class="col-6 collapse-close text-right">
                  <button
                    aria-controls="navigation-index"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    [attr.aria-expanded]="!isCollapsed"
                    (click)="isCollapsed = !isCollapsed"
                    id="example-header-4"
                    type="button"
                  >
                    <span> </span> <span> </span>
                  </button>
                </div>
              </div>
            </div>
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> About Us </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Products </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"> Contact Us </a>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li class="nav-item">
                <a class="nav-link" href="https://twitter.com/CreativeTim">
                  <i class="fab fa-twitter"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.facebook.com/CreativeTim"
                >
                  <i class="fab fa-facebook-square"> </i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.instagram.com/CreativeTimOfficial"
                >
                  <i class="fab fa-instagram"> </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header header-video">
        <div class="overlay"></div>
        <video
          autoplay="autoplay"
          loop="loop"
          muted="muted"
          playsinline="playsinline"
        >
          <source
            src="https://www.oberlo.com/wp-content/uploads/2018/10/1.-Pixabay.mp4"
            type="video/mp4"
          />
        </video>
        <div class="container text-center">
          <div class="row">
            <div class="col-lg-7 mx-auto">
              <h1 class="video-text">People stories</h1>
              <h1 class="display-3 text-white">The time is right now!</h1>
              <a
                class="btn btn-warning btn-icon mt-3 mb-sm-0"
                href="https://www.creative-tim.com/product/argon-design-system-pro"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-button-play"> </i>
                </span>
                <span class="btn-inner--text"> Play more </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="section features-6">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="info info-horizontal info-hover-primary">
            <div
              class="icon icon-shape icon-shape-info rounded-circle text-white"
            >
              <i class="ni ni-html5 text-info"> </i>
            </div>
            <div class="description pl-4">
              <h5 class="title">For Developers</h5>
              <p>
                The time is now for it to be okay to be great. People in
                this world shun people for being great. For being a bright
                color. For standing out. But the time is now.
              </p>
              <a class="text-info" href="#"> Learn more </a>
            </div>
          </div>
          <div class="info info-horizontal info-hover-primary">
            <div
              class="icon icon-shape icon-shape-info rounded-circle text-white"
            >
              <i class="ni ni-app text-info"> </i>
            </div>
            <div class="description pl-4">
              <h5 class="title">For Designers</h5>
              <p>
                There's nothing I really wanted to do in life that I wasn't
                able to get good at. That's my skill. I'm not really
                specifically talented at anything except for the ability to
                learn.
              </p>
              <a class="text-info" href="#"> Learn more </a>
            </div>
          </div>
          <div class="info info-horizontal info-hover-primary">
            <div
              class="icon icon-shape icon-shape-info rounded-circle text-white"
            >
              <i class="ni ni-bell-55 text-info"> </i>
            </div>
            <div class="description pl-4">
              <h5 class="title">For Beginners</h5>
              <p>
                That's what I do. That's what I'm here for. Don't be afraid
                to be wrong because you can't learn anything from a
                compliment. If everything I did failed - which it doesn't.
              </p>
              <a class="text-info" href="#"> Learn more </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-10 mx-md-auto">
          <img
            class="ml-lg-5"
            src="assets/img/ill/ill.png"
            width="100%"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="section features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <span class="badge badge-primary badge-pill mb-3">
            Insight
          </span>
          <h3 class="display-3">Full-Funnel Social Analytics</h3>
          <p class="lead">
            The time is now for it to be okay to be great. For being a
            bright color. For standing out.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle"
            >
              <i class="ni ni-settings-gear-65"> </i>
            </div>
            <h6 class="info-title text-uppercase text-primary">
              Social Conversations
            </h6>
            <p class="description opacity-8">
              We get insulted by others, lose trust for those others. We get
              back stabbed by friends. It becomes harder for us to give
              others a hand.
            </p>
            <a class="text-primary" href="javascript:;">
              More about us <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle"
            >
              <i class="ni ni-atom"> </i>
            </div>
            <h6 class="info-title text-uppercase text-success">
              Analyze Performance
            </h6>
            <p class="description opacity-8">
              Don't get your heart broken by people we love, even that we
              give them all we have. Then we lose family over time. As we
              live, our hearts turn colder.
            </p>
            <a class="text-primary" href="javascript:;">
              Learn about our products
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle"
            >
              <i class="ni ni-world"> </i>
            </div>
            <h6 class="info-title text-uppercase text-warning">
              Measure Conversions
            </h6>
            <p class="description opacity-8">
              What else could rust the heart more over time? Blackgold. The
              time is now for it to be okay to be great. or being a bright
              color. For standing out.
            </p>
            <a class="text-primary" href="javascript:;">
              Check our documentation
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="blogs-2">
    <div class="container-fluid">
      <div class="row mb-md-5">
        <div class="col-md-8 mx-auto">
          <h3 class="display-3 text-center">Our recent writings</h3>
          <p class="lead text-center">
            I always felt like I could do anything. That's the main thing
            people are controlled by! Thoughts- their perception of
            themselves! They're slowed down by their perception of
            themselves. If you're taught you can't do anything, you won't do
            anything. I was taught I could do everything.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/damian.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    AI at the Edge
                  </h6>
                  <h5 class="card-title">Research Byte</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/ashim.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    Spectrum
                  </h6>
                  <h5 class="card-title">Data Virtualization</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/odin.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    Touch on a trend
                  </h6>
                  <h5 class="card-title">New Challenges</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/dane.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    Self-Driving Cars
                  </h6>
                  <h5 class="card-title">Driverless Future</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <br />

  <br />

  <section
    class="pricing-5"
    id="pricing-6"
    style="background-image: url('assets/img/ill/bg_pricing5.svg');"
  >
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-4 d-flex justify-content-center flex-column">
          <h3 class="display-3 mt-3">
            Choose a plan for your next project
          </h3>
          <p class="lead mt-0">
            You have Free Unlimited Updates and Premium Support on each
            package. You also have 20 days to request a refund.
          </p>
        </div>
        <div class="col-lg-7 col-md-8 ml-auto mr-auto">
          <tabset type="pills" class="nav-fill nav-pills-primary">
            <tab heading="Cheaper">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">Gold</h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$100</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 200GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Premium </b>
                          <span> Support </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Platinum
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$135</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 500GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> No time </b>
                          <span> Tracking </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="Expensive">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Standard
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$25</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 20GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> 15 </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> false </b>
                          <span> Support </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Premium
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$59</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 50GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> 100 </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Premium </b>
                          <span> Support </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </section>
</div>
