
<div class="wrapper">
  <div
    class="page-header page-header-small header-filter skew-separator skew-mini"
  >
    <div
      class="page-header-image"
      style="background-image: url('assets/img/pages/kevin-grieve.jpg');"
    ></div>
    <div class="container pt-0">
      <div class="row">
        <div class="col-lg-6 col-md-7 mx-auto text-center">
          <h1 class="title text-white">See our latest collection!</h1>
          <br />

          <div class="buttons">
            <a
              class="btn btn-danger btn-round btn-lg d-inline"
              href="javascript:;"
            >
              <i class="ni ni-cart ml-1"> </i>
            </a>
            <p class="mt-3 d-inline text-white">Shop Now!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-bottom separator-skew">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveaspectratio="none"
        version="1.1"
        viewbox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  </div>
  <div class="main">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="card card-blog" data-header="pattern">
            <a href="javascript:;">
              <img
                class="img pattern rounded"
                src="assets/img/ill/presentation_bg.png"
              />
            </a>
            <div class="card-body">
              <h6 class="card-category text-danger">
                <i class="ni ni-badge"> </i> Wearing
              </h6>
              <h5 class="card-title">
                <a href="javascript:;"> Choose the best products </a>
              </h5>
              <p class="card-description">
                Yesterday, as Facebook launched its news reader app Paper,
                design-focused startup FiftyThree called out Facebook...
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <img
              alt="Image placeholder"
              class="card-img-top"
              src="assets/img/ill/linth3.svg"
            />

            <div class="card-body">
              <h6 class="card-category text-danger">
                <i class="ni ni-badge"> </i> Fashion
              </h6>
              <h5 class="card-title text-success">
                <a href="javascript:;"> Wardrobe essentials </a>
              </h5>
              <p class="card-text mt-4">
                Argon is a great free UI package based on Bootstrap 4 that
                includes the most important components and features.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card card-blog" data-header="pattern">
            <a href="javascript:;">
              <img
                class="img pattern rounded"
                src="assets/img/ill/p2.svg"
              />
            </a>
            <div class="card-body">
              <h6 class="card-category text-danger">
                <i class="ni ni-badge"> </i> Fashion
              </h6>
              <h5 class="card-title">
                <a href="javascript:;"> Wardrobe essentials </a>
              </h5>
              <p class="card-description">
                Trends may come and go, but wardrobe essentials are here to
                stay. Basic pieces tend to be universal across.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <h3 class="desc my-5">Fresh new collection</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="card card-product card-plain">
                  <div class="card-image">
                    <span class="badge badge-info"> NEW </span>
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/tshirt.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h5 class="card-title">Ralph Lauren</h5>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price opacity-8"> € 300 </span>
                      </div>
                      <button
                        class="btn btn-danger btn-icon-only btn-round pull-right btn-sm"
                        placement="left"
                        tooltip="Remove from wishlist"
                      >
                        <i class="ni ni-favourite-28"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card card-product card-plain">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/bag.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h5 class="card-title">Tom Ford</h5>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price-old opcaity-8"> € 1179 </span>
                        <span class="price-new ml-1"> € 999 </span>
                      </div>
                      <button
                        class="btn btn-danger btn-icon-only btn-round pull-right btn-sm"
                        placement="left"
                        tooltip="Add to wishlist"
                      >
                        <i class="ni ni-favourite-28"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card card-product card-plain">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/shirt.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h5 class="card-title">Wooyoungmi</h5>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price opacity-8"> € 555 </span>
                      </div>
                      <button
                        class="btn btn-danger btn-icon-only btn-round pull-right btn-sm"
                        placement="left"
                        tooltip="Add to wishlist"
                      >
                        <i class="ni ni-favourite-28"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card card-product card-plain">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/jeans.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h5 class="card-title">Thom Sweeney</h5>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price opacity-8"> € 680 </span>
                      </div>
                      <button
                        class="btn btn-danger btn-icon-only btn-round pull-right btn-sm"
                        placement="left"
                        tooltip="Add to wishlist"
                      >
                        <i class="ni ni-favourite-28"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 ml-auto mr-auto mt-5 text-center">
                <button class="btn btn-primary btn-round btn-simple">
                  Load more...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center">
      <div class="row mb-5">
        <div class="col-md-8 mx-auto">
          <h3 class="display-3">Our recent writings</h3>
          <p class="lead mt-1">
            The time is now for it to be okay to be great. People in this
            world shun people for being great.
          </p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/theme/josh-appel.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    New Challenges
                  </h6>
                  <h5 class="card-title">Touch on a trend</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/theme/john-hoang.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    New Opportunities
                  </h6>
                  <h5 class="card-title">Constantly growing</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/theme/kit-suman.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    Sales Leads
                  </h6>
                  <h5 class="card-title">
                    Configure Blockchain Technology
                  </h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/damian.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    AI at the Edge
                  </h6>
                  <h5 class="card-title">Research Byte</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/chrisa.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">Focus</h6>
                  <h5 class="card-title">Stay alert</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="card card-blog card-background"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/odin.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body">
                <div class="content-bottom">
                  <h6 class="card-category text-white opacity-8">
                    Touch on a trend
                  </h6>
                  <h5 class="card-title">New Challenges</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <button class="btn btn-icon btn-primary mt-4" type="button">
        <span class="btn-inner--text"> Show more </span>
        <span class="btn-inner--icon">
          <i class="ni ni-bold-right"> </i>
        </span>
      </button>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <h3 class="desc my-5">The latest accessories</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="card card-product">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/hat.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h4 class="card-title">Winter Hat</h4>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price"> € 99 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card card-product">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/shorts.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h4 class="card-title">Gucci Shorts</h4>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price-old"> € 879 </span>
                        <span class="price-new ml-1"> € 499 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card card-product">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img
                        alt="..."
                        src="assets/img/pages/bracelet.png"
                      />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h4 class="card-title">Ice Bracelet</h4>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price"> € 19,999 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card card-product">
                  <div class="card-image">
                    <a href="javascript:;">
                      <img alt="..." src="assets/img/pages/bullet.png" />
                    </a>
                  </div>
                  <div class="card-body">
                    <a href="javascript:;">
                      <h4 class="card-title">Necklace</h4>
                    </a>
                    <div class="card-footer">
                      <div class="price-container">
                        <span class="price"> € 229 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 ml-auto mr-auto mt-5 text-center">
                <button class="btn btn-primary btn-round btn-simple">
                  Load more...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="testimonials-1 bg-secondary py-5 skew-separator skew-top">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h3 class="display-3">What Clients Say</h3>
          </div>
        </div>
        <carousel [showIndicators]="false" class="carousel-team">
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 ml-auto">
                  <h3 class="card-title">Sarah Smith</h3>
                  <h3 class="text-primary">• • •</h3>
                  <h4 class="lead">
                    Take up one idea. Make that one idea your life - think
                    of it, dream of it, live on that idea. Let the brain,
                    muscles, nerves, every part of your body, be full of
                    that idea, and just leave every other idea alone. This
                    is the way to success. A single rose can be my garden...
                    a single friend, my world.
                  </h4>
                  <a
                    class="btn btn-primary mt-3"
                    href="javascript:void(0)"
                  >
                    Read more
                  </a>
                </div>
                <div class="col-md-5 p-5 ml-auto">
                  <div class="p-3">
                    <img
                      alt="First slide"
                      class="img-fluid rounded shadow transform-perspective-right"
                      src="assets/img/faces/fezbot.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 ml-auto">
                  <h3 class="card-title">Isaac Hunter</h3>
                  <h3 class="text-info">• • •</h3>
                  <h4 class="lead">
                    Take up one idea. Make that one idea your life - think
                    of it, dream of it, live on that idea. Let the brain,
                    muscles, nerves, every part of your body, be full of
                    that idea, and just leave every other idea alone. This
                    is the way to success. A single rose can be my garden...
                    a single friend, my world.
                  </h4>
                  <a class="btn btn-info mt-3" href="javascript:void(0)">
                    Read more
                  </a>
                </div>
                <div class="col-md-5 p-5 ml-auto">
                  <div class="p-3">
                    <img
                      alt="First slide"
                      class="img-fluid rounded shadow transform-perspective-right"
                      src="assets/img/faces/brooke-cagle.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</div>
