
<div class="wrapper">
  <section class="section-profile-cover section-shaped my-0">
    <img
      class="bg-image"
      src="assets/img/pages/mohamed.jpg"
      style="width: 100%;"
    />

    <div class="separator separator-bottom separator-skew">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-secondary" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>
  <section class="section bg-secondary">
    <div class="container">
      <div class="card card-profile shadow mt--300">
        <div class="px-4">
          <div class="row justify-content-center">
            <div class="col-lg-3 order-lg-2">
              <div class="card-profile-image">
                <a href="javascript:;">
                  <img
                    class="rounded-circle"
                    src="assets/img/faces/team-4.jpg"
                  />
                </a>
              </div>
            </div>
            <div
              class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
            >
              <div class="card-profile-actions py-4 mt-lg-0">
                <a class="btn btn-sm btn-info mr-4" href="#"> Connect </a>
                <a class="btn btn-sm btn-default float-right" href="#">
                  Message
                </a>
              </div>
            </div>
            <div class="col-lg-4 order-lg-1">
              <div
                class="card-profile-stats d-flex justify-content-center"
              >
                <div>
                  <span class="heading"> 22 </span>
                  <span class="description"> Friends </span>
                </div>
                <div>
                  <span class="heading"> 10 </span>
                  <span class="description"> Photos </span>
                </div>
                <div>
                  <span class="heading"> 89 </span>
                  <span class="description"> Comments </span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <h3>
              Jessica Jones<span class="font-weight-light"> , 27 </span>
            </h3>
            <div class="h6 font-weight-300">
              <i class="ni location_pin mr-2"> </i> Bucharest, Romania
            </div>
            <div class="h6 mt-4">
              <i class="ni business_briefcase-24 mr-2"> </i> Solution
              Manager - Creative Tim Officer
            </div>
            <div>
              <i class="ni education_hat mr-2"> </i> University of Computer
              Science
            </div>
          </div>
          <div class="mt-5 py-5 border-top text-center">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <p>
                  An artist of considerable range, Ryan — the name taken by
                  Melbourne-raised, Brooklyn-based Nick Murphy — writes,
                  performs and records all of his own music, giving it a
                  warm, intimate feel with a solid groove structure. An
                  artist of considerable range.
                </p>
                <a href="javascript:;"> Show more </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
