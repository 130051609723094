
<div class="wrapper">
  <div class="cd-section" id="headers">
    <header class="header-1">
      <div class="page-header">
        <div
          class="page-header-image"
          style="background-image: url('assets/img/ill/p2.svg')"
        ></div>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-md-7 mr-auto text-left d-flex justify-content-center flex-column"
            >
              <h3 class="display-3">Training</h3>
              <p class="lead mt-0">
                The time is now for it to be okay to be great. People in
                this world shun people for being great. For being a bright
                color.
              </p>
              <br />

              <div class="buttons">
                <a class="btn btn-danger" href="javascript:;"> Got it </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

  <div class="cd-section" id="derek">
    <div class="accordion-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h2 class="title mb-3 mt-5">Frequently asked question</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseOne"
                      class="btn btn-link w-100 text-primary text-left"
                      [attr.aria-expanded]="!isCollapsed"
                      (click)="isCollapsed = !isCollapsed"
                      id="collapseOne"
                      type="button"
                    >
                      How do I order?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingOne"
                  class="show"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed"
                  id="collapseOne"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseTwo"
                      class="btn btn-link w-100 text-primary text-left collapsed"
                      [attr.aria-expanded]="!isCollapsed1"
                      (click)="isCollapsed1 = !isCollapsed1"
                      id="collapseTwo"
                      type="button"
                    >
                      How can i make the payment?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed1"
                  id="collapseTwo"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseThree"
                      class="btn btn-link w-100 text-primary text-left collapsed"
                      [attr.aria-expanded]="!isCollapsed2"
                      (click)="isCollapsed2 = !isCollapsed2"
                      id="collapseThree"
                      type="button"
                    >
                      How much time does it take to receive the order?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed2"
                  id="collapseThree"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseFour"
                      class="btn btn-link w-100 text-primary text-left"
                      [attr.aria-expanded]="!isCollapsed3"
                      (click)="isCollapsed3 = !isCollapsed3"
                      id="collapseFour"
                      type="button"
                    >
                      Can I resell the products?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed3"
                  id="collapseFour"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFifth">
                  <h5 class="mb-0">
                    <button
                      aria-controls="collapseFifth"
                      class="btn btn-link w-100 text-primary text-left"
                      [attr.aria-expanded]="!isCollapsed4"
                      (click)="isCollapsed4 = !isCollapsed4"
                      id="collapseFifth"
                      type="button"
                    >
                      Where do I find the shipping details?
                      <i class="ni ni-bold-down float-right pt-1"> </i>
                    </button>
                  </h5>
                </div>
                <div
                  aria-labelledby="headingFifth"
                  data-parent="#accordionExample"
                  [isAnimated]="true"
                  [collapse]="isCollapsed4"
                  id="collapseFifth"
                >
                  <div class="card-body opacity-8">
                    Anim pariatur cliche reprehenderit, enim eiusmod high
                    life accusamus terry richardson ad squid. 3 wolf moon
                    officia aute, non cupidatat skateboard dolor brunch.
                    Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                    wolf moon tempor, sunt aliqua put a bird on it squid
                    single-origin coffee nulla assumenda shoreditch et.
                    Nihil anim keffiyeh helvetica, craft beer labore wes
                    anderson cred nesciunt sapiente ea proident. Ad vegan
                    excepteur butcher vice lomo. Leggings occaecat craft
                    beer farm-to-table, raw denim aesthetic synth nesciunt
                    you probably haven't heard of them accusamus labore
                    sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
