
<div class="wrapper">
  <header class="header-5">

    <div class="page-header">
      <div class="container">
        <div class="row align-items-center text-left">
          <div class="col-lg-5 col-12">
            <h1 class="display-3">
              philosophy<span class="text-primary">
                Develop Anything.
              </span>
            </h1>
            <p class="lead pb-4">
              philosophy high at being focused on building relationships with
              our clients and eeeee. Using our creative gifts drives
              this foundation.
            </p>
            <div class="row row-input">
              <div class="col-sm-8 col-12">
                <input
                  aria-label="Your email"
                  class="form-control"
                  id="signupSrEmail"
                  name="email"
                  placeholder="Your email"
                  type="email"
                />
              </div>
              <div class="col-sm-4 col-12 pl-lg-0">
                <button class="btn btn-primary btn-block" type="submit">
                  More
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-12 pl-0">
            <img
              class="ml-lg-5"
              src="assets/img/ill/bg6-2.svg"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="section features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <span class="badge badge-primary badge-pill mb-3">
            Insight
          </span>
          <h3 class="display-3">Full-Funnel Social Analytics</h3>
          <p class="lead">
            The time is now for it to be okay to be great. For being a
            bright color. For standing out.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle"
            >
              <i class="ni ni-settings-gear-65"> </i>
            </div>
            <h6 class="info-title text-uppercase text-primary">
              Social Conversations
            </h6>
            <p class="description opacity-8">
              We get insulted by others, lose trust for those others. We get
              back stabbed by friends. It becomes harder for us to give
              others a hand.
            </p>
            <a class="text-primary" href="javascript:;">
              More about us <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle"
            >
              <i class="ni ni-atom"> </i>
            </div>
            <h6 class="info-title text-uppercase text-success">
              Analyze Performance
            </h6>
            <p class="description opacity-8">
              Don't get your heart broken by people we love, even that we
              give them all we have. Then we lose family over time. As we
              live, our hearts turn colder.
            </p>
            <a class="text-primary" href="javascript:;">
              Learn about our products
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div
              class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle"
            >
              <i class="ni ni-world"> </i>
            </div>
            <h6 class="info-title text-uppercase text-warning">
              Measure Conversions
            </h6>
            <p class="description opacity-8">
              What else could rust the heart more over time? Blackgold. The
              time is now for it to be okay to be great. or being a bright
              color. For standing out.
            </p>
            <a class="text-primary" href="javascript:;">
              Check our documentation
              <i class="ni ni-bold-right text-primary"> </i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="blogs-6">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h3 class="display-3 mb-5">Latest Blogposts</h3>
          <div
            class="card card-blog card-background card-grande"
            data-animation="zooming"
          >
            <div
              class="full-background"
              style="background-image: url('assets/img/sections/unsplashs.jpg')"
            ></div>
            <a href="javascript:;">
              <div class="card-body mb-4">
                <h2 class="card-title">Miami Vice</h2>
                <p class="card-description text-white">
                  The simplest visual description uses ordinary words to
                  convey what the writer sees. First he or she must look at
                  the subject â slowly, carefully, and repeatedly, if
                  possible â to identify the parts that make the whole
                </p>
                <div class="author">
                  <img
                    alt="..."
                    class="avatar"
                    src="assets/img/faces/team-4.jpg"
                  />

                  <span class="text-white"> by Johanna Zmud </span>
                </div>
                <br />

                <a class="btn btn-primary" href="javascript:;">
                  Read more...
                </a>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    class="pricing-5"
    id="pricing-6"
    style="background-image: url('assets/img/ill/bg_pricing5.svg');"
  >
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-4 d-flex justify-content-center flex-column">
          <h3 class="display-3 mt-3">
            Choose a plan for your next project
          </h3>
          <p class="lead mt-0">
            You have Free Unlimited Updates and Premium Support on each
            package. You also have 20 days to request a refund.
          </p>
        </div>
        <div class="col-lg-7 col-md-8 ml-auto mr-auto">
          <tabset type="pills" class="nav-fill nav-pills-primary">
            <tab heading="Cheaper">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Standard
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$25</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 20GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> 15 </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> false </b>
                          <span> Support </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Premium
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$59</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 50GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> 100 </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Premium </b>
                          <span> Support </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="Expensive">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">Gold</h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$100</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 200GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Premium </b>
                          <span> Support </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="card card-pricing bg-white border-0 text-center mb-4"
                  >
                    <div class="card-header bg-transparent">
                      <h6 class="text-uppercase ls-1 py-3 mb-0">
                        Platinum
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="display-2">$135</div>
                      <span> per month </span>
                      <ul class="list-unstyled my-4">
                        <li class="align-items-center">
                          <b class="text-primary"> 500GB </b>
                          <span> File Storage </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> Unlimited </b>
                          <span> Users </span>
                        </li>
                        <li class="align-items-center">
                          <b class="text-primary"> No time </b>
                          <span> Tracking </span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href="#!"> Request a demo </a>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </section>
  <section class="team-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h3 class="display-3">Our Awesome Team 1</h3>
          <p class="lead">
            People in this world shun people for being great. For being a
            bright color. For standing out. But the time is now to be okay
            to be the greatest you. Would you believe in what you believe
            in?
          </p>
        </div>
      </div>
      <div class="row">
        <carousel [showIndicators]="false" class="carousel-team">
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 p-5">
                  <div class="p-4">
                    <img
                      alt="First slide"
                      class="rounded shadow transform-perspective-left"
                      src="assets/img/theme/kareya-saleh.jpg"
                    />
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="wrapper p-md-0">
                    <h3 class="card-title display-3">Charlie Watson</h3>
                    <div class="lead">
                      Artist is a term applied to a person who engages in an
                      activity deemed to be an art. An artist also may be
                      defined unofficially as "a person should is one who
                      expresses him- or herself through a medium". He is
                      should a descriptive term applied to a person who
                      engages in an activity deemed to be an art.
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div
                              class="badge badge-circle badge-info mr-3"
                            >
                              <i class="ni ni-atom"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-1">Dedicated entrepreneur</p>
                          </div>
                        </div>
                      </li>
                      <li class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div
                              class="badge badge-circle badge-success mr-3"
                            >
                              <i class="ni ni-user-run"> </i>
                            </div>
                          </div>
                          <div><p class="mb-1">Urban exploration</p></div>
                        </div>
                      </li>
                    </ul>
                    <div class="footer text-left">
                      <a
                        class="btn btn-twitter btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-twitter"> </i>
                      </a>
                      <a
                        class="btn btn-facebook btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-facebook-square"> </i>
                      </a>
                      <a
                        class="btn btn-dribbble btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-dribbble"> </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 p-5">
                  <div class="p-4">
                    <img
                      alt="First slide"
                      class="rounded shadow transform-perspective-left"
                      src="assets/img/theme/lucy.jpg"
                    />
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="wrapper p-md-0">
                    <h1 class="card-title">Quavo Austen</h1>
                    <div class="lead">
                      Artist is a term applied to a person who engages in an
                      activity deemed to be an art. An artist also may be
                      defined unofficially as "a person should is one who
                      expresses him- or herself through a medium". He is
                      should a descriptive term applied to a person who
                      engages in an activity deemed to be an art."
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div
                              class="badge badge-circle badge-info mr-3"
                            >
                              <i class="ni ni-atom"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-1">Dedicated entrepreneur</p>
                          </div>
                        </div>
                      </li>
                      <li class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div
                              class="badge badge-circle badge-success mr-3"
                            >
                              <i class="ni ni-user-run"> </i>
                            </div>
                          </div>
                          <div><p class="mb-1">Urban exploration</p></div>
                        </div>
                      </li>
                    </ul>
                    <div class="footer text-left">
                      <a
                        class="btn btn-twitter btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-twitter"> </i>
                      </a>
                      <a
                        class="btn btn-facebook btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-facebook-square"> </i>
                      </a>
                      <a
                        class="btn btn-dribbble btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-dribbble"> </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="container">
              <div class="row">
                <div class="col-md-5 p-5">
                  <div class="p-4">
                    <img
                      alt="First slide"
                      class="rounded shadow transform-perspective-left"
                      src="assets/img/theme/willy-dade.jpg"
                    />
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="wrapper p-md-0">
                    <h1 class="card-title">Lucy Thomson</h1>
                    <div class="lead">
                      Artist is a term applied to a person who engages in an
                      activity deemed to be an art. An artist also may be
                      defined unofficially as "a person should is one who
                      expresses him- or herself through a medium". He is
                      should a descriptive term applied to a person who
                      engages in an activity deemed to be an art."
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div
                              class="badge badge-circle badge-info mr-3"
                            >
                              <i class="ni ni-atom"> </i>
                            </div>
                          </div>
                          <div>
                            <p class="mb-1">Dedicated entrepreneur</p>
                          </div>
                        </div>
                      </li>
                      <li class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div
                              class="badge badge-circle badge-success mr-3"
                            >
                              <i class="ni ni-user-run"> </i>
                            </div>
                          </div>
                          <div><p class="mb-1">Urban exploration</p></div>
                        </div>
                      </li>
                    </ul>
                    <div class="footer text-left">
                      <a
                        class="btn btn-twitter btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-twitter"> </i>
                      </a>
                      <a
                        class="btn btn-facebook btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-facebook-square"> </i>
                      </a>
                      <a
                        class="btn btn-dribbble btn-sm rounded-circle"
                        href="javascript:;"
                      >
                        <i class="fab fa-dribbble"> </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</div>
