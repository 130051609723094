import { Component, OnInit, OnDestroy } from "@angular/core";
//import Glide from "@glidejs/glide";
declare const google: any;

@Component({
  selector: "app-insurtech",
  templateUrl: "insurtech.component.html"
})
export class InsurtechComponent implements OnInit, OnDestroy {

  // isCollapsed = false;
  // isCollapsed1 = true;
  // isCollapsed2 = true;
  // isCollapsed3 = true;
  // isCollapsed4 = true;

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("presentation-page");
    var navbar = document.getElementById("navbar-main");
    navbar.classList.add("bg-gradient-warning");
  }


  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("insurtech-page");
    var navbar = document.getElementById("navbar-main");
    navbar.classList.remove("bg-gradient-warning");
  }
}
