
<div class="wrapper">
  <div class="cd-section" id="headers">
    <header class="header-1">
      <div class="page-header">
        <div
          class="page-header-image"
          style="background-image: url('assets/img/ill/p2.svg')"
        ></div>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-md-7 mr-auto text-left d-flex justify-content-center flex-column"
            >
              <h3 class="display-3">Services</h3>
              <p class="lead mt-0">
                The time is now for it to be okay to be great. People in
                this world shun people for being great. For being a bright
                color.
              </p>
              <br />

              <div class="buttons">
                <a class="btn btn-danger" href="javascript:;"> Got it </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

  <div class="section features-6">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="info info-horizontal info-hover-primary">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-html5 text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title">For Developers</h5>
                <p>
                  The time is now for it to be okay to be great. People in
                  this world shun people for being great. For being a bright
                  color. For standing out. But the time is now.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
            <div class="info info-horizontal info-hover-primary">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-app text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title">For Designers</h5>
                <p>
                  There's nothing I really wanted to do in life that I
                  wasn't able to get good at. That's my skill. I'm not
                  really specifically talented at anything except for the
                  ability to learn.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
            <div class="info info-horizontal info-hover-primary">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-bell-55 text-info"> </i>
              </div>
              <div class="description pl-4">
                <h5 class="title">For Beginners</h5>
                <p>
                  That's what I do. That's what I'm here for. Don't be
                  afraid to be wrong because you can't learn anything from a
                  compliment. If everything I did failed - which it doesn't.
                </p>
                <a class="text-info" href="#"> Learn more </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-10 mx-md-auto">
            <img
              class="ml-lg-5"
              src="assets/img/ill/ill.png"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
</div>
