import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgForm } from '@angular/forms';
import { EcastService } from '../ecast.service';

@Component({
  selector: "app-markets",
  templateUrl: "markets.component.html"
})
export class MarketsComponent implements OnInit, OnDestroy {

  focus: any;
  focus1: any;
  focus2: any;
  focus3: any;
  focus4: any;
  focus5: any;
  focus6: any;
  focus7: any;
  focus8: any;
  focus9: any;
  marketPlaceResponse: string;
  marketPlaceResponseSuccess: boolean;
  marketPlaceResponseError: boolean;


  constructor(private ecastService: EcastService) {
  }

   hero = {name: null , email: null, details: null};

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("markets-page");
    var navbar = document.getElementById("navbar-main");
    navbar.classList.add("bg-gradient-info");
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("markets-page");
    var navbar = document.getElementById("navbar-main");
    navbar.classList.remove("bg-gradient-info");
  }
  onSubmit(form: NgForm) {
    console.log('Your form data : ', form.value);
    var payload = {
      "senderId": "insureco.io",
      "event": "iec-www-marketplace-request",
      "headers": {
        "to": "me@daryx.com",
        "toName": "Derek Lovrenich",
        "mobileNumber": "+12144159224"
      },
      "templateVars": form.value
    };
    console.log('Your form payload : ', payload);
    this.ecastService.sendEcast(payload).subscribe((resp: any) => {
      console.log(resp);
      if (resp.status == "success") {
        form.resetForm();
        this.marketPlaceResponseError = false;
        this.marketPlaceResponseSuccess = true;
        this.marketPlaceResponse = "Your request has been submitted. Thank you.";
      } else {
        this.marketPlaceResponseError = true;
        this.marketPlaceResponseSuccess = false;
        this.marketPlaceResponse = "Something went wrong. Please try later.";
      }
    }, err => {
      console.log(err);
      this.marketPlaceResponseError = true;
      this.marketPlaceResponseSuccess = false;
      this.marketPlaceResponse = "Something went wrong. Please try later.";
    });
  }
}
