
<div class="wrapper">
  <div class="page-header page-header-small header-filter">
    <div
      class="page-header-image"
      style="background-image: url('assets/img/pages/nicolas-prieto.jpg');"
    ></div>
    <div class="content-center">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <h1 class="title text-white">
            A Place for Entrepreneurs to Share and Discover New Stories
          </h1>
          <a
            class="btn btn-warning btn-round btn-icon-only"
            href="#button"
          >
            <i class="fab fa-twitter"> </i>
          </a>
          <a
            class="btn btn-warning btn-round btn-icon-only"
            href="#button"
          >
            <i class="fab fa-instagram"> </i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="main main-raised">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="card card-blog bg-default">
            <img
              class="img pattern rounded"
              src="assets/img/ill/p7.svg"
            />

            <div class="card-body">
              <h4 class="display-4 text-white">Bussiness</h4>
              <p class="lead text-white mt-0">
                Society has put up so many boundaries and limitations on
                what's right and wrong that it's almost impossible...
              </p>
              <a class="btn btn-link text-white px-0" href="javascript:;">
                Read more
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card card-blog bg-default">
            <img
              class="img pattern rounded"
              src="assets/img/ill/p22.png"
            />

            <div class="card-body">
              <h4 class="display-4 text-white">Premium</h4>
              <p class="lead text-white mt-0">
                Colors are good, before somebody tells you you shouldn't
                like pink because that's for girls.
              </p>
              <a class="btn btn-link text-white px-0" href="javascript:;">
                Read more
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card card-blog bg-default">
            <img
              class="img pattern rounded"
              src="assets/img/ill/p7.svg"
            />

            <div class="card-body">
              <h4 class="display-4 text-white">Focus</h4>
              <p class="lead text-white mt-0">
                Because it's about motivating the doers. Because I'm here to
                follow my dreams and inspire other people.
              </p>
              <a class="btn btn-link text-white px-0" href="javascript:;">
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <section class="blogs-3">
              <div class="container">
                <div class="row">
                  <div class="col-lg-10 col-md-8 mx-auto">
                    <h2 class="title mb-5">Related Stories</h2>
                    <div
                      class="card card-blog card-plain blog-horizontal mb-5"
                    >
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="card-image shadow">
                            <a href="javascript:;">
                              <img
                                class="img rounded"
                                src="assets/img/faces/team-2.jpg"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-lg-8">
                          <div class="card-body">
                            <h3 class="card-title">
                              <a href="javascript:;">
                                Rover raised $65 million for pet sitting
                              </a>
                            </h3>
                            <p class="card-description">
                              Finding temporary housing for your dog should
                              be as easy as renting an Airbnb. That's the
                              idea behind Rover, which raised $65 million to
                              expand its pet sitting and dog-walking
                              businesses...
                              <a href="javascript:;"> Read More </a>
                            </p>
                            <div class="author">
                              <img
                                alt="..."
                                class="avatar img-raised"
                                src="assets/img/faces/team-1.jpg"
                              />

                              <div class="text">
                                <span class="name"> Tom Hanks </span>
                                <div class="meta">Drawn on 23 Jan</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card card-blog card-plain blog-horizontal mb-5"
                    >
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="card-image shadow">
                            <a href="javascript:;">
                              <img
                                class="img rounded"
                                src="assets/img/faces/team-3.jpg"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-lg-8">
                          <div class="card-body">
                            <h3 class="card-title">
                              <a href="javascript:;">
                                MateLabs mixes learning with IFTTT
                              </a>
                            </h3>
                            <p class="card-description">
                              If you've ever wanted to train a machine
                              learning model and integrate it with IFTTT,
                              you now can with a new offering from MateLabs.
                              MateVerse, a platform where novices can spin
                              out machine...<a href="javascript:;">
                                Read More
                              </a>
                            </p>
                            <div class="author">
                              <img
                                alt="..."
                                class="avatar img-raised"
                                src="assets/img/faces/team-5.jpg"
                              />

                              <div class="text">
                                <span class="name"> Paul Smith </span>
                                <div class="meta">Drawn on 23 Jan</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card card-blog card-plain blog-horizontal">
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="card-image shadow">
                            <a href="javascript:;">
                              <img
                                class="img rounded"
                                src="assets/img/faces/team-4.jpg"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-lg-8">
                          <div class="card-body">
                            <h3 class="card-title">
                              <a href="javascript:;">
                                US venture investment ticks up in Q2
                              </a>
                            </h3>
                            <p class="card-description">
                              Venture investment in U.S. startups rose
                              sequentially in the second quarter of 2017,
                              boosted by large, late-stage financings and a
                              few outsized early-stage rounds in tech and
                              healthcare..
                              <a href="javascript:;"> Read More </a>
                            </p>
                            <div class="author">
                              <img
                                alt="..."
                                class="avatar img-raised"
                                src="assets/img/faces/team-2.jpg"
                              />

                              <div class="text">
                                <span class="name"> Jasmine Tailor </span>
                                <div class="meta">Drawn on 23 Jan</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section>
      <section class="blogs-4">
        <div class="container-fluid">
          <h2 class="title mb-4">Latest Blogposts</h2>
          <br />

          <div class="row">
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/athena.jpg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        Stellar Partnership
                      </h6>
                      <h5 class="card-title">Climate Change</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/thomas.jpg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        Digital Currency
                      </h6>
                      <h5 class="card-title">Save the World</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/odin.jpg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        Blockchain Explained
                      </h6>
                      <h5 class="card-title">Applications Companies</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/twk-tt.jpg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        A Robot is Your Co-Worker
                      </h6>
                      <h5 class="card-title">ARFID microchips</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="row row-below">
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/theme/kit-suman.jpg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        Sales Leads
                      </h6>
                      <h5 class="card-title">
                        Configure Blockchain Technology
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/sections/damian.jpg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        AI at the Edge
                      </h6>
                      <h5 class="card-title">Research Byte</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/ill/p2.svg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        Features
                      </h6>
                      <h5 class="card-title">
                        FiftyThree Files For Paper
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3">
              <div
                class="card card-blog card-background"
                data-animation="zooming"
              >
                <div
                  class="full-background"
                  style="background-image: url('assets/img/ill/p8.svg')"
                ></div>
                <a href="javascript:;">
                  <div class="card-body">
                    <div class="content-bottom">
                      <h6 class="card-category text-white opacity-8">
                        News
                      </h6>
                      <h5 class="card-title">Focus on Your Employees</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <div class="section team-4 bg-default">
      <div class="container">
        <div class="row">
          <div class="team-2">
            <div class="container">
              <div class="row">
                <div class="col-md-8 mx-auto text-center mb-5">
                  <h3 class="display-3">The Executive Team</h3>
                  <h4 class="lead">
                    This is the paragraph where you can write more details
                    about your team. Keep you user engaged by providing
                    meaningful information.
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div
                    class="card card-profile"
                    data-image="profile-image"
                  >
                    <div class="card-header">
                      <div class="card-image">
                        <div class="dropdown" dropdown>
                          <button
                            aria-expanded="false"
                            class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="ni ni-settings-gear-65"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                            x-placement="bottom-end"
                          >
                            <a class="dropdown-item" href="javascript:;">
                              Edit Profile
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Settings
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Log out
                            </a>
                          </div>
                        </div>
                        <a href="javascript:;">
                          <img
                            class="img rounded"
                            src="assets/img/faces/ali-pazani.jpg"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <h4 class="display-4 mb-0">Sofia Scarlett</h4>
                      <p class="lead">UX Designer</p>
                      <div class="table-responsive">
                        <ul class="list-unstyled">
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-info mr-3"
                                >
                                  <i class="ni ni-atom"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">Keen on great design</h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-success mr-3"
                                >
                                  <i class="ni ni-user-run"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">Outdors lover</h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-danger mr-3"
                                >
                                  <i class="ni ni-chart-bar-32"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Super friendly support team
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="card card-profile"
                    data-image="profile-image"
                  >
                    <div class="card-header">
                      <div class="card-image">
                        <div class="dropdown" dropdown>
                          <button
                            aria-expanded="false"
                            class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="ni ni-settings-gear-65"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                            x-placement="bottom-end"
                          >
                            <a class="dropdown-item" href="javascript:;">
                              Edit Profile
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Settings
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Log out
                            </a>
                          </div>
                        </div>
                        <a href="javascript:;">
                          <img
                            class="img rounded"
                            src="assets/img/faces/team-5.jpg"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <h4 class="display-4 mb-0">Dylan Wyatt</h4>
                      <p class="lead">Team Lead</p>
                      <div class="table-responsive">
                        <ul class="list-unstyled">
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-info mr-3"
                                >
                                  <i class="ni ni-atom"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Dedicated entrepreneur
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-success mr-3"
                                >
                                  <i class="ni ni-user-run"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">Urban exploration</h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-danger mr-3"
                                >
                                  <i class="ni ni-chart-bar-32"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Able to get good at everything
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="card card-profile"
                    data-image="profile-image"
                  >
                    <div class="card-header">
                      <div class="card-image">
                        <div class="dropdown" dropdown>
                          <button
                            aria-expanded="false"
                            class="btn btn-link dropdown-toggle btn-icon-only dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="ni ni-settings-gear-65"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                            x-placement="bottom-end"
                          >
                            <a class="dropdown-item" href="javascript:;">
                              Edit Profile
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Settings
                            </a>
                            <a class="dropdown-item" href="javascript:;">
                              Log out
                            </a>
                          </div>
                        </div>
                        <a href="javascript:;">
                          <img
                            class="img rounded"
                            src="assets/img/faces/atikh.jpg"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <h4 class="display-4 mb-0">Mila Skylar</h4>
                      <p class="lead">Content Creator</p>
                      <div class="table-responsive">
                        <ul class="list-unstyled">
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-info mr-3"
                                >
                                  <i class="ni ni-atom"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  High quality publication
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-success mr-3"
                                >
                                  <i class="ni ni-user-run"> </i>
                                </div>
                              </div>
                              <div><h6 class="mb-1">Storytelling</h6></div>
                            </div>
                          </li>
                          <li class="py-1">
                            <div class="d-flex align-items-center">
                              <div>
                                <div
                                  class="badge badge-circle badge-danger mr-3"
                                >
                                  <i class="ni ni-chart-bar-32"> </i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-1">
                                  Master of words qualification
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title text-center bg-default">
      <h3 class="text-white mb-0 pb-3">Show us some love</h3>
    </div>
    <div class="social-line social-line-big-icons bg-default">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <a
              class="btn btn-gradient-twitter btn-footer"
              href="javascript:;"
            >
              <i class="fab fa-twitter"> </i>
              <p class="title">twitter</p>
              <p class="subtitle">@creativetim</p>
            </a>
          </div>
          <div class="col-lg-3 col-md-6">
            <a
              class="btn btn-gradient-facebook btn-footer"
              href="javascript:;"
            >
              <i class="fab fa-facebook-square"> </i>
              <p class="title">facebook</p>
              <p class="subtitle">@creativetim</p>
            </a>
          </div>
          <div class="col-lg-3 col-md-6">
            <a
              class="btn btn-gradient-slack btn-footer"
              href="javascript:;"
            >
              <i class="fab fa-slack"> </i>
              <p class="title">slack</p>
              <p class="subtitle">@creativetim</p>
            </a>
          </div>
          <div class="col-lg-3 col-md-6">
            <a
              class="btn btn-gradient-instagram btn-footer"
              href="javascript:;"
            >
              <i class="fab fa-instagram"> </i>
              <p class="title">instagram</p>
              <p class="subtitle">@creativetim</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="subscribe-line bg-default">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-10 mx-auto">
            <div class="text-center">
              <h4 class="title text-white">Subscribe to our Newsletter</h4>
              <p class="description text-white">
                Join our newsletter and get news in your inbox every week!
                We hate spam too, so no worries about this.
              </p>
            </div>
            <div class="card card-raised card-form-horizontal">
              <div class="card-body">
                <form action="" method="">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="ni ni-email-83"> </i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          placeholder="Your Email..."
                          type="email"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <button
                        class="btn btn-primary btn-block"
                        type="button"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
