
<div class="wrapper">
  <div class="contactus-3">
    <div class="page-header">
      <img
        alt=""
        class="bg-image"
        src="assets/img/ill/bg_contactus3.svg"
      />
    </div>
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-12 text-center mb-5">
          <h1 class="display-1">Got Something To Say?</h1>
          <h3 class="lead">We'd like to talk more about what you need</h3>
          <button class="btn btn-icon btn-primary mt-3" type="button">
            <span class="btn-inner--icon">
              <i class="ni ni-chat-round"> </i>
            </span>
            <span class="btn-inner--text"> Slack Us </span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div
              class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
            >
              <i class="ni ni-square-pin"> </i>
            </div>
            <h4 class="info-title">Address</h4>
            <p class="description px-0">101 W Broadway, Suite 300, San Diego, CA 92101</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div
              class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
            >
              <i class="ni ni-email-83"> </i>
            </div>
            <h4 class="info-title">Email</h4>
            <p class="description px-0">hello@insureco.io</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div
              class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
            >
              <i class="ni ni-mobile-button"> </i>
            </div>
            <h4 class="info-title">Phone</h4>
            <p class="description px-0">(833) 414-7768</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div
              class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary"
            >
              <i class="iec iec-printer-p-2"> </i>
            </div>
            <h4 class="info-title">Fax</h4>
            <p class="description px-0">(833) 414-7768</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main">
    <div class="container-fluid">
      <div class="row mt-5 mb-4 pt-5">
        <div class="col-md-8 ml-auto mr-auto text-center mt-5">
          <span class="badge badge-info"> Leave a message </span>
          <h1 class="title">Tell us more about <b> your interest </b></h1>
          <h4 class="desc">
            Whether you have questions or you would just like to say hello,
            contact us.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mx-auto">
          <form
            class="card bg-secondary p-3"
            id="contact-form"
            method="post"
            role="form"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': focus === true }">
                    <label> First name </label>
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-circle-08"> </i>
                        </span>
                      </div>
                      <input
                        aria-label="First Name..."
                        class="form-control"
                        placeholder="First Name..."
                        type="text" (blur)="focus = false" (focus)="focus = true"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': focus1 === true }">
                    <label> Last name </label>
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-tag"> </i>
                        </span>
                      </div>
                      <input
                        aria-label="Last Name..."
                        class="form-control"
                        placeholder="Last Name..."
                        type="text" (blur)="focus1 = false" (focus)="focus1 = true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" [ngClass]="{ 'focused': focus2 === true }">
                <label> Email address </label>
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"> </i>
                    </span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Email Here..."
                    type="text" (blur)="focus2 = false" (focus)="focus2 = true"
                  />
                </div>
              </div>
              <div class="form-group" [ngClass]="{ 'focused': focus3 === true }">
                <label> Your message </label>
                <textarea
                  class="form-control form-control-alternative"
                  id="message"
                  name="message" (blur)="focus3 = false" (focus)="focus3 = true"
                  rows="6"
                ></textarea>
              </div>
              <div class="row">
                <div class="col-md-6 ml-auto">
                  <button class="btn btn-primary pull-right" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div
    class="map skew-separator skew-top"
    id="map-contactus-3"
    style="height: 500px;"
  ></div>
</div>
